import { Box } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

function TripCardSkeleton(props) {
  const { visible = false, count = 1 } = props;

  const isMobile = useMediaQuery('(max-width:768px)')

  if (!visible) return null;

  return (
    <>
      {[...Array(Number(count)).keys()].map(() => (
        <Skeleton
          key={Math.random() + Date.now}
          variant="rect"
          width={isMobile ? "100%" : "30%"}
          height={455}
          style={{ padding: 10, marginRight: isMobile ? 0 : 32 }}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Skeleton width={40} height={40} variant="circle" />
            <Skeleton width="85%" />
          </Box>
          <Skeleton width="98%" height={190} />
          <Box
            textAlign="center"
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            <Skeleton width="75%" />
            <Skeleton width="48%" height={80} />
            <Skeleton width="48%" height={80} />
          </Box>
        </Skeleton>
      ))}
    </>
  );
}

export default TripCardSkeleton;
