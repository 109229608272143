import * as Yup from "yup";

import Actions, { enqueueSnackbar } from "../../actions";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Icon,
  InputAdornment,
  TextField,
  makeStyles
} from "@material-ui/core";
import React, { useEffect } from "react";

import { Formik } from "formik";
import IconButton from "@material-ui/core/IconButton";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { bindActionCreators } from "redux";
import clsx from "clsx";
import { connect } from "react-redux";
import { green } from "@material-ui/core/colors";

const styles = makeStyles(theme => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  titleRoot: {
    margin: 0,
    padding: theme.spacing(2)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  buttonSpacer: {
    marginTop: theme.spacing(1)
  },
  loadingButtonWrapper: {
    position: "relative",
    width: "100%"
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexFlow: "column"
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  textField: {
    flexBasis: 200
  }
}));

const DialogTitle = props => {
  const classes = styles();
  const { children, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.titleRoot}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Icon>close</Icon>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

function ForgotPassword(props) {
  const {
    open,
    handleClose,
    error,
    success,

    forgotPasswordAction,
    enqueueSnackbar
  } = props;
  const classes = styles();

  useEffect(() => {
    var unmounted = false;

    if (!unmounted) {
      if (!!error) {
        let errorMessage = "Erro desconhecido";
        let autoHideDuration = 4000;

        // console.log("ForgotPassword error", error);

        if (error.code === "auth/user-not-found") {
          errorMessage = "Email não cadastrado";
        }

        enqueueSnackbar({
          message: errorMessage,
          options: {
            key: "forgotPasswordSnack",
            autoHideDuration: autoHideDuration,
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        });
      }

      if (success) {
        // console.log(success);
        handleClose();
        enqueueSnackbar({
          message: "Email de recuperação de senha enviado com sucesso!",
          options: {
            key: "forgotPasswordSnack",
            autoHideDuration: 5000,
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          }
        });
      }
    }

    return () => {
      unmounted = true;
    };
  }, [error, success, handleClose, enqueueSnackbar]);

  const formSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email inválido")
      .required("Por favor digite seu email")
  });

  const handleSubmit = (values, { setSubmitting }) => {
    forgotPasswordAction(values.email, () => {
      setSubmitting(false);
    });
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"xs"}
      open={open}
      onClose={handleClose}
      scroll={"paper"}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle onClose={handleClose}>Esqueci minha senha</DialogTitle>
      <DialogContent>
        <div className={classes.root}>
          <Typography>
            Informe o email de login do 4bus e nós o ajudaremos a criar uma nova
            senha.
          </Typography>
          <Formik
            initialValues={{
              email: ""
            }}
            validationSchema={formSchema}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              status,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  id="email"
                  className={clsx(classes.margin, classes.textField)}
                  margin="dense"
                  variant="standard"
                  type="text"
                  label="Email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <Icon>email</Icon>
                        </IconButton>
                      </InputAdornment>
                    ),
                    inputProps: {
                      autoComplete: "off",
                      form: {
                        autoComplete: "off"
                      }
                    }
                  }}
                  error={errors.email && touched.email}
                  helperText={
                    errors.email && touched.email ? errors.email : null
                  }
                />

                <Box
                  display="flex"
                  flexDirection="row-reverse"
                  alignItems="flex-end"
                  justifyContent="space-between"
                  paddingY={2}
                >
                  <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Recuperar senha
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = store => ({
  error: store.auth.forgotPasswordError,
  success: store.auth.forgotPasswordSuccess
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      forgotPasswordAction: Actions.auth.authForgotPassword,
      enqueueSnackbar: enqueueSnackbar
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);
