import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import BookingGroups from './booking-groups/BookingGroups';
import BookingPanels from './booking-panels';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { UserTripType } from '../../repositories/UserRepository';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { enqueueSnackbar } from '../../actions';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

function MyTrips(props) {
  const { user } = props;

  const classes = useStyles();
  // console.log('props', props);

  const [reload, setReload] = React.useState(false);
  const [value, setValue] = React.useState('pendentes');
  const isMobile = useMediaQuery('(max-width:768px)');
  function handleChange(event, newValue) {
    setValue(newValue);
  }

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      // console.log('mounted');
    }

    // console.log('out');

    return () => {
      // console.log('cleanup');
      unmounted = true;
    };
  }, []);

  return (
    <>
      <Box className={classes.root}>
        {!isMobile && <Typography variant='h4'>Viagens</Typography>}
        <Tabs value={value} onChange={handleChange} indicatorColor='secondary'>
          <Tab value='pendentes' label='Pendentes' />
          <Tab value='finalizadas' label='Finalizadas' />
          <Tab value='grupos' label='Sugest&otilde;es' />
        </Tabs>
        <Box value={value} index='pendentes' hidden={value !== 'pendentes'}>
          <BookingPanels
            statuses={UserTripType.PENDING}
            user={user}
            setReload={setReload}
          />
        </Box>
        <Box value={value} index='finalizadas' hidden={value !== 'finalizadas'}>
          <BookingPanels
            statuses={UserTripType.FINISHED}
            user={user}
            setReload={setReload}
          />
        </Box>
        <Box value={value} index='grupos' hidden={value !== 'grupos'}>
          <BookingGroups user={user} />
        </Box>
      </Box>
    </>
  );
}

const mapStateToProps = (store) => ({
  user: store.auth.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      enqueueSnackbar: enqueueSnackbar,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MyTrips);
