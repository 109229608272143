import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  makeStyles,
} from '@material-ui/core';
import React,{useEffect} from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Skeleton from '@material-ui/lab/Skeleton';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { searchTrips } from '../../actions';

const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },

  tripSearchCards: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(2),
  },
  tripSearchCard: {
    [theme.breakpoints.down('sm')]: {
      flexBasis: "100%",
      marginRight: 0,
    },
    flexBasis: "30%",
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  tripSearchCardsTitle: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-end',
  },
  icon: {
    alignSelf: 'center',
    margin: '.75rem',
    transform: 'rotate(90deg)',
  },
  button: {
    textTransform: 'none',
  }
}));

function UserLastSearchesContainer(props) {
  const { title, trips, isLoading, isEmpty,firstTrip, settings, searchTrips, user } = props;
  const classes = styles();
  const [tripsEdit, setTripsEdit] = React.useState([]);
  // console.log("trips", trips, user);
  useEffect(() => {
    setTripsEdit(trips);
    if (firstTrip && settings) {
      const tripsEdited = trips.map(trip => {
        if (
          trip.etd <= settings.startDateFirstTripDiscount &&
          trip.etd >= settings.endDateFirstTripDiscount
        ) {
          if (settings.firstTripDiscountUnity === 'V') {
            trip.ticketFirstTrip =
              trip.ticketValue - settings.firstTripDiscount;
          } else {
            trip.ticketFirstTrip =
              trip.ticketValue -
              parseFloat(
                trip.ticketValue * (settings.firstTripDiscount / 100)
              ).toFixed(2);
          }
        }
        return trip;
      });
      setTripsEdit(tripsEdited);
      console.log('tripsEdited', tripsEdited);
    }
  }, [trips, firstTrip, settings]);
  const isMobile = useMediaQuery('(max-width:768px)')
  const redoSearch = trip => {
    console.log('redoSearch', trip);
    searchTrips(
      trip.departureCity || trip.cityDeparture,
      trip.arrivalCity || trip.cityArrival,
      trip.dateDepart,
      trip.dateReturn,
      false,
      user.uidFirebase,
    );
  };

  const getDepartureCity = trip =>
    `${(trip.departureCity || trip.cityDeparture).name}, ${
      (trip.departureCity || trip.cityDeparture).uf
    }`;

  const getArrivalCity = trip =>
    `${(trip.arrivalCity || trip.cityArrival).name}, ${
      (trip.arrivalCity || trip.cityArrival).uf
    }`;

  if (Boolean(isEmpty)) {
    return null;
  }


  const {} = props
  

  return (
    <>
      <Box marginBottom={4}>
        <Container className={classes.tripSearchCards}>
          <Box className={classes.tripSearchCardsTitle}>
            <h1>{title}</h1>
          </Box>

          {isLoading &&
            Array.from([0, 1]).map(_ => (
              <Skeleton
                key={Math.random() + Date.now}
                variant="rect"
                width={isMobile ? '100%' : '30%'}
                height={120}
                style={{ padding: 10, marginRight: isMobile ? 0 : 32 }}
              >
                <Skeleton width="75%" />
                <Skeleton width="55%" />
                <Skeleton width="70%" height={32} />
              </Skeleton>
            ))}

          {!isEmpty &&
            tripsEdit &&
            tripsEdit.map(trip => (
              <Card key={Math.random()} className={classes.tripSearchCard}>
                <CardContent>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="stretch"
                    justifyContent="space-around"
                  >
                    <div>
                      <div>
                        {!!trip.dateReturn && <span>Ida: </span>}
                        <span style={{ fontWeight: 'bolder' }}>
                          {getDepartureCity(trip)}
                          {' > '}
                          {getArrivalCity(trip)}
                        </span>
                      </div>
                      {!!trip.dateDepart && (
                        <span style={{ fontWeight: 'lighter' }}>
                          {moment(trip.dateDepart).format('dddd, ll')}
                        </span>
                      )}
                    </div>
                  </Box>
                </CardContent>
                <CardActions disableSpacing>
                  <Box className={classes.actionsContainer}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      className={classes.button}
                      onClick={() => redoSearch(trip)}
                    >
                      Refazer pesquisa
                    </Button>
                  </Box>
                </CardActions>
              </Card>
            ))}
        </Container>
      </Box>
    </>
  );
}

const mapStateToProps = store => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      searchTrips: searchTrips,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserLastSearchesContainer);
