import CurrencyFormat from 'react-currency-format';
import React from 'react';
import './AppCurrencyFormat.css';

function AppCurrencyFormat(props) {
  const { value } = props;

  return (
    <CurrencyFormat
      className='format-value'
      value={value}
      decimalScale={2}
      fixedDecimalScale={true}
      displayType={'text'}
      thousandSeparator={'.'}
      decimalSeparator={','}
      prefix={'R$ '}
    />
  );
}

export default AppCurrencyFormat;
