function cpfIsValid(cpf, required) {
  cpf = (cpf && cpf.replace(/\D/g, '')) || '';
  if (cpf.length === 0 && !required) return true;
  var strCPF = cpf;
  var Soma;
  var Resto;
  Soma = 0;
  if (strCPF === '00000000000') return false;

  for (var j = 1; j <= 9; j++)
    Soma = Soma + parseInt(strCPF.substring(j - 1, j)) * (11 - j);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (var i = 1; i <= 10; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

export { cpfIsValid };
