import { db } from '../firebase';

export default class SettingsRepository {
  static getDiscounts = () => {
    return db
      .collection('discounts')
      // .doc('4bus')
      .get();
  };

  static getSettings = () => {
    return db.collection('settings').doc('4bus').get()
  }
  
}
