import React, { useEffect, useState, Fragment } from 'react';
import { Grid, Typography, Button, withStyles } from '@material-ui/core';
import LoadingOverlay from 'react-loading-overlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import ROUTES from '../../../constants/routes';

import CheckoutStepper from '../../checkout/checkout-stepper/index';
import TripApiService from '../../../services/TripApiService';

import SeatsMapNew from './../SeatsMapNew';

import facebookPixel from '../../../utils/facebookPixel';
import googleAnalytics from '../../../utils/googleAnalytics';
import STORAGE from '../../../constants/storage';
import moment from 'moment';

const ReturnTripSeat = ({ history, theme }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [trip, setTrip] = useState(null);
  const [firstDecker, setFirstDecker] = useState(null);
  const [secondDecker, setSecondDecker] = useState(null);
  const [numberSeatToSet, setNumberSeatToSet] = useState(0);
  const [returnTripDetails, setReturnTripDetails] = useState(null);
  const [departTripDetails, setDepartTripDetails] = useState(null);
  const [noSeatMap, setNoSeatMap] = useState(null);
  const [passengers, setPassengers] = useState(
    JSON.parse(sessionStorage.getItem('selectedPassengers'))
  );
  const [connectionFirstDecker, setConnectionFirstDecker] = useState(null);
  const [connectionSecondDecker, setConnectionSecondDecker] = useState(null);
  const [inConnection, setInConnection] = useState(false);
  const limitSelected = passengers.length;

  useEffect(() => {
    setDepartTripDetails(JSON.parse(sessionStorage.getItem('departTrip')));
    setReturnTripDetails(JSON.parse(sessionStorage.getItem('returnTrip')));
  }, []);

  useEffect(() => {
    setTrip(returnTripDetails);
    console.log('returnTripDetails', returnTripDetails);
    getSeatsDepartAndReturn();
  }, [returnTripDetails]);

  const getSeatsDepartAndReturn = async () => {
    try {
      if (departTripDetails || returnTripDetails) {
        const data = JSON.parse(sessionStorage.getItem('dataTrip'));
        console.log('Pegando session API SEATS', data);

        if (data.busSeatingMapReturnTripSecondDecker) {
          setSecondDecker(data.busSeatingMapReturnTripSecondDecker);
        }
        if (data.busSeatingMapReturnTripFirstDecker) {
          setFirstDecker(data.busSeatingMapReturnTripFirstDecker);
        }

        if (data.busSeatingMapReturnTripConnectionSecondDecker) {
          setConnectionSecondDecker(
            data.busSeatingMapReturnTripConnectionSecondDecker
          );
        }
        if (data.busSeatingMapReturnTripConnectionFirstDecker) {
          setConnectionFirstDecker(
            data.busSeatingMapReturnTripConnectionFirstDecker
          );
        }
      }
    } catch (error) {
      console.log('error API SEATS', error);
    }
  };

  // // useEffect(() => {
  // //   if (bookingSegments.length > 0) {
  // //     googleAnalytics(
  // //       '',
  // //       { category: 'ecommerce', action: 'purchase', label: 'purchase' },
  // //       {
  // //         transactionId: bookingSegments[0].bookingCode,
  // //         value: bookingSegments[0].value,
  // //       }
  // //     );
  // //     facebookPixel('', {
  // //       type: 'Purchase',
  // //       data: { currency: 'BRL', value: bookingSegments[0].value },
  // //     });
  // //   }
  // // }, []);

  const setSeatsPassengers = (bookingCode) => {
    const passengersSeatsFiltered = passengers.map((e) => {
      // e.seat = e.seatNumber;
      delete e.age;
      delete e.birth;
      delete e.birthdate;
      delete e.cpf;
      delete e.createdAt;
      delete e.email;
      delete e.idIssuer;
      delete e.idNumber;
      delete e.idType;
      delete e.selected;
      delete e.tableData;
      delete e.userId;
      delete e.name;
      // delete e.id
      // delete e.seatNumber;
      return e;
    });
    passengersSeatsFiltered.map((e) => {
      e.passengerId = e.id;
      e.id = undefined;
      return e;
    });

    console.log('passengersSeatsFiltered', passengersSeatsFiltered);
    const seats = {
      segment: 'return',
      passengersSeats: passengersSeatsFiltered,
      tripId: !inConnection
        ? firstDecker && firstDecker.tripId
          ? firstDecker.tripId
          : null
        : connectionFirstDecker && connectionFirstDecker.tripId
          ? connectionFirstDecker.tripId
          : null,
    };
    if (!inConnection && connectionFirstDecker) {
      sessionStorage.setItem(STORAGE.SEATS_RETURN, JSON.stringify(seats));

      if (connectionFirstDecker) {
        setInConnection(true);
      } else {
        history.push(ROUTES.CHECKOUT_CONFIRMATION);
      }
    } else {
      if (inConnection) {
        sessionStorage.setItem(
          STORAGE.SEATS_RETURN_CONNECTION,
          JSON.stringify(seats)
        );
      }
      sessionStorage.setItem(STORAGE.SEATS_RETURN, JSON.stringify(seats));
      history.push(ROUTES.CHECKOUT_CONFIRMATION);
    }
  };
  const changeSeatsPassengers = (seatNumber, decker) => {
    const newPassengersWithSeat = passengers.filter((e, index) => {
      if (!e.seatNumber && index === numberSeatToSet) {
        e.seatNumber = seatNumber;
        e.decker = decker;
      }
      return e;
    });
    if (numberSeatToSet < limitSelected) {
      setNumberSeatToSet(numberSeatToSet + 1);
    }
    setPassengers(newPassengersWithSeat);
  };
  const unselectSeat = (seatNumber, decker) => {
    const newPassengersWithSeat = passengers.filter((e, index) => {
      if (e.seatNumber === seatNumber && e.decker === decker) {
        e.seatNumber = null;
        e.decker = null;
        setNumberSeatToSet(index);
      }
      return e;
    });
    setPassengers(newPassengersWithSeat);
  };

  return noSeatMap ? (
    <Grid container justify='center' style={{ paddingTop: 20 }}>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Typography variant='h5' color='primary'>
          Não é possível escolher assentos para esta viagem, desculpe pelo
          transtorno.
        </Typography>
      </Grid>
      <Grid item xs={4} style={{ textAlign: 'center', paddingTop: 20 }}>
        <Button
          color='primary'
          variant='contained'
          // disabled={passengers.find(e => !e.seatNumber)}
          onClick={(e) => {
            history.push(ROUTES.CHECKOUT_SUMMARY);
          }}
        >
          {returnTripDetails ? 'Continuar' : 'Pagamento'}
          <FontAwesomeIcon icon={faArrowRight} />
        </Button>
      </Grid>
    </Grid>
  ) : (
    <Fragment>
      <LoadingOverlay
        spinner
        text='Finalizando seleção de assentos, por favor aguarde...'
        active={isSaving}
      >
        <CheckoutStepper activeStep={1} />
        <Grid container style={{ padding: 20, justifyContent: 'center' }}>
          <Grid item xs={12}>
            <Typography variant='h5' align='center'>
              Seleção de Assentos{' '}
              {(firstDecker || connectionFirstDecker) &&
                (!inConnection
                  ? `${firstDecker && firstDecker.departureCity} > ${firstDecker && firstDecker.arrivalCity
                  }`
                  : `${connectionFirstDecker &&
                  connectionFirstDecker.departureCity
                  } > ${connectionFirstDecker && connectionFirstDecker.arrivalCity
                  }`)}
            </Typography>
          </Grid>
          <Grid item sm={6} xs={10}>
            <Typography variant='h5' align='center'>
              Passageiros
            </Typography>
            <Grid container direction='column' style={{ marginTop: 10 }}>
              {passengers.map((p, index) => (
                <Grid container>
                  <Grid item xs={12} sm={10} md={8}>
                    <Grid
                      container
                      alignItems='center'
                      style={{
                        border: '1px solid #ccc',
                        padding: 10,
                        marginBottom: 10,
                        borderRadius: 5,
                        boxShadow: theme.shadows[4],
                      }}
                    >
                      <Grid item sm={6}>
                        <Typography style={{ marginBottom: 10 }}>
                          <span style={{ fontWeight: 700 }}>Nome: </span>
                          {p.name}
                        </Typography>
                        <Typography>
                          <span style={{ fontWeight: 700 }}>Documento: </span>
                          {p.cpf || p.idNumber}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 20 }} />
                      <Fragment>
                        <Grid item xs={2}>
                          <Typography
                            color='primary'
                            style={{ fontWeight: 500 }}
                            align='center'
                          >
                            Poltrona
                          </Typography>{' '}
                        </Grid>
                        <Grid item xs={2}>
                          <div
                            style={{
                              width: 40,
                              height: 40,
                              fontSize: 20,
                              color: '#fff',
                              borderRadius: '5px 5px 0 0 ',
                              textAlign: 'center',
                              marginRight: 10,
                              marginLeft: 15,
                              marginBottom: 10,
                              background: '#0FB8D4',
                              // border: '1px solid #ccc',
                              borderBottom: '5px solid #ccc',
                              paddingTop: 4,
                            }}
                          >
                            {p.seatNumber}
                          </div>
                        </Grid>
                      </Fragment>
                      <Fragment>
                        <Grid item xs={2}>
                          <Typography
                            color='primary'
                            style={{ fontWeight: 500 }}
                            align='center'
                          >
                            Andar
                          </Typography>{' '}
                        </Grid>
                        <Grid item xs={2}>
                          <div
                            style={{
                              width: 35,
                              height: 35,
                              fontSize: 18,
                              color: '#fff',
                              borderRadius: '5px 5px 5px 5px',
                              textAlign: 'center',
                              marginRight: 10,
                              marginBottom: 10,
                              background: '#0FB8D4',
                              paddingTop: 5,
                            }}
                          >
                            {p.decker}
                          </div>
                        </Grid>
                      </Fragment>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={10} sm={6}>
            <Grid item xs={12} style={{ textAlign: 'end'}}>
              <Button
                color='primary'
                variant='contained'
                disabled={passengers.find((e) => !e.seatNumber)}
                onClick={(e) => setSeatsPassengers()}
              >
                {!inConnection && connectionFirstDecker
                  ? 'SELECIONR ASSENTOS CONEXÃO'
                  : 'PAGAMENTO'}
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </Grid>
            <SeatsMapNew
              trip={trip}
              showTypes={true}
              passengers={passengers}
              changeSeatsPassengers={changeSeatsPassengers}
              unselectSeat={unselectSeat}
              firstDecker={inConnection ? connectionFirstDecker : firstDecker}
              secondDecker={
                inConnection ? connectionSecondDecker : secondDecker
              }
              hasReturnTrip={returnTripDetails ? true : false}
            />
          </Grid>

        </Grid>
      </LoadingOverlay>
      )
    </Fragment>
  );
};

export default withRouter(withStyles({}, { withTheme: true })(ReturnTripSeat));
