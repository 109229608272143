import { Avatar, Box, Divider, Grid } from '@material-ui/core';

import React from 'react';

function TripSteps(props) {
  const { steps, spacing = 30 } = props;
  console.log('steps', steps);

  return (
    <Box>
      <Grid container>
        {steps.map((tripStep, index, all) => (
          <React.Fragment key={tripStep.title}>
            <Grid container item>
              <Grid item>
                <Box
                  display='flex'
                  justifyContent='center'
                  flexDirection='column'
                  alignItems='center'
                  height='100%'
                  marginRight={1}
                  paddingTop={index === 0 ? `${spacing}px` : 0}
                  paddingBottom={index === all.length - 1 ? `${spacing}px` : 0}
                >
                  <Divider
                    hidden={index === 0}
                    orientation='vertical'
                    style={{
                      color: '#0FB8D4',
                      height: `${spacing}px`,
                    }}
                  />
                  <Avatar
                    style={{
                      width: '15px',
                      height: '15px',
                      margin: 1,
                      color: '#fff',
                      backgroundColor: '#0FB8D4',
                      paddingTop:
                        index !== all.length - 1 && index !== 0 ? '10px' : 0,
                      paddingBottom: index === all.length - 1 ? '10px' : 0,
                    }}
                  />
                  <Divider
                    hidden={index === all.length - 1}
                    orientation='vertical'
                    style={{
                      color: '#0FB8D4',
                      height: `${spacing}px`,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs>
                <Box
                  alignItems='flex-start'
                  height='100%'
                  width='100%'
                  display='flex'
                  justifyContent='center'
                  flexDirection='column'
                >
                  <div>{tripStep.title}</div>
                  <div>{tripStep.subTitle}</div>
                </Box>
              </Grid>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
}

export default TripSteps;
