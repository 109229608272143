import { Box, Divider, Grid, Typography } from '@material-ui/core';

import AppCurrencyFormat from '../../../shared/app-currency-format/AppCurrencyFormat';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import FlagIcon from '@material-ui/icons/Flag';
import React from 'react';
import RoomIcon from '@material-ui/icons/Room';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';

function CheckoutTripSummaryCard(props) {
  const { trip, title, isGoing } = props;
  console.log("CHECKOUT TRIP -->", trip)
  return (
    <>
      <Box textAlign='center'>
        <Typography variant='h5'>{title}</Typography>
        <Typography variant='h6' gutterBottom>
          {moment(new Date(trip.etdTimestamp)).format('LL')}
        </Typography>
        <Grid
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Grid item xs={5}>
            <Typography variant='h5'>{trip.departure.city.name}</Typography>
          </Grid>
          <Grid item xs={2}>
            {isGoing && <ArrowForwardIcon fontSize='large' />}
            {!isGoing && <ArrowBackIcon fontSize='large' />}
          </Grid>
          <Grid item xs={5}>
            <Typography variant='h5'>{trip.arrival.city.name}</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={5}>
            {/* <Typography variant="h6">
              {moment(new Date(trip.etdTimestamp)).format('LL')}
            </Typography> */}
            <Typography variant='h4'>
              {moment(new Date(trip.etdTimestamp)).format('HH:mm')}
            </Typography>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={5}>
            {/* <Typography variant="h6">
              {moment(new Date(trip.etaTimestamp)).format('LL')}
            </Typography> */}
            <Typography variant='h4'>
              {moment(new Date(trip.etaTimestamp)).format('HH:mm')}
            </Typography>
          </Grid>
        </Grid>

        <Divider style={{ marginTop: 16, marginBottom: 16 }} />

        <Box textAlign='left' marginBottom={1}>
          <Box display='flex' alignItems='center'>
            <RoomIcon fontSize='small' />
            <Typography variant='caption'>Embarque</Typography>
          </Box>
          <Typography variant='body2' style={{ marginLeft: 16 }}>
            {trip.departure.address
              ? trip.departure.address
              : trip.departure.name}
          </Typography>
        </Box>

        <Box textAlign='left'>
          <Box display='flex' alignItems='center'>
            <FlagIcon fontSize='small' />
            <Typography variant='caption'>Desembarque</Typography>
          </Box>
          <Typography variant='body2' style={{ marginLeft: 16 }}>
            {trip.arrival.address ? trip.arrival.address : trip.arrival.name}
          </Typography>
        </Box>

        <Divider style={{ marginTop: 16, marginBottom: 16 }} />

        <Table size='small'>
          <TableBody>
            <TableRow>
              <TableCell component='th' scope='row'>
                Preço na rodoviária
              </TableCell>
              <TableCell align='right'>
                <AppCurrencyFormat value={trip.busStationPrice} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row'>
                <b>Valor</b>
              </TableCell>
              <TableCell align='right'>
                <AppCurrencyFormat value={trip.ticketValue} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </>
  );
}

export default CheckoutTripSummaryCard;
