import React, { useState, useEffect, Fragment, forwardRef } from "react";
import clsx from "clsx";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";

import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Fade from "react-reveal/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";

import AuthApiService from "../../services/AuthApiService";
const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PaperComponent = (props) => {
  return <Paper {...props} />;
};

const styles = () => ({
  dialog: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

const CustomDialog = ({
  title,
  open,
  setOpen,
  classes,
  currentTrip,
  selectTrip,
  width,
  children,
  color = "#02b3d4",
  paddingDialog = true,
  height = "auto",
  backdropclick = false,
}) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setMessageError] = useState("");
  const [name, setName] = useState("");
  const [step, setStep] = useState("info-client");

  const verifyEmail = async () => {
    setLoading(true);
    try {
      const { data, status } = await AuthApiService.verifyEmail(email);
      console.log("Data -->", data);
      if (status === 200) {
        saveTripAndClientInTrip(data);
      } else if (status === 409) {
        setShowMessage(true);
      }
      if (status === 204) {
        setStep("new-client");
      }
    } catch ({ status }) {
      if (status) {
        setShowMessage(true);
        setMessageError(
          "Email já cadastrado para outro tipo de usuário, utilize um e-mail diferente para cadastrar ou encontrar o cliente."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const saveTripAndClientInTrip = (clientSelectedBySeller) => {
    // sessionStorage.clear();
    sessionStorage.setItem(
      "clientSelectedBySeller",
      JSON.stringify(clientSelectedBySeller)
    );
    selectTrip(currentTrip);
  };

  const createNewUser = async () => {
    setLoading(true);
    try {
      const { data } = await AuthApiService.createUser(name, email);
      console.log("create New User -->", data);
      saveTripAndClientInTrip(data);
    } catch (error) {
      console.log("error -->", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Dialog
      PaperComponent={PaperComponent}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      open={open}
      onClose={() => setOpen(false)}
      TransitionComponent={Transition}
      PaperProps={{
        elevation: 0,
        style: {
          minWidth: 400,
          maxWidth: 400,
          maxHeight: height,
          margin: 0,
        },
      }}
    >
      <DialogTitle
        disableTypography
        style={{ background: color, cursor: "move" }}
        className={clsx(classes.dialog)}
        id="draggable-dialog-title"
      >
        <Typography
          variant={"h6"}
          className={"font-700 text-white"}
          style={{ color: "#FFF" }}
        >
          {title}
        </Typography>
        <IconButton onClick={() => setOpen(false)} disabled={loading}>
          <CloseIcon className={"text-white"} />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent className={"p-20"}>
        {step === "info-client" && (
          <Fragment>
            <TextField
              label="Informe o Email"
              variant="outlined"
              fullWidth
              margin="dense"
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                if (showMessage) {
                  setShowMessage(false);
                  setMessageError("");
                }
              }}
              onKeyPress={(e) => {
                console.log("key press ->", e.charCode)
                if (e.charCode === 13 && email) {
                  verifyEmail()
                }
              }}
            />

            {showMessage && (
              <Fade>
                <Typography align="center">
                  Email já cadastrado para outro tipo de usuário, utilize um
                  e-mail diferente para cadastrar ou encontrar o cliente.
                </Typography>
              </Fade>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              <Button variant="outlined" color="primary" disabled={loading}>
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={() => verifyEmail()}
              >
                {loading ? <CircularProgress size={20} /> : "Buscar"}
              </Button>
            </div>
          </Fragment>
        )}
        {step === "new-client" && (
          <Fragment>
            <Typography align="center">
              Cliente não cadastrado. Informe o nome completo para fazer o
              cadastro
            </Typography>
            <TextField
              label="Nome Completo"
              variant="outlined"
              fullWidth
              margin="dense"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                if (showMessage) {
                  setShowMessage(false);
                  setMessageError("");
                }
              }}
              onKeyPress={(e) => {
                console.log("key press ->", e.charCode)
                if (e.charCode === 13 && name) {
                  createNewUser()
                }
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setStep("info-client")}
                disabled={loading}
              >
                Voltar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => createNewUser()}
                disabled={loading}
              >
                {loading ? <CircularProgress size={20} /> : "Adicionar"}
              </Button>
            </div>
          </Fragment>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(CustomDialog);
