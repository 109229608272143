import React, { useRef, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import logo from "./static/images/logo.png";
import { withRouter, Link } from "react-router-dom";
import ROUTES from "./constants/routes";
import { Tabs, Tab, Container } from "@material-ui/core";
import { authSignInOpenModal, authSignOut, enqueueSnackbar } from "./actions";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import SuggestTrip from "./components/suggest-trip";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import HomeIcon from '@material-ui/icons/Home';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import FeedbackIcon from '@material-ui/icons/Feedback';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({

  appbar: {
    backgroundColor: "white",
    alignItems: "center",
    zIndex: "9999",
    padding: theme.spacing(0.5)
  },
  navButton: {
    color: "#03b3d4",
    textDecoration: "none",
    minWidth:0,
    margin: '0 10px'
  },
  image: {
    height: "42px",
    display: "flex",
    alignContent: "center",
  },
  tabsGroup:{
    display: 'flex',
    marginLeft: 'auto',
  },

  mobileRoot:{
    width: '100%',
    overflow: 'hidden',
    position: 'fixed',
    bottom: 0,
    zIndex: '9999',
  },
  mobileIconColor:{
    color: "#03b3d4",
  }
}));

const CustomAppBar = ({
  history,
  children,
  authSignInOpenModal,
  signOut,
  user,
  match,
  enqueueSnackbar
}) => {
  const classes = useStyles();
  const [suggestTripIsOpen, setSuggestTripIsOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const bookingListener = useSelector((state) => state.bookingListener);

  useEffect(() => {
    if(bookingListener.pixData && bookingListener.pixData.statusId !== 15){
      let message, variant
      switch(bookingListener.pixData.statusId){
        case 3:
          message = "Sua reserva foi confirmada como paga!"
          variant = "success"
          break
        case 4:
        default:
          message = "Sua reserva foi cancelada!"
          variant = "error"
          break

          
      }
      return enqueueSnackbar({
        message,
        options: {
          key: 'bookingListenerErrorSnack',
          autoHideDuration: 5000,
          variant,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        },
      });
    }
  }, [bookingListener.pixData, enqueueSnackbar])

  const handleCloseSuggestTrip = () => {
    setSuggestTripIsOpen(false);
  };

  const isMobile = useMediaQuery("(max-width:768px)");
  const pathname = history.location.pathname;
  const navButton = useRef();

  return (
    <div className={classes.root}>

      <SuggestTrip
        open={suggestTripIsOpen}
        handleClose={handleCloseSuggestTrip}
      />

      {isMobile ? 
      
        <>

        {user ? 
          <div>

            <BottomNavigation value={value} onChange={(event, newValue) => {
                setValue(newValue);
              }}
                showLabels
                className={classes.mobileRoot}
              >

              <BottomNavigationAction label="Início" icon={<HomeIcon />}
              className={classes.mobileIconColor}
              onClick={(e) => {
                e.preventDefault();
                history.push(ROUTES.HOME);
              }}/>

              <BottomNavigationAction label="Viagens" icon={<DirectionsBusIcon />} 
              className={classes.mobileIconColor}
              onClick={(e) => {
                e.preventDefault();
                history.push(ROUTES.MY_TRIPS);
              }}/>
              
              <BottomNavigationAction label="Sugestões" icon={<FeedbackIcon />} 
              className={classes.mobileIconColor}
              onClick={(e) => {
                  e.preventDefault();
                  setSuggestTripIsOpen(true);
                }}/>

              <BottomNavigationAction label="Ajuda" icon={<ContactSupportIcon />}
              className={classes.mobileIconColor}
              onClick={(e) => {
                e.preventDefault();
                history.push(ROUTES.HELP);
              }}/>

              <BottomNavigationAction label="Sair" icon={<ExitToAppIcon />}
              className={classes.mobileIconColor}
              onClick={(e) => {
                  e.preventDefault();
                  setTimeout(() => {
                    signOut();
                  }, 50);
                }}/>

            </BottomNavigation>

          </div>
        
        :

          <div>
            
            <BottomNavigation value={value} onChange={(event, newValue) => {
                setValue(newValue);
              }}
                showLabels
                className={clsx(classes.mobileRoot, classes.mobileIconColor)}
              >

              <BottomNavigationAction label="Início" icon={<HomeIcon />}
              className={classes.mobileIconColor}
              onClick={(e) => {
                e.preventDefault();
                history.push(ROUTES.HOME);
              }}/>

              <BottomNavigationAction label="Entrar" icon={<RestoreIcon />}
              className={classes.mobileIconColor}
              onClick={(e) => {
                e.preventDefault();
                history.push(ROUTES.SIGNIN);
              }}/>
              
              <BottomNavigationAction label="Ajuda" icon={<ContactSupportIcon />}
              className={classes.mobileIconColor}
              onClick={(e) => {
                e.preventDefault();
                history.push(ROUTES.HELP);
              }}/>

            </BottomNavigation>

          </div>
        }
        </>
      
      : 
      
      <AppBar className={classes.appbar}>
        <Container>
        {user ? (
          <Tabs>

            <Link to={ROUTES.HOME}>
              <img src={logo} alt="logo da 4bus" className={classes.image} />
            </Link>

            <div className={classes.tabsGroup}>
              <Tab
                onClick={(e) => {
                  e.preventDefault();
                  history.push(ROUTES.MY_TRIPS);
                }}
                className={classes.navButton}
                label="Minhas viagens"
              />

              <Tab
                onClick={(e) => {
                  e.preventDefault();
                  setSuggestTripIsOpen(true);
                }}
                className={classes.navButton}
                label="Sugerir linha"
              />

              <Tab
                onClick={(e) => {
                  e.preventDefault();
                  history.push(ROUTES.MY_ACCOUNT);
                }}
                className={classes.navButton}
                label="Minha conta"
              />

              <Tab
                onClick={(e) => {
                  e.preventDefault();
                  history.push(ROUTES.HELP);
                }}
                className={classes.navButton}
                label="Ajuda"
              />

              <Tab
                onClick={(e) => {
                  e.preventDefault();
                  setTimeout(() => {
                    signOut();
                  }, 50);
                }}
                className={classes.navButton}
                label="Sair"
              />
            </div>

          </Tabs>
        ) : (
          <AppBar className={classes.appbar}>
            <Container>
              <Tabs>
                <Link to={ROUTES.HOME}>
                  <img
                    src={logo}
                    alt="logo da 4bus"
                    className={classes.image}
                  />
                </Link>

                <div className={classes.tabsGroup}>
                  <Tab
                    label="Entrar"
                    className={classes.navButton}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(ROUTES.SIGNIN);
                    }}
                  />

                  <Tab
                    label="Ajuda"
                    className={classes.navButton}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(ROUTES.HELP);
                    }}
                  />
                </div>

              </Tabs>
            </Container>
          </AppBar>
        )}
        </Container>
    </AppBar> 
    }

    {children}

    </div>
  );
};

const mapStateToProps = (store) => ({
  user: store.auth.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      enqueueSnackbar: enqueueSnackbar,
      authSignInOpenModal: authSignInOpenModal,
      signOut: authSignOut,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomAppBar)
);