import { db, storage } from '../firebase';

export const UserTripType = {
  PENDING: [2, 3, 6, 8, 13, 14, 15],
  FINISHED: [4, 5, 7],
};

export default class UserRepository {
  static getCurrentUserDoc = (uidFirebase) => {
    return db.collection("users").doc(uidFirebase);
  };

  static getCard = (uidFirebase) => {
    return this.getCurrentUserDoc(uidFirebase)
      .collection("cards")
      .limit(1)
      .get()
      .then((snapshot) => snapshot.docs.map((doc) => doc.data()));
  };

  static getBookings = (uidFirebase, tripType) => {
    return this.getCurrentUserDoc(uidFirebase)
      .collection("bookings")
      .orderBy("id", "desc")
      .get()
      .then((snapshot) =>
        snapshot.docs
          .map((doc) => doc.data())
          .filter((trip) => tripType.some((id) => id === trip.statusId))
      );
  };

  static getBookingsSeller = (userId, tripType) => {
    return db
      .collection("bookings")
      .where("bookingByUserId", "==", userId)
      .orderBy("trip.etd", "desc")
      .get()
      .then((snapshot) =>
        snapshot.docs
          .map((doc) => doc.data())
          .filter((trip) => tripType.some((id) => id === trip.statusId))
      );
  };

  static getNextTrip = (uidFirebase, tripType) => {
    return this.getCurrentUserDoc(uidFirebase)
      .collection("bookings")
      .orderBy("trip.etd")
      .get()
      .then((snapshot) =>
        snapshot.docs
          .map((doc) => doc.data())
          .filter((trip) => tripType.some((id) => id === trip.statusId))
      );
  };

  static geTripGroups = (userId) => {
    return db
      .collection("tripGroup")
      .where("userId", "==", userId)
      .get()
      .then((snapshot) =>
        snapshot.docs
          .map((doc) => doc.data())
          .sort((a, b) => a.departDate - b.departDate)
      );
  };

  static getWallet = (uidFirebase) => {
    return this.getCurrentUserDoc(uidFirebase)
      .collection("wallet")
      .orderBy("date", "desc")
      .get()
      .then((snapshot) => snapshot.docs.map((doc) => doc.data()));
  };

  static getProfileImage = (uidFirebase) => {
    return storage.ref().child(`users/${uidFirebase}.jpg`).getDownloadURL();
  };

  static saveProfileImage = (uidFirebase, imageBuffer) => {
    return storage.ref().child(`users/${uidFirebase}.jpg`).put(imageBuffer);
  };

  static bookingListener = (bookingCode) =>
    db.collection("bookings")
      .doc(bookingCode)
      .onSnapshot(
        (doc) => doc.data()
      )
}
