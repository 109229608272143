import { Box, Container } from "@material-ui/core";
import React, { useEffect } from "react";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import FaqRepository from "../repositories/FaqRepository";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    [theme.breakpoints.up("sm")]: {
      flexShrink: 0,
    },
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function CharterTermsOfUseFretado() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [faqList, setFaqList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      setIsLoading(true);
      FaqRepository.getFaq().then((snapshot) => {
        const faqs = snapshot.docs.map((doc) => doc.data());
        // console.log(faqs);
        setTimeout(() => {
          setFaqList(faqs);
          setIsLoading(false);
        }, 500);
      });
    }

    return () => {
      unmounted = true;
    };
  }, [setFaqList]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box className={classes.root}>
      <Container>
        <h1>Termos de uso fretado</h1>
        <h3>I. INTRODUÇÃO</h3>
        <p>
        Quando você for usar o app 4bus Motorista Fretado, você nos confia seus dados pessoais. E temos o compromisso de manter essa confiança. Para começar, vamos te ajudar a entender as nossas práticas de privacidade.
        </p>
        <p>
          Este aviso descreve os dados pessoais que coletamos, como são usados e
          compartilhados e as suas opções com relação a eles. Recomendamos ler
          este aviso junto com a visão geral de privacidade, que destaca os
          pontos principais das nossas práticas de privacidade.
        </p>
        <p>
          Última alteração: 05 de janeiro de 2022
          <br />
          Entrada em vigor: 05 de janeiro de 2022
        </p>

        <h3>II. VISÃO GERAL</h3>

        <h3> A. ESCOPO</h3>
        <p>
          Este aviso aplica-se aos usuários dos do app 4bus Motorista Fretado em
          todo o território brasileiro, inclusive os usuários do portal de
          gestão de rotas, sites, recursos ou outros serviços.
        </p>

        <p>
          Esse aviso descreve de que maneira a 4bus Tecnologia do transporte SA
          coleta e usa os dados pessoais. Esse aviso se aplica especificamente
          a:
        </p>

        <p>
          Motoristas parceiros: indivíduos que prestam serviços de viagens
          contratadas no modelo de fretamento contínuo de passageiros, tendo
          sido contratados pelas empresas que assinam os serviços mensais da
          4BUS ou motoristas terceiros contratados por meio de empresas
          parceiras de fretamento.
        </p>

        <p>
          Esse aviso também se aplica a outras coletas de dados pessoais pela
          4BUS, em conexão com o app 4bus. Por exemplo, podemos coletar
          informações de contato de indivíduos que usam como colaboradores
          transportados ou em conexão com nossa tecnologia e nossos recursos de
          mapeamento.
        </p>

        <p>
          Todos os indivíduos sujeitos ao presente aviso são denominados
          “usuários” neste documento.
        </p>

        <p>
          Dúvidas, comentários e reclamações sobre as práticas de tratamento de
          dados da 4BUS podem ser enviados aqui https://4bus.com.br/ajuda . Você
          também pode o chat para enviar uma pergunta ao Encarregado da Proteção
          de Dados da 4BUS.
        </p>
        <br />
        <br />

        <h3>III. COLETA E USO DE DADOS</h3>

        <h3>A. DADOS QUE COLETAMOS</h3>

        <p>
          A 4BUS coleta: Dados informados à 4BUS pelos usuários, por exemplo,
          durante a criação da conta Dados criados durante o uso de nossos
          serviços, como dados de localização, utilização do app e dispositivo
          Dados de outras fontes, como usuários do nosso portal de gestão que
          usam APIs (Application Programming Interfaces – Interfaces de
          Programação de Aplicação) da 4BUS Os dados a seguir são coletados
          pelos apps da 4BUS ou em nome dela:
        </p>
        <p>1- Dados informados pelos usuários. Isso inclui:</p>
        <p>
          Perfil do usuário: coletamos dados quando os usuários criam ou alteram
          contas da 4BUS. Pode ser nome, e-mail, CPF e eventualmente o usuário
          pode adicionar uma foto de perfil por livre e espontânea vontade.
        </p>
        <p>
          Podemos usar as fotos enviadas pelos usuários para confirmar as
          identidades deles, por exemplo, por meio de tecnologias de
          reconhecimento facial. Para mais informações, consulte a seção “Como
          usamos os dados pessoais”.
        </p>
        <p>
          Dados demográficos: podemos coletar dados demográficos sobre os
          usuários, inclusive por meio de pesquisas com usuários.
        </p>
        <br />
        <p>2- Dados criados durante o uso dos nossos serviços. Isso inclui:</p>
        <p>
          Dados de localização do motorista: a 4BUS coleta esses dados quando
          seu app é executado em primeiro plano (está aberto e na tela) ou em
          segundo plano (aberto, mas fora da tela) no dispositivo móvel desses
          indivíduos.
        </p>
        <p>
          A 4BUS coleta os dados de localização do motorista durante a viagem e
          os vincula à conta do usuário, mesmo que o usuário não tenha nos
          permitido coletar os dados de localização do seu dispositivo. Isso nos
          permite acompanhar e monitorar as viagens e manter o centro de
          controle de operação (CCO) com informações em tempo real das viagens,
          como distância percorrida, tempos de viagem, pontos de embarque e
          desembarque.
        </p>
        <p>
          Dados de uso: coletamos dados sobre a interação dos usuários com
          nossos serviços. Isso inclui datas e horários de acesso, páginas ou
          recursos do app visualizados, problemas do app e outras atividades do
          sistema, tipo de navegador e sites ou serviços de terceiros utilizados
          antes da interação com nossos serviços. Em alguns casos, podemos fazer
          essa coleta por meio de cookies, pixels, tags e tecnologias similares
          que criam e mantêm identificadores únicos. Para saber mais sobre essas
          tecnologias, consulte o nosso Aviso sobre cookies.
        </p>
        <p>
          Dados do dispositivo: podemos coletar dados sobre os dispositivos
          usados para acessar nossos serviços, inclusive modelos de hardware,
          endereço IP do dispositivo, sistemas operacionais e versões, software,
          idiomas preferenciais, identificadores exclusivos do dispositivo,
          identificadores de publicidade, número de série, dados de movimento do
          dispositivo e dados da rede móvel.
        </p>
        <p>
          Dados de comunicação: permitimos que os usuários se comuniquem apenas
          recebendo mensagens enviadas pela nossa plataforma, mensagens de
          orientação e segurança apenas.
        </p>
        <br />
        <p>3. Como usamos os dados pessoais</p>
        <p>
          {" "}
          A 4BUS coleta e usa dados para viabilizar viagens de fretamento
          contínuo para empresas contratantes ou terceiras de forma confiável e
          prática. Também usamos os dados que coletamos:
        </p>
        <p>
          Para melhorar a segurança dos nossos usuários e serviços Para suporte
          ao cliente
        </p>
        <p>Para pesquisa e desenvolvimento</p>
        <p>Para permitir a comunicação entre os usuários</p>
        <p>Para utilizá-los em procedimentos jurídicos</p>
        <p>
          {" "}
          A 4BUS não vende nem compartilha dados pessoais dos usuários com
          terceiros para marketing direto.
        </p>
        <br />
        <p>
          {" "}
          A 4BUS usa os dados que coleta para os seguintes fins, entre outros:
        </p>
        <p> 1-Fornecer recursos e prestar serviços.</p>
        <p>
          {" "}
          A 4BUS usa os dados que coleta para oferecer, personalizar, manter e
          melhorar produtos e serviços.
        </p>
        <p>Isso inclui o uso dos dados para:</p>
        <p>Criar e alterar contas de usuários.</p>
        <p> Confirmar a identidade de motoristas próprios ou parceiros.</p>
        <p> Viabilizar viagens e outros serviços.</p>
        <p> Acompanhar e compartilhar o progresso das viagens.</p>
        <p>
          {" "}
          Realizar operações internas necessárias para prestar serviços,
          inclusive para solucionar problemas de software e operacionais, fazer
          análise, testes e pesquisas de dados, além de monitorar e analisar
          tendências de uso e atividade.
        </p>
        <p>
          {" "}
          2- Segurança. Usamos dados pessoais para manter a segurança e a
          integridade dos nossos serviços e usuários. Isso inclui:
        </p>
        <p>
          {" "}
          Processo de cadastro de usuários parceiros antes de permitir o uso dos
          serviços e em intervalos subsequentes, inclusive análise de checagem
          de antecedentes quando a lei permite, para impedir que motoristas
          parceiros e/ou usuários que representem risco à segurança usem nossos
          serviços.
        </p>
        <p>
          {" "}
          Uso de dados de dispositivos de motoristas para identificar
          comportamento perigoso ao volante, como excesso de velocidade ou
          freada e aceleração bruscas, e para informá-los sobre práticas de
          direção mais seguras.
        </p>
        <p>
          {" "}
          Uso de dados de dispositivo, localização, perfil, utilização e outros
          para impedir, detectar e combater fraudes ou atividades que
          representem risco à segurança.
        </p>
        <p> 3-Suporte ao cliente.</p>
        <p>
          {" "}
          A 4BUS usa as informações que coleta (inclusive gravações de chamadas
          para o suporte ao cliente, com aviso ao usuário e seu consentimento)
          para oferecer suporte ao cliente, inclusive para:
        </p>
        <p> Encaminhar perguntas ao profissional de suporte apropriado.</p>
        <p> Investigar e buscar soluções para as preocupações do usuário.</p>
        <p>
          {" "}
          Monitorar e melhorar respostas e processos do suporte ao cliente.
        </p>
        <p>
          {" "}
          4- Pesquisa e desenvolvimento. Podemos usar os dados que coletamos
          para teste, pesquisa, análise, desenvolvimento de produtos e
          aprendizado de máquina para melhorar a experiência do usuário. Isso
          nos permite melhorar e aprimorar a segurança dos serviços, aumentar a
          capacidade de impedir o uso dos nossos serviços para fins ilícitos ou
          inadequados, desenvolver novos recursos e produtos e facilitar
          soluções financeiras e de seguros associadas aos nossos serviços.
        </p>
        <p>
          {" "}
          5- Comunicados sem fins de marketing. A 4BUS pode usar os dados que
          coleta para, informá-los sobre mudanças em termos, serviços ou
          políticas ou enviar outros comunicados sem a finalidade de divulgar
          serviços e produtos ou os dos seus parceiros.
        </p>
        <p>
          {" "}
          6- Procedimentos e exigências legais. Podemos usar os dados pessoais
          que coletamos para investigar ou tratar de pedidos de indenização ou
          contestações relacionadas ao uso dos nossos serviços ou de outra forma
          permitida pela lei aplicável ou conforme solicitação de reguladores,
          entidades governamentais e consultas oficiais.
        </p>
        <br />
        <h3>C. COOKIES E TECNOLOGIAS DE TERCEIROS</h3>
        <p>
          {" "}
          A 4BUS e seus parceiros usam cookies e outras tecnologias de
          identificação nos seus apps, sites, e-mails e anúncios online para os
          fins descritos neste aviso.
        </p>
        <p>
          {" "}
          Os cookies são pequenos arquivos de texto armazenados em navegadores
          ou dispositivos por sites, apps, mídias online e anúncios. A 4BUS usa
          cookies e tecnologias similares para fins como:
        </p>
        <p> Autenticar .</p>
        <p>Lembrar preferências e configurações de usuários.</p>
        <p>
          {" "}
          Podemos também permitir que outros nos prestem serviços de medição e
          análise de público, mostrem anúncios na Internet em nosso nome e, por
          fim, monitorem e relatem o desempenho desses anúncios. Essas entidades
          podem usar cookies, web beacons, SDKs e outras tecnologias para
          identificar os dispositivos em que os visitantes usam nossos sites e
          outros serviços e sites online.
        </p>
        <p>
          {" "}
          Consulte nosso Aviso sobre Cookies para obter mais informações
          relacionadas ao uso de cookies e outras tecnologias descritas nesta
          seção.
        </p>
        <br />
        <h3> D. COMPARTILHAMENTO E DIVULGAÇÃO DE DADOS</h3>
        <p>
          {" "}
          Alguns dos recursos da 4BUS exigem o compartilhamento de dados
          EXCLUSIVAMENTE com usuários do app 4bus. Podemos também compartilhar
          dados com nossas afiliadas, subsidiárias e parceiras por motivos
          jurídicos ou em conexão com pedidos de indenização e contestações.
        </p>
        <p> A 4BUS pode compartilhar os dados que coleta:</p>
        <p> 1- Com usuários do app 4bus</p>
        <p> Isso inclui compartilhar:</p>
        <p>
          Nome, avaliação e local de partida e/ou destino das viagens com os
          motoristas.
        </p>
        <p>Endereço dos pontos de embarque e desembarque.</p>
        <p>2- Mediante solicitação do usuário</p>
        <p> Isso inclui compartilhamento de dados com:</p>
        <p>
          Outras pessoas mediante solicitação do usuário. Por exemplo,
          compartilhamos a previsão de chegada e a localização do usuário com os
          passageiros a serem embarcados no decorrer das viagens.
        </p>
        <p>
          {" "}
          Google, em conexão com o uso do Google Maps nos apps da 4BUS (veja a
          política de privacidade da Google para obter informações de coleta e
          uso de dados).
        </p>
        <p> Parceiros gestores de veículos.</p>
        <p> 3- Por motivos legais ou no caso de controvérsias</p>
        <p>
          {" "}
          A 4BUS pode compartilhar dados pessoais de usuários se acreditar que é
          obrigatório por lei, regulamento, licença ou contrato de operação,
          processo jurídico ou solicitação governamental ou nos casos em que a
          divulgação seja apropriada por outros motivos devido a preocupações
          com a segurança ou similares.
        </p>
        <p>
          Isso inclui o compartilhamento de dados pessoais com autoridades
          policiais, agentes de saúde pública e outras autoridades
          governamentais, contratos de usuário ou outras políticas; para
          proteger os direitos ou a propriedade da 4BUS ou os direitos, a
          segurança ou a propriedade de outros; ou em caso de pedido de
          indenização ou contestação com relação ao uso dos nossos serviços.
        </p>
        <p>4- Com consentimento</p>
        <p>
          A 4BUS pode compartilhar os dados pessoais do usuário de maneiras
          diferentes das descritas neste aviso se notificá-lo previamente e
          obtiver seu consentimento.
        </p>
        <h3> E. RETENÇÃO E EXCLUSÃO DE DADOS</h3>
        <p>
          {" "}
          A 4BUS retém os dados dos usuários pelo tempo necessário para os fins
          descritos acima.
        </p>
        <p>
          {" "}
          Os usuários podem solicitar a exclusão das suas contas a qualquer
          momento.
        </p>
        <p>
          {" "}
          A 4BUS pode reter os dados de um usuário após uma solicitação de
          exclusão devido a exigências legais ou regulatórias ou pelos motivos
          declarados nesta política.
        </p>
        <p>
          {" "}
          A 4BUS retém os dados dos usuários pelo tempo necessário para os fins
          descritos acima. Isso significa que retemos diferentes categorias de
          dados por períodos diferentes, dependendo da categoria do usuário a
          quem os dados se referem, dos tipos de dados e da finalidade da coleta
          deles.
        </p>
        <h3> F. BASES PARA O PROCESSAMENTO</h3>
        <p>
          {" "}
          Coletamos e usamos dados pessoais apenas quando temos respaldo legal
          para isso. Isso inclui o tratamento de dados pessoais para prestar
          serviços e oferecer recursos solicitados, para fins de interesses
          legítimos da 4BUS ou de terceiros, para cumprir obrigações legais ou
          com base em consentimento.
        </p>
        <p>
          {" "}
          Coletamos e usamos dados pessoais apenas quando temos uma ou mais
          bases legais para isso. Essas bases podem variar de acordo com a
          localização dos usuários, mas geralmente incluem tratamento de dados
          pessoais:
        </p>
        <p>
          {" "}
          Para prestar serviços, precisamos coletar e usar certos dados
          pessoais. Isso inclui:
        </p>
        <p>
          {" "}
          Dados do perfil de usuário usados para criar e manter contas de
          usuários; confirmar a identidade dos usuários.
        </p>
        <p>
          {" "}
          Dados de localização do motorista usados para acompanhar viagens e
          ajudar com a navegação.
        </p>
        <p>
          Dados de utilização necessários para manter, otimizar e melhorar os
          serviços da 4BUS, inclusive para determinar melhorias nas rotas
          percorridas e calcular custos de viagens e ganhos para os clientes
          contratantes;
        </p>
        <br />
        <h3><b>V. ALTERAÇÕES DESTE AVISO</b></h3>
        <p>
          {" "}
          Ocasionalmente, podemos alterar esse aviso. O uso dos nossos serviços
          depois de uma alteração constitui o consentimento com o aviso de
          alteração dentro do limite permitido por lei.
        </p>
        <p>
          {" "}
          Ocasionalmente, podemos alterar esse aviso. Se fizermos alterações
          significativas, notificaremos com antecedência os usuários pelos apps
          da 4BUS ou outros meios, como e-mail. Recomendamos que os usuários
          revejam periodicamente esse aviso para saber as informações mais
          recentes sobre nossas práticas de privacidade.
        </p>
        <p>
          {" "}
          Este aplicativo é mantido e operado por 4BUS Tecnologia do transporte
          SA.
        </p>
        <p>
          Por ser um aplicativo de uso exclusivo para os motoristas, que são
          funcionários registrados dos clientes que contratam os serviços do
          4BUS, todos os dados pessoais que utilizamos já foram devidamente
          cadastrados no sistema e, portanto, a manutenção e posse destes dados
          pertencem ao cliente. Ao utilizá-lo, agimos na qualidade de
          controlador desses dados e estamos sujeitos às disposições da Lei
          Federal n. 13.709/2018 (Lei Geral de Proteção de Dados Pessoais -
          LGPD).
        </p>
        <p> Como entrar em contato conosco</p>
        <p>
          {" "}
          Para esclarecer quaisquer dúvidas sobre esta Política de Privacidade
          ou sobre os dados pessoais que tratamos, entre em contato com nosso
          Encarregado de Proteção de Dados Pessoais, por algum dos canais
          mencionados abaixo:
        </p>
        <p> E-mail: contato@4bus.com.br</p>
        <p> Suporte via chat no botão Ajuda ou através do App </p>
        <p>
          {" "}
        </p>
      </Container>
    </Box>
  );
}

export default CharterTermsOfUseFretado;
