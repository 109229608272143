import Actions from '../actions';
import moment from 'moment';

const initialState = {
  customer: null,
  error: null,
  isNaturalPerson: null,
};

const customerReducer = (state = initialState, action) => {
  // console.log("action", action.type, action);
  switch (action.type) {
    case Actions.customer.types.GET_CUSTOMER_SUCCESS:
      const customer = { ...action.data };
      if (customer && customer.birthdate) {
        customer.birthdate = moment(new Date(customer.birthdate))
          .utcOffset(3)
          .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
          .toString();
      }

      return {
        ...state,
        customer,
        isNaturalPerson: Boolean(
          !Boolean(customer && customer.id) ||
            (customer && customer.rg && customer.rg.length > 0),
        ),
      };

    case Actions.customer.types.GET_CUSTOMER_ERROR:
      // console.log("action Actions.customer.types.GET_CUSTOMER_ERROR", action);
      return {
        ...state,
        error: action.error,
      };

    case Actions.customer.types.SET_IS_NATURAL_PERSON:
      // console.log("action Actions.customer.types.SET_IS_NATURAL_PERSON", action);
      return {
        ...state,
        isNaturalPerson: action.isNaturalPerson,
      };

    default:
      return state;
  }
};

export default customerReducer;
