import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Link, withRouter } from 'react-router-dom';
import { FaGoogle } from 'react-icons/fa';
import Paper from '@material-ui/core/Paper';
import EmailIcon from '@material-ui/icons/Email';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components'
import ROUTES from './../constants/routes';

import {
  authSignInGoogle,
  enqueueSnackbar,
  removeSnackbar
} from './../actions';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: '80px',
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 350,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  button: {
    marginTop: theme.spacing(3),
    textTransform: 'none'
  },
  signup: {
    marginTop: theme.spacing(5),
    textAlign: 'center',
    textTransform: 'none'
  },
  google: {
    background: '#eeeeee',
    '&:hover': {
      background: '#cccccc',
    }
  },
}));

const GoogleIcon = styled(FaGoogle)`
  color: #EA4235;
`

const Login = (props) => {
  const classes = useStyles();
  const {
    signInGoogle,
    user,
    history
  } = props;

  const handleGoogleSignIn = () => {
    signInGoogle();
  };

  useEffect(() => {
    if(user) {
      history.push(ROUTES.HOME)
    }
  }, [history, user])

  return (
    <>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h6" align="center">
            Iniciar sessão
          </Typography>
          <Button
            variant="contained"
            className={`${classes.button} ${classes.google}`}
            startIcon={<GoogleIcon />}
            fullWidth
            onClick={handleGoogleSignIn}
          >
            Acesse com Google
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            startIcon={<EmailIcon />}
            fullWidth
            component={Link}
            to={ROUTES.SIGNIN}
          >
            Acesse com seu e-mail
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className={classes.signup}
            fullWidth
            component={Link}
            to={ROUTES.SIGNUP}
          >
            Inscreva-se
          </Button>
        </Paper>
      </main>
    </>
  );
}

const mapStateToProps = store => ({
  user: store.auth.user,
  isLoading: store.auth.isLoading,
  error: store.auth.error,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      signInGoogle: authSignInGoogle,
      enqueueSnackbar: enqueueSnackbar,
      removeSnackbar: removeSnackbar
    },
    dispatch
  );

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Login));