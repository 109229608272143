import React, { Component, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import './index.css';
import { Fab, withStyles } from '@material-ui/core';
import { Print } from '@material-ui/icons';
import ReactDOMServer from 'react-dom/server';

import { jsPDF } from 'jspdf';

import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { db, storage } from './../../../firebase';
import 'firebase/firestore';
const storageRef = storage.ref('bookings');

const PrintIcon = styled(Print)`
  margin-right: 10px;
`;

const PrintButton = styled(Fab)`
  background: #7db234;
  &:hover {
    background: #a4d65f;
  }
`;

const PrintBooking = (props) => {
  const [bookingId, setBookingId] = useState(
    parseInt(props.match.params.bookingId)
  );
  const [passengerId, setPassenterId] = useState(
    parseInt(props.match.params.passengerId)
  );
  const [bookingCode, setBookingCode] = useState(
    props.match.params.bookingCode
  );
  const [loadingApi, setLoadingApi] = useState(false);
  const [items, setItems] = useState([]);
  const [passengersUrls, setPassengersUrls] = useState({});

  const fetchItems = async () => {
    setLoadingApi(true);
    let query = db.collection('bookings');
    query = query
      .where('id', '==', bookingId)
      .where('bookingCode', '==', bookingCode);

    await query.get().then((querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => doc.data());
      setItems(data);
      setLoadingApi(false);
    });

  };

  const getImage = async (bookingCode, passengerId) => {
    return await storageRef
      .child(`${bookingCode}-${passengerId}.jpg`)
      .getDownloadURL()
      .then((url) => {
        return url
      })
      .catch((error) => {
        console.log('Erro ao buscar imagens', error);
      });
  };
  const getCompanyImage = async (id, companyId, passengerId, urlPassengerImage) => {
    let url = '';
    await storage.ref('company')
      .child(`${companyId}.jpeg`)
      .getDownloadURL()
      .then((url) => {
        url = url;
        setPassengersUrls((oldState) => (
          { ...oldState, ...{ [id]: { ...{ passengerId: passengerId, companyImage: url, url: urlPassengerImage } } } })
        );

      })
      .catch((error) => {
      });
    return url;
  };
  const fetchImages = async () => {
    items.map((item) => {
      item.passengers.map((passenger) => {
        getImage(passenger.bookingCode, passenger.passengerId).then((urlPassenger) => {
          getCompanyImage(passenger.id, passenger.segment.trip.busCompanyId, passenger.passengerId, urlPassenger)
        })
      });
    });
  };

  useEffect(() => {
    // console.log('teste,', {
    //   bookingId: bookingId,
    //   passengerId: passengerId,
    //   bookingCode: bookingCode,
    // });
    fetchItems();
  }, []);

  const buttonClick = () => {
    window.print();
  };

  useEffect(() => {
    fetchImages();
   
  }, [items]);

  useEffect(() => {
   
  }, [passengersUrls, items]);

  return (
    <>
      {Object.values(passengersUrls) !== [] && (
        <>
          <Link
            id='buttonPrint'
            align='center'
            onClick={() => buttonClick()}
            style={{ textDecoration: 'none' }}
          >
            <PrintButton
              variant='extended'
              color='primary'
              aria-label='Imprimir'
            >
              <PrintIcon />
              Imprimir
            </PrintButton>
          </Link>
          <div id='printable'>
            {items.map((item) =>
              item.passengers.map(
                (passenger) =>
                  Object.values(passengersUrls).length === item.passengers.length && (
                    <div id='content'>
                      <div style={{ backgroundColor: '#ffffff' }}>
                        <table align='center' border={0}>
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  textAlign: 'center',
                                  verticalAlign: 'top',
                                  direction: 'ltr',
                                  fontSize: '0px',
                                  padding: '0px 0px 0px 0px',
                                }}
                              >
                                <div
                                  className='m_2777253878547091802mj-column-per-100'
                                  style={{
                                    verticalAlign: 'top',
                                    display: 'inline-block',
                                    direction: 'ltr',
                                    fontSize: '13px',
                                    textAlign: 'left',
                                    width: '100%',
                                  }}
                                >
                                  <table
                                    role='presentation'
                                    cellPadding={0}
                                    cellSpacing={0}
                                    style={{ verticalAlign: 'top' }}
                                    width='100%'
                                    border={0}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          style={{
                                            wordWrap: 'break-word',
                                            fontSize: '0px',
                                            padding: '0px 0px 0px 0px',
                                          }}
                                          align='center'
                                        >
                                          <table
                                            role='presentation'
                                            cellPadding={0}
                                            cellSpacing={0}
                                            style={{
                                              borderCollapse: 'collapse',
                                              borderSpacing: '0px',
                                            }}
                                            align='center'
                                            border={0}
                                          >
                                            <tbody>
                                              <tr>
                                                <td style={{ width: '246px' }}>
                                                  {/** TODO: ADD COMPANY LOGO
                                                   * Get booking info for company id
                                                   * Fetch company id image in storage 
                                                   */}
                                                  <img
                                                    alt=''
                                                    title
                                                    height='auto'
                                                    src={passengersUrls[passenger.id].companyImage}
                                                    style={{
                                                      border: 'none',
                                                      borderRadius: '0px',
                                                      display: 'block',
                                                      fontSize: '13px',
                                                      outline: 'none',
                                                      textDecoration: 'none',
                                                      width: '50%',
                                                      height: 'auto',
                                                    }}
                                                    width={246}
                                                  />
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          align='left'
                                          style={{
                                            paddingLeft: '24px',
                                            paddingRight: '24px',
                                            backgroundColor: '#ffffff',
                                          }}
                                        ></td>
                                      </tr>
                                      <tr>
                                        <td
                                          align='left'
                                          style={{
                                            backgroundColor: '#ffffff',
                                          }}
                                        >
                                          <div
                                            style={{
                                              fontFamily:
                                                'Helvetica,Arial,sans-serif',
                                              fontSize: '14px',
                                              lineHeight: '20px',
                                              textAlign: 'left',
                                              color: '#333333',
                                            }}
                                          >
                                            <table>
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <b>Viagem para:</b>
                                                  </td>
                                                  <td>
                                                    {' '}
                                                    {
                                                      item.trip.arrival.city
                                                        .name
                                                    }
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <b>Data e hora:</b>
                                                  </td>
                                                  <td>
                                                    {' '}
                                                    {new Date(
                                                      item.trip.etdTimestamp
                                                    ).toLocaleString('pt-BR')}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <b>Embarque:</b>
                                                  </td>
                                                  <td>
                                                    {' '}
                                                    <a
                                                      href={`https://www.google.com/maps/search/${item.trip.departure
                                                        .address
                                                        ? item.trip.departure
                                                          .address
                                                        : item.trip.departure
                                                          .name
                                                        },+Brasil?entry=gmail&source=g`}
                                                    >
                                                      {item.trip.departure
                                                        .address
                                                        ? item.trip.departure
                                                          .address
                                                        : item.trip.departure
                                                          .name}
                                                      , Brasil
                                                    </a>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <b>Ônibus:</b>
                                                  </td>
                                                  <td>
                                                    {' '}
                                                    {item.trip.bus.licensePlate
                                                      ? item.trip.bus
                                                        .licensePlate
                                                      : item.trip.bus.busType
                                                        .name}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <b>Código da viagem:</b>
                                                  </td>
                                                  <td> {item.tripId}</td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <b>Passageiro:</b>
                                                  </td>
                                                  <td>
                                                    {' '}
                                                    {passenger.name +
                                                      ' - RG: ' +
                                                      passenger.passenger
                                                        .idNumber}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <b>Código embarque:</b>
                                                  </td>
                                                  <td>
                                                    {' '}
                                                    {passenger.locator &&
                                                      passenger.locator}
                                                    {!passenger.locator &&
                                                      `${item.bookingCode}-${passenger.passengerId}`}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td></td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <br />
                                            <p align='center'>
                                              {!passenger.locator && (
                                                <b>Cartão de embarque</b>
                                              )}
                                            </p>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                    <tr>
                                      {!passenger.locator && (
                                        <td
                                          style={{ width: '100px' }}
                                          align='center'
                                        >
                                          <img
                                            className='image'
                                            src={passengersUrls[passenger.id].url}
                                            style={{
                                              border: 'none',
                                              borderRadius: '0px',
                                              display: 'block',
                                              fontSize: '13px',
                                              outline: 'none',
                                              textDecoration: 'none',
                                              width: '30%',
                                              height: 'auto',
                                            }}
                                            width={246}
                                            align='center'
                                          />
                                        </td>
                                      )}
                                    </tr>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )
              )
            )}
          </div>
        </>
      )}
    </>
  );
};

PrintBooking.propTypes = {};

export default withRouter(withStyles({}, { withTheme: true })(PrintBooking));
