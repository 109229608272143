import React, { useEffect, Fragment, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Box,
  Button,
  Grid,
  CircularProgress,
} from "@material-ui/core";

import "./TripCard.css";
import AppCurrencyFormat from "../shared/app-currency-format/AppCurrencyFormat";
import BookingStatuses from "../../models/BookingStatuses";
import CardHeader from "@material-ui/core/CardHeader";
import ModalEmail from "../modal-email-client";

import ROUTES from "../../constants/routes";
import TripDirections from "../trip-directions/TripDirections";
import Typography from "@material-ui/core/Typography";
import { authSignInOpenModal } from "../../actions";
import DetailTrip from "./DetailTrip";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { isAndroid, isIOS } from "react-device-detect";

import appleStore from "./applestore.png";
import playstore from "./playstore.jpg";

import facebookPixel from "../../utils/facebookPixel";
import googleAnalytics from "../../utils/googleAnalytics";
import { storage } from "./../../firebase";
import "firebase/firestore";
import AppConfirmation from "../shared/app-confirmation/AppConfirmation";
import { ErrorOutline } from "@material-ui/icons";
const storageRef = storage.ref("company");

function TripCard(props) {
  const { trip, history, authSignInOpenModal, user, onSelectTrip, isGoing } =
    props;
  const [selectedTrip, setSelectedTrip] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [openModalEmail, setOpenModalEmail] = useState(false);
  const [currentTrip, setCurrentTrip] = useState(null);
  const clientBySeller = JSON.parse(
    sessionStorage.getItem("clientSelectedBySeller")
  );
  const [companyImage, setCompanyImage] = useState("");
  const [modalStatusOpen, setModalStatusOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getImage(trip.bus.busCompanyId);
  }, []);

  const getImage = async (companyId) => {
    let url = "";
    await storageRef
      .child(`${companyId}.jpeg`)
      .getDownloadURL()
      .then((url) => {
        url = url;
        setCompanyImage(url);
      })
      .catch((error) => {
        console.log("url AQUI error", error);
      });
    return url;
  };

  const internalSelectTrip = (saveToStorage) => (trip) => {
    if (Boolean(user)) {
      if (saveToStorage) {
        sessionStorage.setItem("departTrip", JSON.stringify(trip));
      }

      history.push(ROUTES.CHECKOUT);
    } else {
      authSignInOpenModal();
      history.push(ROUTES.SIGNIN);
    }
  };

  const selectTrip = (trip) => {
    googleAnalytics(
      "",
      { category: "ecommerce", action: "add_to_cart", label: "add_to_cart" },
      ""
    );
    facebookPixel("", {
      type: "AddToCart",
      data: {
        content_name: trip.departure.city.name + " > " + trip.arrival.city.name,
        value: trip.ticketValue,
        currency: "BRL",
      },
    });

    if (Boolean(onSelectTrip)) {
      if (Boolean(user)) {
        onSelectTrip({
          trip,
          onSelectTripDefault: internalSelectTrip(false),
        });
      } else {
        authSignInOpenModal();
        history.push(ROUTES.SIGNIN);
      }
    } else {
      internalSelectTrip(true)(trip);
    }
  };

  //calculando a duração da viagem
  const startDate = new Date(trip.etd);
  const endDate = new Date(trip.eta);
  const duration = endDate - startDate;
  const durationInMinutes = duration / 1000 / 60;
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = Math.floor(durationInMinutes % 60);

  return (
    <Fragment>
      <CardHeader
        className="card-header"
        subheader={
          <Grid container className="company-logo-container">
            <Grid item>
              {companyImage?(
                <img
                  src = { companyImage }
                  className = "company-logo"
                  alt = "CompanyLogo"
                    />
              ): <CircularProgress size = { 30 } />}

            </Grid>

            <Grid item xs>
              <Grid className="text-type-ticket">
                <Typography
                  variant=""
                  //estilo do tipo de passagem
                  className="ticket-type"
                >
                  {trip.bus.busType.name}
                </Typography>
                <ErrorOutline
                  //estilo do ícone ao lado do tipo de passagem
                  className="icon-exclamation"
                  onClick={() => setModalStatusOpen(true)}
                ></ErrorOutline>
              </Grid>
            </Grid>
          </Grid>
        }
      />

      <Grid container>
        <Box className="all-card-itens">
          <Box className="trip-info">
            <Grid item>
              <h3>{moment.utc(trip.etd).format("LL")}</h3>
            </Grid>

            <Grid item>
              <h3 className="trip-duration">
                Duração da viagem: {hours}H {minutes}Min
              </h3>
            </Grid>
          </Box>

          <Box item xs className="trip-direction">
            <TripDirections trip={trip} />
          </Box>

          <Box className="box-value-and-select-ticket">
            <Typography root color="primary" className="ticket-price">
              <AppCurrencyFormat value={trip.ticketValue || 0} />
            </Typography>

            <Button
              variant="outlined"
              color="primary"
              disabled={trip.statusId === 8 || trip.statusId === 14}
              onClick={() => {
                sessionStorage.removeItem("returnTrip");
                if (isAndroid || isIOS) {
                  setSelectedTrip(trip);
                  handleClickOpen();
                } else {
                  if (user?.type === 5 && !clientBySeller?.id) {
                    setOpenModalEmail(true);
                    setCurrentTrip(trip);
                  } else {
                    selectTrip(trip);
                  }
                }
              }}
            >
              {trip.statusId === 8
                ? "Lotado"
                : trip.statusId === 14
                  ? "Grupo Fechado"
                  : "Selecionar"}
            </Button>
          </Box>
        </Box>

        <DetailTrip trip={trip} />

      </Grid>


      <ModalEmail
        open={openModalEmail}
        title="Informe o email do cliente"
        setOpen={setOpenModalEmail}
        selectTrip={selectTrip}
        currentTrip={currentTrip}
      />
      <AppConfirmation
        title={BookingStatuses[trip.statusId]}
        message={
          <>
            <Typography>
              {trip.statusId === 3 &&
                "Esta viagem está confirmada, portanto o seu embarque acontecerá no local e horário informado"}
              {trip.statusId === 2 &&
                "Esta viagem ainda não foi confirmada, mas fique tranquilo você pode fazer sua reserva e caso realmente não seja confirmada você será informado com no mínimo 12 horas de antecedência e o valor será estornado."}
              {trip.statusId === 8 &&
                "Viagem com ônibus lotado. Fique de olho, pode ser que alguém desista e então você poderá fazer sua reserva."}
            </Typography>
          </>
        }
        open={modalStatusOpen}
        handleClose={() => setModalStatusOpen(false)}
        actions={[
          {
            label: "Ok",
            onClick: () => {
              setModalStatusOpen(false);
            },
          },
        ]}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{
          style: {
            maxHeight: "50vh",
          },
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography color="primary">
              Baixe nosso app para ter uma experiência completa e acompanhar
              nossas promoções.
            </Typography>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={playstore}
                alt="Frente Onibus"
                style={{ width: 120, margin: "auto" }}
                onClick={(event) => {
                  event.preventDefault();
                  window.open(
                    "https://play.google.com/store/apps/details?id=br.com.fourbusapp"
                  );
                }}
              />
              <img
                src={appleStore}
                alt="Frente Onibus"
                style={{ width: 120, margin: "auto" }}
                onClick={(event) => {
                  event.preventDefault();
                  window.open(
                    "https://apps.apple.com/br/app/4bus/id1476990913?l=pt"
                  );
                }}
              />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              handleClose();
              selectTrip(selectedTrip);
            }}
            color="primary"
            autoFocus
          >
            Continuar no site
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

const mapStateToProps = (store) => ({
  user: store.auth.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      authSignInOpenModal: authSignInOpenModal,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TripCard)
);