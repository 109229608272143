import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { Box, Button, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AuthApiService from '../services/AuthApiService';
import { searchTrips, getFeaturedTrips, getUserLastSearches } from '../actions';

import CitiesRepository from '../repositories/CitiesRepository';

import TripCardsContainer from '../components/trip-cards-container/TripCardsContainer';
import TripSearchResults from '../components/trip-search/trip-search-results/TripSearchResults';

import UserLastSearchesContainer from '../components/user-last-searches-container/UserLastSearchesContainer';

import SettingsRepository from '../repositories/SettingsRepository';
import { useCallback } from 'react';
import UserNextTripCard from '../components/user-next-trip-card/UserNextTripCard';

const styles = makeStyles((theme) => ({
  button: {
    margin: '0 auto',
    textTransform: 'none',
    paddingRight: '10px',
    paddingLeft: '10px',
    border: '2px solid #02B3D4',
    color: '#02B3D4',
    background: '#ffffff',
    '&:hover, &:focus': {
      background: '#02B3D4',
      color: '#ffffff',
    },
  },
}));

const citiesRepository = new CitiesRepository();

function ResultSearch(props) {
  const classes = styles();
  const {
    featuredTrips,
    featuredTripsIsLoading,
    featuredTripsError,
    featuredTripsIsEmpty,
    getFeaturedTrips,
  } = props;

  const {
    userLastSearches,
    userLastSearchesIsLoading,
    userLastSearchesError,
    userLastSearchesIsEmpty,
    getUserLastSearches,
  } = props;

  const { user } = props;

  const [settings, setSettings] = useState(null);
  const [firstTrip, setFirstTrip] = useState(true);

  const dispatch = useDispatch();

  const {
    cityIdDeparture,
    cityIdArrival,
    departDate,
    returnDate,
  } = props.route.match.params;

  const handleDateFromParams = useCallback((array) => {
    const dateArray = array.split('-');
    return moment(
      new Date(
        parseInt(dateArray[2]),
        parseInt(dateArray[1]) - 1,
        parseInt(dateArray[0])
      )
    ).valueOf();
  }, []);

  useEffect(() => {
    if (Object.keys(props.route.match.params).length > 0) {
      const depDate = departDate && handleDateFromParams(departDate);
      const retDate = returnDate && handleDateFromParams(returnDate);

      const getDepartureCity = citiesRepository
        .searchCityById(cityIdDeparture)
        .then((res) => {
          return res[0];
        });
      const getArrivalCity = citiesRepository
        .searchCityById(cityIdArrival)
        .then((res) => {
          return res[0];
        });

      Promise.all([getDepartureCity, getArrivalCity]).then(
        ([departure, arrival]) => {
          return dispatch(
            searchTrips(
              departure,
              arrival,
              depDate,
              returnDate ? retDate : null,
              true,
              user && user.uidFirebase,
              () => {
                // console.log('PESQUISOUUUUU');
              }
            )
          );
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.route.match.params]);

  useEffect(() => {
    AuthApiService.getFullUser()
      .then((res) => {
        if (res.data.firstTrip) {
          setFirstTrip(true);
        } else {
          setFirstTrip(false);
        }
      })

    SettingsRepository.getDiscounts()
      .then((set) => {
        let discounts = [];
        set.docs.forEach((doc) => discounts.push(doc.data()));
        setSettings(discounts);
      })
      .catch((err) => console.log('SETTINGS err', err));
  }, [user]);
  useEffect(() => {
    let unmounted = false;
    getFeaturedTrips();
    if (!unmounted && !!user && !!user.uidFirebase) {
      getUserLastSearches(user.uidFirebase);
    }

    return () => {
      unmounted = true;
    };
  }, [getFeaturedTrips, getUserLastSearches, user]);

  return (
    <div>
      <div style={{ marginTop: '-21px' }}>
        <div
          className='content'
          style={{
            background:
              'url(https://images-adm.s3-sa-east-1.amazonaws.com/site-imagem-fundo.jpg) no-repeat center 55%',
          }}
        >
          <Box
            height='160px'
            width='100%'
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <Link
              variant='text'
              aria-label='Home'
              className={classes.button}
              component={Button}
              to='/'
            >
              Ir para a home
            </Link>
          </Box>
        </div>
      </div>

      <TripSearchResults settings={settings} firstTrip={firstTrip} />

      {!!user && (
        <>
          {/* <UserLastSearchesContainer
            title='Buscas recentes'
            trips={userLastSearches}
            settings={settings}
            firstTrip={firstTrip}
            isLoading={userLastSearchesIsLoading}
            error={userLastSearchesError}
            isEmpty={userLastSearchesIsEmpty}
            user={user}
          /> */}
          <UserNextTripCard
            title='Próximas viagens'
            settings={settings}
            firstTrip={firstTrip}
            isLoading={userLastSearchesIsLoading}
            error={userLastSearchesError}
            isEmpty={userLastSearchesIsEmpty}
            user={user}
          />
        </>
      )}

      {/* <TripCardsContainer
        title='Viagens em destaque'
        settings={settings}
        firstTrip={firstTrip}
        trips={featuredTrips}
        isLoading={featuredTripsIsLoading}
        error={featuredTripsError}
        isEmpty={featuredTripsIsEmpty}
      /> */}
    </div>
  );
}

ResultSearch.propTypes = {};

const mapStateToProps = (store) => ({
  featuredTrips: store.featuredTrips.trips,
  featuredTripsIsLoading: store.featuredTrips.isLoading,
  featuredTripsError: store.featuredTrips.error,
  featuredTripsIsEmpty: store.featuredTrips.isEmpty,

  userLastSearches: store.userLastSearches.trips,
  userLastSearchesIsLoading: store.userLastSearches.isLoading,
  userLastSearchesError: store.userLastSearches.error,
  userLastSearchesIsEmpty: store.userLastSearches.isEmpty,

  user: store.auth.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      searchTrips: searchTrips,
      getFeaturedTrips: getFeaturedTrips,
      getUserLastSearches: getUserLastSearches,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ResultSearch);
