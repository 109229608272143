import Autosuggest from 'react-autosuggest';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

const useStyles = makeStyles(theme => ({
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
}));

function renderInputComponent(props) {
  const { classes, inputRef = () => {}, ref, inputProps, ...other } = props;

  // console.log('props', props);

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
        ...inputProps,
      }}
      {...other}
    />
  );
}

function AutoComplete(props) {
  const classes = useStyles();
  const {
    onSuggestionChanged,
    onClick,
    onBlur,
    onSuggestionsFetch,
    onSuggestionsClear,
    getSuggestionValue,

    alwaysRenderSuggestions,
    focusInputOnSuggestionClick	,
    highlightFirstSuggestion,
    suggestions,
    value,
    ...other
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [autocompleteValue, setAutocompleteValue] = React.useState(
    Boolean(value) ? getSuggestionValue(value) : '',
  );

  const renderSuggestion = function(suggestion, { query, isHighlighted }) {
    const suggestionValue = getSuggestionValue(suggestion);
    const matches = match(suggestionValue, query);
    const parts = parse(suggestionValue, matches);

    // console.log('suggestion', suggestion);

    return (
      // <div style={{paddingTop:16,paddingBottom:16,paddingLeft:10,paddingRight:10,fontSize:"1rem",}}>
      // {parts.map(part => (
      //         <span
      //           key={Math.random() + part.text}
      //           style={{ fontWeight: part.highlight ? 500 : 400 }}
      //         >
      //           {part.text}
      //         </span>
      //       ))}
      // </div>
      <MenuItem
        key={Math.random() + suggestionValue}
        selected={isHighlighted}
        component="div"
      >
        <div key={Math.random()}>
          {parts.map(part => (
            <span
              key={Math.random() + part.text}
              style={{ fontWeight: part.highlight ? 500 : 400 }}
            >
              {part.text}
            </span>
          ))}
        </div>
      </MenuItem>
    );
  };

  const autosuggestProps = {
    renderInputComponent: renderInputComponent,
    suggestions: suggestions,
    onSuggestionsFetchRequested: onSuggestionsFetch,
    onSuggestionsClearRequested: onSuggestionsClear,
    getSuggestionValue,
    renderSuggestion,
    alwaysRenderSuggestions,
    focusInputOnSuggestionClick	,
    highlightFirstSuggestion,
  };

  return (
    <Autosuggest
      {...autosuggestProps}
      onSuggestionSelected={(_, { suggestion }) => {
        onSuggestionChanged(props.name, suggestion);
        setAutocompleteValue(getSuggestionValue(suggestion));
      }}
      
      onSuggestionHighlighted={({ suggestion }) => {
        suggestion && onSuggestionChanged(props.name, suggestion);
        suggestion && setAutocompleteValue(getSuggestionValue(suggestion));
      }}
      inputProps={{
        classes,
        value: autocompleteValue,
        onChange: (e, { newValue }) => {
          !Boolean(newValue) && onSuggestionChanged(props.name, newValue);

          setAutocompleteValue(newValue);
        },
        onBlur: onBlur,
        onClick: onClick,
        inputRef: node => {
          setAnchorEl(node);
        },
        // InputLabelProps: {
        //   shrink: true,
        // },
        ...other,
      }}
      theme={{
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion,
      }}
      renderSuggestionsContainer={options => (
        <Popper
          anchorEl={anchorEl}
          open={Boolean(options.children)}
          style={{ zIndex: 999999, position:"fixed" }}
        >
          <Paper
            square
            {...options.containerProps}
            style={{
              width: anchorEl ? anchorEl.clientWidth : 'auto',
            }}
          >
            {options.children}
          </Paper>
        </Popper>
      )}
    />
  );
}

export default AutoComplete;
