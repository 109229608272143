import { Box, Container } from "@material-ui/core";
import React, { useEffect } from "react";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import FaqRepository from "../repositories/FaqRepository";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    [theme.breakpoints.up("sm")]: {
      flexShrink: 0,
    },
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function CharterTermsOfUse() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [faqList, setFaqList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      setIsLoading(true);
      FaqRepository.getFaq().then((snapshot) => {
        const faqs = snapshot.docs.map((doc) => doc.data());
        // console.log(faqs);
        setTimeout(() => {
          setFaqList(faqs);
          setIsLoading(false);
        }, 500);
      });
    }

    return () => {
      unmounted = true;
    };
  }, [setFaqList]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box className={classes.root}>
      <Container>
        <h1>Termos de uso</h1>
        <p>
        4 BUS TECNOLOGIA NO TRANSPORTE DE PASSAGEIROS S/A, pessoa jurídica de direito privado inscrita no CNPJ sob o nº 34.782.998/0001-45, com endereço na Rua General Liberato Bittencourt, 1885, sala 504, Estreito, Florianópolis/SC, CEP 88070-800, estabelece os Termos de Uso e Política de Privacidade, conforme passa a expor abaixo.
Ao fazer o download ou usar o aplicativo, estes termos se aplicarão automaticamente a você - portanto, certifique-se de lê-los com atenção antes de usar o aplicativo
        </p>
        <h3>PREZADO USUÁRIO - LEIA COM ATENÇÃO ESTES TERMOS ANTES DE ACESSAR OU USAR OS SERVIÇOS</h3>
        <p></p>
        <h3>AVISO IMPORTANTE</h3>

        <p>
        Considerando que a 4 Bus funciona apenas como uma plataforma de conexão entre pessoas que desejam viajar, transitando pelo mesmo trajeto, na mesma data, e empresas que tem autorização para prestar serviços de transporte privado coletivo de passageiros na modalidade de frete eventual cadastradas em sua base de dados, tem-se que o endereço supra não corresponde a sua sede física, dado que, a companhia ainda não possui estabelecimento comercial destinado ao atendimento de seus usuários. 
Destarte, quaisquer tratativas advindas da relação proveniente da conexão entre usuários, plataforma e fretadoras, deve se dar exclusivamente por meio do serviço de HelpDesk, disponibilizado no site e aplicativo da 4 Bus
        </p>

        <h3>CLÁUSULA PRIMEIRA – RELACIONAMENTO CONTRATUAL</h3>

        <p>
        Estes Termos de uso regem seu acesso e uso, como pessoa física, dentro do Brasil, de aplicativos, sites de Internet, conteúdos, bens e também serviços disponibilizados pela 4 Bus ou qualquer de suas afiliadas. 
Acessando e usando os serviços deste aplicativo, você concorda com os presentes termos e condições, que estabelecem o relacionamento contratual entre você e a 4 Bus. Em caso de discordância com os termos, você não poderá acessar nem Utilizar os Serviços. 
A 4 Bus poderá imediatamente encerrar estes Termos ou quaisquer Serviços em relação a você ou, de modo geral, deixar de oferecer ou negar acesso aos Serviços ou a qualquer parte deles, a qualquer momento e por qualquer motivo.
        </p>

        <h3>CLÁUSULA SEGUNDA – OBJETO</h3>
        
        <p> Constituem objeto deste TERMOS DE USO a instalação, acesso e uso, dos seus aplicativos, sites de internet, conteúdos, bens e serviços produzidos, disponibilizados, distribuídos ou de qualquer forma fornecidos diretamente ou através da 4 Bus, por você e por terceiros, sejam pessoas naturais ou jurídicas, com domicílio no Brasil ou no exterior.</p>
        <h4>Parágrafo Único: </h4>
        <p>De agora em diante, a instalação, acesso e uso dos seus aplicativos, sites de internet, conteúdos, bens e serviços produzidos, disponibilizados, distribuídos pela 4 Bus passarão a ser coletivamente denominados de SERVIÇOS.</p>

        <h3>CLÁUSULA TERCEIRA – SERVIÇOS PRESTADOS</h3>
        <p>A 4 Bus é uma empresa de tecnologia cujos SERVIÇOS prestados através de aplicativos para dispositivos móveis e sites de internet: conectam pessoas que desejam viajar e que desejam fazer o mesmo trajeto, na mesma data, permitindo a formação de grupos com interesses comuns. Além disso, conectam esses grupos de pessoas a empresas cadastradas em sua plataforma de dados, que tem autorização para prestar serviços de transporte privado coletivo de passageiros na modalidade de frete eventual, permitindo, desta forma, que o grupo e a empresa negociem e contratem o serviço de transporte.</p>
        <h4>Parágrafo Único:</h4>
        <p>É de grande importância destacar que a 4 Bus não é uma empresa de transporte, não possui frota de veículos e também não é fornecedora de serviços de transporte. O objeto deste TERMOS DE USO é unicamente a intermediação entre pessoas e empresas de ônibus.  </p>
        
        <h3>CLÁUSULA QUARTA – TITULARIDADE SOBRE MARCA E SERVIÇOS</h3>
        <p>A 4 Bus é a detentora e titular exclusiva de todas as marcas, websites, aplicativos, programas de computador, registros, domínios, códigos-fonte e/ou quaisquer outras obras industriais e/ou intelectuais necessárias à prestação dos SERVIÇOS e que tenham sido desenvolvidos e/ou fornecidos pela 4 Bus.</p>
        <h4>Parágrafo Primeiro: </h4>
        <p>A observância completa aos TERMOS DE USO, concede ao usuário autorização para a utilização e acesso aos aplicativos em seu dispositivo móvel pessoal exclusivamente para uso dos SERVIÇOS nele oferecidos; Garante também o acesso ao site da 4 Bus na internet exclusivamente para uso dos SERVIÇOS nele oferecidos.</p>
        <h4>Parágrafo Segundo: </h4>
        <p>Essa licença de acesso e uso é conferida a título precário, podendo ser revogável a qualquer tempo. Ademais, a referida licença é exclusiva para uso pessoal, sendo vedada toda e qualquer utilização comercial.</p>
        <h4>Parágrafo Terceiro: </h4>
        <p>Quaisquer direitos não expressamente outorgados por estes TERMOS DE USO são reservados à 4 Bus e suas afiliadas licenciadoras.</p>
        
        <h3>CLÁUSULA QUINTA – LIMITAÇÕES DE USO</h3>
        <p>Você reconhece que está veemente proibido de remover quaisquer avisos de direitos de propriedade, de marca e autorais ou qualquer outro aviso de direito de propriedade de qualquer parte dos SERVIÇOS da 4 Bus. </p>
        <h4>Parágrafo Primeiro: </h4>
        <p>É vedado, também, modificar, reproduzir, preparar obras derivadas, vender, revender, distribuir, licenciar, locar, transferir, exibir, veicular, transmitir ou, de qualquer outro modo, explorar os SERVIÇOS, com exclusão do que foi permitido por esse TERMO DE USO;</p>
        <h4>Parágrafo Segundo: </h4>
        <p>Não é permitido conectar, espelhar, recortar, fazer ou lançar quaisquer programas ou scripts com a finalidade de fazer scraping, indexação, pesquisa ou qualquer outra forma de obtenção de dados de qualquer parte dos SERVIÇOS, ou de sobrecarregar ou prejudicar indevidamente a operação e/ou funcionalidade de qualquer aspecto dos SERVIÇOS ou ainda decompilar, realizar engenharia reversa ou desmontar os SERVIÇOS fora das estritas hipóteses permitidas;  </p>
        <h4>Parágrafo Terceiro:</h4>
        <p>É vedado tentar obter acesso não autorizado aos SERVIÇOS ou ao banco de dados mantido pela 4 Bus e suas afiliadas, tampouco prejudicar qualquer aspecto dos SERVIÇOS ou seus sistemas ou redes correlatas;</p>
        <h4>Parágrafo Quarto: </h4>
        <p>Estes TERMOS DE USO não outorgam nem conferem qualquer direito sobre os SERVIÇOS, tampouco permitem usar ou de qualquer modo fazer referência a nomes societários, logotipos, nomes de produtos ou de serviços, marcas comerciais ou marcas de serviço da 4 Bus.</p>
        <h4>Parágrafo Quinto: </h4>
        <p>O descumprimento desta cláusula ou de quaisquer de seus parágrafos poderá resultar no seu banimento, além das devidas responsabilizações civis e criminais previstas pela legislação pátria. </p>
        
        <h3>CLÁUSULA SEXTA – UTILIZAÇÃO DOS SERVIÇOS</h3>
        <p>Utilização dos SERVIÇOS da 4 Bus está diretamente relacionado a concordância e ao cumprimento dos TERMOS DE USO. </p>
        <h4>Parágrafo Primeiro:</h4>
        <p>A utilização dos SERVIÇOS só é permitida para maiores de 18 anos, desde que civilmente capazes;</p>
        <h4>Parágrafo Segundo:</h4>
        <p>Você é responsável por todas as atividades realizadas na sua conta e concorda em manter sempre a segurança e o sigilo do nome de usuário e senha por você escolhidos;</p>
        <h4>Parágrafo Terceiro:</h4>
        <p>É necessário efetuar cadastro junto a alguma das plataformas da 4 Bus (site, aplicativo ou outro) para utilizar os SERVIÇOS da 4 Bus;</p>
        <h4>Parágrafo Quarto: </h4>
        <p>Será permitido que você tenha apenas um único cadastro junto à 4 Bus, que será vinculado ao seu CPF ou documento de identificação, excetuando os casos que forem permitidos pela 4 Bus;</p>
        <h4>Parágrafo Quinto:</h4>
        <p>Para fazer o cadastro é necessário apresentar informações pessoas, como nome, CPF, documento de identidade, data de aniversário, endereço, número de telefone celular, bem como um método licito e válido de pagamento. Caso a forma de pagamento for invalida ou expirar, a 4 Bus poderá impedir você de acessar e usar os SERVIÇOS; </p>
        <h4>Parágrafo Sexto:</h4>
        <p>Você se responsabiliza a fornecer, com absoluto respeito à verdade, todas as informações que lhe forem solicitadas e concorda em manter todos os dados completos e devidamente atualizados.  </p>
        <h4>Parágrafo Sétimo :</h4>
        <p>Você concorda e se responsabiliza em compartilhar todos os dados coletados pelas plataformas com a 4 Bus (https://api.4bus.com.br/).</p>

        <h3>CLÁUSULA SÉTIMA – REGRAS PARA VIAGENS</h3>
        <p>Para realizar viagens por meio dos SERVIÇOS da 4 Bus é necessário informar todos os dados requeridos pela 4 Bus. Poderá ser solicitado informações a respeito do destino, data de preferência, hora da viagem e valor máximo de rateio que poderá arcar.</p>
        <h4>Parágrafo Primeiro:</h4>
        <p>A entrada em um grupo de viagem só será efetivada após a compensação bancária do pagamento da reserva de vaga, que será feito exclusivamente pelos meios disponibilizados pelo aplicativo;  </p>
        <h4>Parágrafo Segundo:  </h4>
        <p>A entrada em um grupo já existente ou a sugestão de novo grupo implica em outorga de poderes para que a 4 Bus celebre, em seu nome, contrato de fretamento com empresa de transporte devidamente registrada e autorizada pelos órgãos competentes, bem como aos critérios extras de qualidade e segurança eventualmente exigidos pela 4 Bus;</p>
        <h4>Parágrafo Terceiro: </h4>
        <p>Você concorda que somente entrará em um grupo se puder se comprometer e garantir para todos os componentes do grupo que participará do rateio do frete;</p>
        <h4>Parágrafo Quarto: </h4>
        <p>Você concorda que arcará com o valor definido para a viagem, que efetivou a entrada, sempre que o número mínimo de pessoas necessárias para atingir aquele valor for atingido, de forma que tais atos são irrevogáveis e irretratáveis, independente de justo motivo ou da ocorrência de casos fortuitos ou motivos de força maior, salvo se a desistência for realizada com pelo menos 24 horas de antecedência do horário da viagem;</p>
        <h4>Parágrafo Quinto:</h4>
        <p>Durante as suas viagens, você deverá sempre portar o documento de identidade informado no cadastro da 4 Bus e deverá apresentar sempre que for exigido por autoridades competentes de órgãos policiais e de fiscalização, bem como pelos responsáveis pelo serviço de transporte e seus empregados e prepostos;</p>
        <h4>Parágrafo Sexto:  </h4>
        <p>Durante as suas viagens, você se compromete a cumprir, todas as regras definidas pela 4 Bus, as regras definidas pela empresa responsável pelo fornecimento do serviço de transporte e, também, as todas as regras aplicáveis aos passageiros de transporte;   </p>
        <h4>Parágrafo Sétimo:</h4>
        <p>Você deverá sempre tratar os demais componentes do seu grupo de viagem e os prestadores e prepostos do serviço de transporte com civilidade e respeito, de modo que não serão toleradas condutas discriminatórias ou vexatórias. Quaisquer reclamações poderão implicar na proibição temporária ou definitiva do uso dos SERVIÇOS;</p>
        <h4>Parágrafo Oitavo: </h4>
        <p>Nas viagens, é proibido transportar (consigo ou em bagagem) quaisquer itens ou substâncias ilegais, perigosa, em desacordo com a legislação de cada estado ou que provoquem ruído ou odor que cause incômodo a terceiros; </p>
        <h4>Parágrafo Nono:</h4>
        <p>É de sua inteira responsabilidade informar, previamente a viagem, os responsáveis pelo serviço de transporte, que possui substâncias ou itens que possam se enquadrar no parágrafo anterior, sob pena de tais substâncias ou itens poderem ser removidos do veículo durante o trajeto sem qualquer obrigação de guarda dos mesmos pelo transportador, e tampouco direito a qualquer indenização ou ressarcimento para você;</p>
        <h4>Parágrafo Dez: </h4>
        <p>Você deverá se apresentar e se manter sóbrio de modo a estar sempre pronto para atender orientações de segurança e de resposta a sinistros que eventualmente venham a ser emanadas pelos responsáveis pelo serviço de transporte e seus empregados e prepostos, bem como, vedada a apresentação sob o efeito de quaisquer substâncias psicotrópicas, podendo ser impedido de embarcar ou de permanecer no veículo sem direito a qualquer indenização ou ressarcimento se, a critério dos responsáveis pelo serviço de transporte e seus empregados e prepostos, represente risco para a segurança da viagem e dos demais passageiros.</p>
        <h4> </h4>

        <h3>CLÁUSULA OITAVA – PAGAMENTO</h3>
        <p>Para participar de um grupo de viagem, você deverá efetuar o pagamento da reserva de vaga no grupo, que será equivalente ao valor máximo de rateio de frete definido pela 4 Bus quando da criação do mesmo.</p>
        <h4>Parágrafo Primeiro:  </h4>
        <p>O pagamento da reserva de vaga está sujeito ao disposto na cláusula 7, parágrafo quarto dos presentes TERMOS DE USO, ou seja, não será reembolsável a menos que o arrependimento se dê em até 24h antes da viagem; </p>
        <h4>Parágrafo Segundo:  </h4>
        <p>O pagamento da reserva não implica em obrigatoriedade da realização da viagem pretendida, uma vez que não se confunde com compra de passagem; </p>
        <h4>Parágrafo Terceiro:  </h4>
        <p>Você está ciente de que as viagens só ocorrerão se for atingido o número mínimo de pessoas definidas para a viagem pela 4 Bus; </p>
        <h4>Parágrafo Quarto:  </h4>
        <p>Caso o número mínimo de pessoas no grupo não seja atingido a 4 bus se reserva no direito de cancelar a viagem e, por consequência, devolver o valor desembolsado a título de reserva de vaga;</p>
        <h4>Parágrafo Quinto:</h4>
        <p>O pagamento do valor da reserva de vaga não garante a prestação de serviços de transporte, uma vez que poderá não haver ônibus parceiro para executar a viagem, razão pela qual o trajeto será cancelado e, também neste caso, os valores pagos serão devolvidos; </p>
        <h4>Parágrafo Sexto: </h4>
        <p>O valor do rateio será obtido através de simples divisão entre o valor total do frete pelo número de pessoas no grupo. Esse valor será definido pela 4 Bus e somente após o grupo estiver fechado e o frete contratado; </p>
        <h4>Parágrafo Sétimo: </h4>
        <p>Em caso de diferença entre o valor pago pela reserva de vaga e o valor final definido para a viagem, você receberá a quantia paga a mais; </p>
        <h4>Parágrafo Oitavo: </h4>
        <p>Todas as devoluções serão feitas na modalidade de crédito, na sua conta junto à 4 Bus, esses valores poderão ser usados em novas reservas de vagas em outros grupos. Caso você deseje que a devolução ocorra em estorno bancário, será necessário solicitar junto a 4 Bus, sendo imprescindível a indicação de uma conta para o depósito do valor; </p>
        <h4>Parágrafo Nono: </h4>
        <p>O pagamento do rateio será feito pela 4 Bus, que atuará como simples facilitadora do pagamento do valor final do rateio por você e pelos outros membros do grupo para a empresa de transporte, razão pela qual o pagamento efetuado será considerado pagamento feito diretamente por você à empresa prestadora do serviço de transporte; </p>
        <h4>Parágrafo Dez:  </h4>
        <p>O valor final do rateio já inclui todos os tributos e impostos exigidos por lei.</p>

        <h3>CLÁUSULA NONA – SERVIÇOS E CONTEÚDOS DE TERCEIROS</h3>
        <p>Os SERVIÇOS oferecidos pela 4 Bus são de intermediação de viagens, todo o transporte de pessoas é feito por ônibus de terceiros independentes registrados, fiscalizados e autorizados pelos órgãos reguladores competentes a exercer a atividade de transporte privado coletivo de passageiros. Como a 4 Bus não é uma empresa de transporte, as empresas que executarão os serviços é que são responsáveis exclusivos pelo cumprimento de suas obrigações e garantias na forma da lei. </p>
        <h4>Parágrafo Primeiro:  </h4>
        <p>Além das empresas de transportes, os SERVICOS podem disponibilizados e acessados em conexão com serviços e conteúdo promocionais e comerciais de terceiros, inclusive publicidade, sobre os quais a 4 Bus não exercerá controle. Isso não implicará qualquer forma de endosso ou assunção de responsabilidade;</p>
        <h4>Parágrafo Segundo: </h4>
        <p>Termos de uso e políticas de privacidade diferentes poderão ser aplicáveis ao uso dos serviços de terceiros;</p>
        <h4>Parágrafo Terceiro:</h4>
        <p>Apple Inc., Google, Inc., Microsoft Corporation ou BlackBerry Limited e/ou suas subsidiárias e afiliadas internacionais serão terceiros beneficiários destes TERMOS DE USO, caso você acesse os SERVIÇOS usando aplicativos desenvolvidos para dispositivos móveis baseados em Apple iOS, Android, Microsoft Windows, ou Blackberry, respectivamente. Esses terceiros beneficiários não são partes destes TERMOS DE USO ou de qualquer outra relação contratual desenvolvida entre a 4 Bus e você, e não são responsáveis pela prestação dos SERVIÇOS ou por qualquer forma de suporte aos SERVIÇOS. Seu acesso aos SERVIÇOS usando esses dispositivos está sujeito às condições estabelecidas nos termos de serviços dos respectivos terceiros beneficiários.</p>
      
        <h3>CLÁUSULA DÉCIMA – GARANTIA, LIMITAÇÕES DE RESPONSABILIDADE E INDENIZAÇÕES</h3>
        <p>A entrada em um grupo ou a sugestão de um, bem como o pagamento devido pela reserva de vaga não garante a realização da viagem. Conforme supramencionados, as viagens só poderão ser efetivadas se for atingido um número mínimo de pessoas rateando o trajeto e caso haja uma empresa disponível para a realização da viagem.  </p>
        <h4>Parágrafo Primeiro: </h4>
        <p>Somente quando a 4 Bus confirmar a viagem é que está estará garantida. Essa confirmação será feita por intermédio de algum dos canais indicados por você quando do cadastro e não é necessário responder ou confirmar a leitura do comunicado para que se produzam os efeitos; </p>
        <h4>Parágrafo Segundo: </h4>
        <p>A 4 Bus só tem em seu cadastro empresas devidamente registradas, fiscalizadas e autorizadas pelos órgãos competentes a realizar transporte privado coletivo de passageiros;</p>
        <h4>Parágrafo Terceiro:</h4>
        <p>A 4 Bus não pode fornecer garantia por vícios ou fatos do serviço e nem se responsabiliza por eles, justamente por não prestar serviços de transporte e não fazer parte da cadeia de fornecedores; </p>
        <h4>Parágrafo Quarto:</h4>
        <p>Para sua segurança e para garantir a mínima qualidade da prestação de serviço a 4 Bus compromete-se a fazer checagens da regularidade documental e inspeções periódicas nos veículos das empresas de transporte constantes em seu banco de dados; </p>
        <h4>Parágrafo Quinto:</h4>
        <p>Em compromisso com a sua segurança a 4 Bus, também exigirá que toda empresa de transporte cadastrada em seu banco de dados mantenha sempre seguro coletivo e individual contra sinistros, danos e acidentes pessoais, bem como seguro contra sinistros ocorridos com bagagens despachadas.  Entretanto, você é responsável pela sua bagagem de mão;</p>
        <h4>Parágrafo Sexto: </h4>
        <p>A 4 Bus não se responsabiliza por danos indiretos, incidentais, especiais, punitivos ou emergentes, inclusive lucros cessantes, perda de dados, danos morais ou patrimoniais relacionados, associados ou decorrentes de qualquer uso dos SERVIÇOS; </p>
        <h4>Parágrafo Sétimo: </h4>
        <p>A 4 Bus recusa todas as declarações e garantias, expressas ou implícitas, não expressamente contidas nestes TERMOS DE USO, inclusive as garantias implícitas de comercialização, adequação a uma finalidade específica e não infringência;</p>
        <h4>Parágrafo Oitavo:  </h4>
        <p>Não é responsabilidade da 4 Bus nenhum dano ou prejuízo decorrente do uso dos SERVIÇOS ou sua incapacidade de acessar ou usar os SERVIÇOS e nem de qualquer operação ou relacionamento entre você e qualquer membro do grupo ou prestador de serviço de transporte, ainda que com base em avaliações prévias dos usuários a 4 Bus tenha sido alertada para a possibilidade desses danos;   </p>
        <h4>Parágrafo Nono: </h4>
        <p>Quando utilização dos SERVIÇOS você concorda que qualquer pagamento feito a você com base no seguro contra acidentes pessoais de passageiros mantido pela 4 Bus ou pelo prestador de serviço de transporte reduzirá qualquer indenização devida a você decorrente daquele mesmo acidente;</p>
        <h4>Parágrafo Dez:</h4>
        <p>Você concorda que a 4 Bus não tem responsabilidade em relação a você, por conta de qualquer serviço de transporte realizados por prestadores terceiros, incluindo, mas não se limitando a rotas adotadas pelos motoristas ou por quaisquer itens perdidos nos veículos.</p>
        
        <h3>CLÁUSULA DÉCIMA PRIMEIRA – ACESSO À REDE E EQUIPAMENTOS</h3>
        <p>Você é o único responsável pela obtenção e acesso à rede de dados necessário para usar os SERVIÇOS e é único responsável por arcar com todas as taxas e encargos necessários para tanto. Também é de sua responsabilidade adquirir e atualizar os equipamentos e dispositivos necessários para acessar e usar os SERVIÇOS da 4 Bus e quaisquer de suas atualizações.</p>
        <h4>Parágrafo Único: </h4>
        <p>A 4 Bus não garante que os SERVIÇOS, ou qualquer parte deles, funcionarão em qualquer equipamento ou dispositivo em particular. Além disso, os SERVIÇOS poderão estar sujeitos a mau funcionamento e atrasos inerentes ao uso da internet e de comunicações eletrônicas.</p>
        
        <h3>CLÁUSULA DÉCIMA SEGUNDA – DISPOSIÇÕES GERAIS</h3>
        <p>Você só poderá acessar e usar os SERVIÇOS da 4 Bus se concordar com os presentes TERMOS DE USO que estabelecem o relacionamento contratual entre você e a 4 Bus. </p>
        <h4>Parágrafo Primeiro:</h4>
        <p>Quaisquer termos adicionais complementares serão considerados e parte integrante destes TERMOS DE USO e em caso de divergência entre eles, os termos adicionais prevalecerão sobre estes TERMOS DE USO, somente com relação às matérias específicas das quais eles venham a tratar e, principalmente quanto as normativas de operação emitidas por órgão competentes quanto a pandemias e/ou desastres.</p>
        <h4>Parágrafo Segundo: </h4>
        <p>A 4 Bus tem total direito de encerrar os SERVIÇOS a qualquer tempo, total ou parcialmente, ou mesmo apenas em relação a você, ou, de modo geral, deixar de oferecer ou negar acesso aos SERVIÇOS ou a qualquer parte deles, a qualquer momento e por qualquer motivo, independente de prévio aviso ou notificação, sem que disso resulte qualquer direito ou obrigatoriedade de pagamento de indenizações;</p>
        <h4>Parágrafo Terceiro: </h4>
        <p>É permitido que a 4 Bus altere, a qualquer momento, os TERMOS DE USO relativos aos SERVIÇOS. As novas disposições entram em vigor no dia seguinte que a 4 Bus fizer a postagem dos aditamentos;  </p>
        <h4>Parágrafo Quarto: </h4>
        <p>Caso você continue a utilizar os SERVIÇOS após o aviso de aditamento representará o seu conhecimento e consentimento para com as novas regras e condições; </p>
        <h4>Parágrafo Quinto: </h4>
        <p>Quaisquer controvérsias decorrentes da aplicação dos presentes TERMOS DE USO serão dirimidas conforme as leis brasileiras, no foro da Comarca de São José/SC.</p>
        
        <h3>CLÁUSULA DÉCIMA TERCEIRA – VIAGENS PARA CRIANÇAS E ADOLESCENTES</h3>
        <p>Para fins de utilização dos SERVIÇOS da 4 Bus, considera-se criança, a pessoa com até 12 (doze) anos de idade incompletos, e adolescente a pessoa entre 12 (doze) e 18 (dezoito) anos de idade, conforme determina o art. 2º do Estatuto da Criança e do Adolescente.</p>
        <h4>Parágrafo Primeiro:</h4>
        <p>Crianças e adolescentes devem possuir reserva, viajar em assento individual, utilizando cinto de segurança, e quando necessário, equipamento de retenção (cadeirinha, bebê conforto, assento de elevação, etc.), o qual deve seguir as orientações da Resolução 277 do CONTRAN, sendo toda a responsabilidade e encargos, do responsável pelo(a) menor;</p>
        <h4>Parágrafo Segundo: </h4>
        <p>Nenhuma criança e/ou adolescente menor de 16 (dezesseis) anos poderá viajar para fora da comarca onde reside desacompanhado dos pais ou de responsável, sem expressa autorização judicial;</p>
        <h4>Parágrafo Terceiro:</h4>
        <p>A autorização aludida no parágrafo anterior somente será dispensada quando:
I – Tratar-se de comarca contígua à da residência da criança ou do adolescente menor de 16 (dezesseis) anos, se na mesma unidade da Federação, ou incluída na mesma região metropolitana;
II – A criança e/ou o adolescente menor de 16 (dezesseis) anos estiver acompanhado(a) de: 
a) ascendente ou colateral maior, até o terceiro grau, comprovado documentalmente o parentesco;
b) pessoa maior, munida de escritura pública ou de documento particular com firma reconhecida por semelhança ou autenticidade, por pai, mãe ou responsável legal do(a) menor, seguindo as determinações do art. 83, §1, inciso 2 do Estatuto da Criança e do Adolescente – ECA e art. 2º. II, inciso b) da Resolução 295 do CNJ;
</p>
        <h4>Parágrafo Quarto:</h4>
        <p>Para viagens com crianças e adolescentes é imprescindível a apresentação, cumulativa, dos seguintes documentos:</p>
        <p>I – Quando o(a) menor estiver acompanhado(a) de ambos os pais, ou do responsável legal: </p>
        <p>a) documento de identificação do(a) menor (carteira de identidade e/ou certidão de nascimento em bom estado de conservação);</p>
        <p>b) documento de identificação de ambos os pais ou do responsável legal, em bom estado de conservação;</p>
        <p>II – Quando o(a) menor estiver desacompanhado(a) de um dos pais: </p>
        <p>a) documento de identificação do(a) menor (carteira de identidade e/ou certidão de nascimento, em bom estado de conservação);</p>
        <p>b) documento de identificação do pai presente, em bom estado de conservação;</p>
        <p>c) autorização por escrito assinada pelo outro responsável, com firma reconhecida em cartório.</p>
        <p>III – Quando o(a) menor estiver acompanhado de outra pessoa maior de idade, cumulativamente:</p>
        <p>a) documento de identificação do(a) menor (carteira de identidade e/ou certidão de nascimento em bom estado de conservação);</p>
        <p>b) autorização por escrito assinada por ambos os pais ou responsável legal, com firma reconhecida em cartório;</p>
        <p>c) documento de identificação do maior acompanhante em bom estado de conservação.</p>
        <p>IV – Quando o(a) menor estiver desacompanhado:</p>
        <p>a) documento de identificação do(a) menor (carteira de identidade e/ou certidão de nascimento em bom estado de conservação);</p>
        <p>b) autorização por escrito assinada por ambos os pais ou responsável legal, com firma reconhecida em cartório;</p>
        <h4>Parágrafo Quinto:</h4>
        <p>Adolescentes de 16 (dezesseis) a 18 (dezoito) anos de idade não necessitam de autorização para viajar dentro do território nacional, sendo apenas necessário, como documentação obrigatória para embarque, documento oficial de identificação com foto;</p>
        <h4>Parágrafo Sexto:</h4>
        <p>Crianças e/ou adolescentes restam incluídas ao rol de pagamento habitual, ou seja, respondem integral e igualitariamente para fins de valores quando do rateio, dado que lhes serão atribuídas reservas individuais, bem como assentos próprios.</p>
        
        <h3>CLÁUSULA DÉCIMA QUARTA – VIAGENS PARA IDOSOS E ESTUDANTES</h3>
        <p>Idosos e estudantes não possuem descontos nas viagens intermediadas pela 4 Bus, pois esta regra é imposição feita exclusivamente às empresas de transporte público. Na 4 Bus, o transporte operado é o de modalidade de fretamento eventual, o qual trata dos passageiros como sendo um grupo de pessoas que se unem, dividindo o valor integral de uma viagem. Sendo assim, cada pessoa paga o mesmo valor do rateio. A relação não é edificada entre cada passageiro e a 4 Bus, mas entre o grupo a empresa parceira, que são apenas conectadas por intermédio da plataforma.</p>
        
        <h3>CLÁUSULA DÉCIMA QUINTA – VIAGENS COM ANIMAIS</h3>
<p>A 4 Bus adota a bandeira “ Pet Friendly ” e tem como praxe em suas viagens, a aceitação de transporte de animais domésticos, condição que autoriza o passageiro a embarcar consigo, animais domésticos de pequeno ou médio porte, dóceis, de até 08kg de peso corpóreo, devidamente acondicionados e de acordo com as disposições regulamentares pertinentes. O transporte deve ser realizado preservando a comodidade, segurança e conforto dos passageiros, bem como o bem-estar do animal; Será permitido apenas 02 (pets) por veículo, assim será necessário ver disponibilidade com antecedência.</p>
<h4>Parágrafo Primeiro :</h4>
<p>
   O transporte de animais domésticos somente será realizado mediante reserva de poltrona e respectivo pagamento. Para tanto, o proprietário do animal deverá efetuar transação adicional mediante o seu cadastro, com o seu CPF, fazendo constar como observação a expressão “R eserva para Animal Doméstico ”. Ou seja, além de sua reserva individual, o passageiro terá de realizar reserva adicional, idêntica a sua, para o animal, bem como, deverá executar o pagamento dessa, integral e igualitariamente, em respeito ao valor do rateio;        
</p>
<h4>Parágrafo Segundo :</h4>
<p>
   Os animais domésticos serão transportados sobre as poltronas, idêntico aos passageiros, na poltrona ao lado, em containers apropriados e com boas condições de higiene;        
</p>
<h4>Parágrafo Terceiro :</h4>
<p>
   Os animais domésticos somente terão embarque autorizado se acomodados em suas respectivas caixas transportadoras, as quais devem possuir, no máximo: 48,5cm de altura, 72cm de comprimento, e 52cm de largura, a caixa deverá estar forrada com tapete higiênico, que absorva fezes e urina do animal durante a viagem. Em toda parada, o responsável deverá se certificar da limpeza e alimentação do animal, não qual não poderá ser realizado no interior do ônibus.        
</p>
<h4>Parágrafo Quarto :</h4>
<p>
   Antes do embarque o proprietário do animal doméstico deverá apresentar ao motorista do ônibus:
<p>
   I – Documento atestando as boas condições de saúde do animal, assinado por médico veterinário regularmente inscrito no CRMV/UF, o qual deverá ter em sua descrição o nome completo do profissional e seu número de registro, assim como, deverá ser emitido com antecedência máxima de 15 (quinze) da data da viagem;
</p>
</p>
<p>
   II – Carteira de vacinação do animal, atualizada, contendo as vacinas antirrábica e polivalente;
</p>
<h4>Parágrafo Quinto :</h4>
<p>
   A 4 Bus , em nenhuma hipótese, responsabilizar-se-á pelo estado do animal, de modo que, poderá recusar o transporte desse, caso verifique que seu acondicionamento possa oferecer riscos a sua saúde, e/ou comprometer a segurança e o conforto dos demais passageiros.        
</p>
<h3>CLÁUSULA DÉCIMA SEXTA – CÃES-GUIA</h3>
<p>Apenas cães-guia têm prerrogativas de condução em transportes coletivos e, portanto, somente esses serão absolutamente aceitos, desde que possuam, cumulativamente, os seguintes itens:</p>
<p>I – Carteira e/ou Plaqueta de Identificação;</p>
<p>II – Carteira de vacinação atualizada, com comprovação da vacinação múltipla e antirrábica, assinada por médico veterinário com registro no órgão regulador da profissão;</p>
<p>III – Coleira, guia e arreio com alça.</p>
<h4>Parágrafo Único :</h4>
<p>
   O deficiente visual deve efetuar sua reserva normalmente, fazendo constar a observação: “ Acompanhado de Cão-guia ”, condição que lhe assegurará o transporte em assento preferencial com maior espaço a sua volta, ou um assento adicional, pelos quais não serão cobrados quaisquer valores adicionais.
</p>
<h3>CLÁUSULA DÉCIMA SÉTIMA – BAGAGENS, MERCADORIAS E OBJETOS DE USO PESSOAL</h3>
<p>A reserva de cada passageiro pressupõe o acondicionamento e transporte de 01 (uma) bagagem grande, ou 02 (duas) bagagens médias, ou 03 (três) bagagens pequenas, as quais seguem o parâmetro de tamanho demonstrado abaixo.</p>
<p>I – Bagagem grande: até 70cm de altura, 45cm de largura, e 26cm de profundidade;</p>
<p>II – Bagagem média: até 60cm de altura, 40cm de largura, e 23cm de profundidade;</p>
<p>III – Bagagem pequena: até 49cm de altura, 34cm de largura, e 19cm de profundidade;</p>
<h4>Parágrafo Primeiro :</h4>
<p>
   Dentro do espaço comum do ônibus, são permitidas apenas bagagens sem rodinhas, como bolsas e mochilas, de tamanho pequeno, de modo que as bagagens diversas a essas, serão etiquetadas pelo motorista, o qual entregará um ticket identificador a cada passageiro, respectivo a cada volume de sua propriedade, para que ao final da viagem, esse lhe seja devolvido a fim de que o resgate dos pertences seja procedido de forma organizada a evitar transtornos;
</p>
<h4>Parágrafo Segundo :</h4>
<p>
   As bagagens transportadas dentro do espaço comum do ônibus serão de responsabilidade única e absoluta de seus proprietários;
</p>
<h4>Parágrafo Terceiro :</h4>
<p>
   O transporte de mercadorias esportivas, só será procedido se os artigos estiverem devidamente embalados ou em uma bolsa para transporte, e as mercadorias eletroeletrônicas deverão ser de pequeno porte, embalado em caixa originais e mediante apresentação de suas respectivas notas fiscais, assim como, deverá observar as condições anteriormente dispostas nessa cláusula, de modo que a 4 Bus e a empresa parceira não se responsabilizam por danos ou perdas de componentes desses itens;
</p>
<h4>Parágrafo Quarto :</h4>
<p>
   Não serão considerados mercadorias, os aparelhos eletroeletrônicos que forem e estiverem sob uso pessoal e habitual do passageiro, tais como: celulares, smatphones, tablets, notebooks, pagers .
</p>
<h4>Parágrafo Quinto :</h4>
<p>
   A 4 Bus não ressarce, bem como não se responsabiliza, por objetos perdidos, deixados, avariados e/ou extraviados em viagens intermediadas pela plataforma, sendo tal circunstância, de responsabilidade da empresa parceira. Entretanto, visando otimizar a tratativa entre o passageiro e a fretadora, a 4 Bus opera no sentido de facilitar o contato entre as duas partes, oportunizando ao passageiro eventualmente lesado, o envio de suas solicitações e questionamentos ao e-mail contato@4bus.com.br , para que esses sejam remetidos à empresa responsável pela viagem, a qual deverá analisar e dar o devido prosseguimento ao caso.
</p>
<h1>POLÍTICA DE PRIVACIDADE</h1>
<p>
   A 4 Bus respeita a privacidade de seus usuários e está empenhada em protegê-la.
   Esta Política de Privacidade tem como finalidade estabelecer as regras relativas à coleta, tratamento e utilização das informações fornecidas pelos usuários por meio do nosso site: www.4bus.com.br ou do nosso aplicativo para dispositivos móveis (ambos denominados “Plataforma”).
   Em conjunto com os nossos Termos de Uso, bem como os demais documentos existentes na Plataforma, a Política de Privacidade estabelece as bases segundo as quais os dados pessoais que coletamos dos usuários serão processados pela 4 Bus .
   As informações coletadas em razão do uso da Plataforma poderão ser retidas, armazenadas, processadas, acessadas, usadas e compartilhadas em caráter sigiloso, nos termos da legislação aplicável.
   Esta página é usada para informar os visitantes sobre nossas políticas de coleta, uso e divulgação de Informações Pessoais, caso alguém decida usar nosso Serviço.
   Se você optar por usar nosso Serviço, você concorda com a coleta e uso de informações em relação a esta política. As informações pessoais que coletamos são usadas para fornecer e melhorar o serviço. Não usaremos ou compartilharemos suas informações com ninguém, exceto conforme descrito nesta Política de Privacidade.
   Os termos usados nesta Política de Privacidade têm os mesmos significados que em nossos Termos e Condições, que podem ser acessados na 4 Bus, a menos que definido de outra forma nesta Política de Privacidade.
   Ao acessar nossa Plataforma e concluir seu cadastro, você estará concordando integralmente com a Política de Privacidade aqui disposta.
</p>
<h3>PREZADO USUÁRIO - LEIA COM ATENÇÃO ESTA POLÍTICA DE PRIVACIDADE ANTES DE ACESSAR OU USAR OS SERVIÇOS</h3>
<h3>CLÁUSULA PRIMEIRA – DADOS DOS USUÁRIOS: COLETA, TRATAMENTO E UTILIZAÇÃO</h3>
<p>A 4 Bus recolhe dados pessoais dos usuários através do cadastro feito para iniciar a utilização do aplicativo.</p>
<h4>Parágrafo Primeiro :</h4>
<p>
   O envio de dados à 4 Bus pelos usuários implica concordância ao acesso a tais dados, em especial, mas não se limitando, a: (i) nome completo; (ii) foto identificadora; (iii) apelido; (iv) número de telefone; (v) documento pessoal; (vi) e-mail; (vii) senha de acesso; (viii) meios de pagamento; (ix) país de utilização; (x) dados de viagens, como origem e destino, tempo de duração, distância e (xi) avaliação da empresa que prestou o serviço de transporte coletivo privado;
</p>
<h4>Parágrafo Segundo :</h4>
<p>
   Os dados pessoais dos usuários serão inseridos em uma ficha padronizada pela 4 Bus , para que seja possível (i) manter uma adequada relação com o usuário; (ii) permitir o acesso ao serviço prestado pela 4 Bus ; (iii) prestar, gerenciar, administrar, ampliar e melhorar os serviços prestados por meio da Plataforma; (iv) atender adequadamente qualquer consulta ou solicitação de informação realizada pelo usuário e; (v) cumprir com a legislação aplicável que regula os serviços da 4 Bus ;
</p>
<h4>Parágrafo Terceiro :</h4>
<p>
   Para utilizar a Plataforma também poderá ser necessário coletar outras informações sobre dispositivos utilizados pelo usuário, como, por exemplo, informações de rede móvel e modelos de sistema operacional;
</p>
<h4>Parágrafo Quarto :</h4>
<p>
   O Usurário submete os dados de forma voluntária e concorda que a falta de quaisquer dados pode afetar a qualidade e a oferta dos serviços;
</p>
<h4>Parágrafo Quinto :</h4>
<p>
   O Usurário entende e concorda que é obrigatório o envio, à empresa que prestará o serviço de transporte, do nome completo e de um documento de identidade do Usuário tendo em vista as regras e regulamentações do setor de transporte coletivo privado de passageiros na modalidade fretamento eventual;
</p>
<h4>Parágrafo Sexto :</h4>
<p>
   O usuário, ao se cadastrar na Plataforma 4 Bus e concordar com os Termos de Uso, passa a concordar também com a divulgação desses dados para as empresas que realizarão as viagens;
</p>
<h4>Parágrafo Sétimo :</h4>
<p>
   O usuário concede para a 4 Bus o direito de registrar as atividades efetuadas pelo Usuário na Plataforma, por meio da coleta de informações como: (i) endereço IP do Usuário; (ii) ações efetuadas pelo Usuário na Plataforma; (iii) datas e horários de acesso à Plataforma;
</p>
<h4>Parágrafo Oitavo :</h4>
<p>
   A 4 Bus se compromete a não divulgar, vender ou alugar as suas informações pessoais para terceiros, sem prévio consentimento do Usuário nesse sentido, ou conforme exigido por lei ou decisão judicial, salvo com relação às empresas prestadoras dos serviços de transporte coletivo privado, uma vez que as viagens realizadas por meio da Plataforma presumem o compartilhamento das informações necessárias ao servido;
</p>
<h4>Parágrafo Nono :</h4>
<p>
   O usuário aceita que toda as informações fornecidas poderão ser usadas pela 4 Bus para a realização de analises, estudos e aperfeiçoamento do sistema e do serviço;
</p>
<h4>Parágrafo Dez :</h4>
<p>
   É de total responsabilidade do Usuário guardar e não repassar seu login e senha para terceiros, além de se responsabilizar integralmente pela sua conta, pelos dados fornecidos e pelo uso das plataformas;
</p>
<h4>Parágrafo Onze :</h4>
<p>
   O Usuário autoriza a 4 Bus a enviar mensagens, por eletrônicos e/ou mensagem SMS ou notificações por push e/ou em aplicativos, contendo informações ou promoções relacionadas aos serviços prestados;
</p>
<h4>Parágrafo Doze :</h4>
<p>
   A 4 Bus se compromete a manter a confidencialidade e o anonimato do avaliador e das avaliações prestadas pelos usuários dos serviços de transporte;
</p>
<h4>Parágrafo Treze :</h4>
<p>
   Caso o Usuário tenha o interesse em revogar a autorização do parágrafo décimo primeiro, este poderá fazê-lo mediante o envio de declaração contendo nome completo e número de telefone ao endereço de e-mail: contato@4bus.com.br .
</p>
<h4>Parágrafo Quatorze :</h4>
<p>Para uma melhor experiência, ao usar o nosso Serviço, podemos exigir que você nos forneça algumas informações de identificação pessoal, incluindo, mas não se limitando a Nome, sobrenome, CPF, telefone, data de nascimento, RG. As informações que solicitamos serão retidas por nós e usadas conforme descrito nesta política de privacidade.
O aplicativo usa serviços de terceiros que podem coletar informações usadas para identificá-lo.</p>
<p>Serviços terceirizados usados pelo aplicativo:</p>
<p>Serviços do Google Play</p>
<p>Google Analytics para Firebase</p>
<p>Firebase Crashlytics</p>
<p>Facebook</p>
<p>Mixpanel</p>

<h3>CLÁUSULA SEGUNDA – SEGURANÇA DOS DADOS PESSOAIS DOS USUÁRIOS</h3>
<p>Em que pese a 4 Bus cumprir com rigor todos os padrões de segurança para proteger as informações coletadas, infelizmente não há um método totalmente seguro de armazenamento eletrônico de dados. Dessa forma, apesar de todos os esforços da 4 Bus com o intuito de proteger as informações coletadas, não é possível garantir segurança absoluta.</p>
<h3>CLÁUSULA TERCEIRA – ALTERAÇÕES NA POLÍTICA DE PRIVACIDADE</h3>
<p>Podemos atualizar nossa Política de Privacidade de tempos em tempos. Portanto, é recomendável revisar esta página periodicamente para verificar quaisquer alterações. Iremos notificá-lo de quaisquer alterações, publicando a nova Política de Privacidade nesta página</p>
<h4>Parágrafo Primeiro :</h4>
<p>Em caso de alterações substanciais na Política de Privacidade ou relativa à forma de uso dos dados dos usuários, a 4 Bus promoverá esforços para dar publicidade às alterações, como, por exemplo, inserindo uma mensagem na Plataforma.
</p>
<h3>CLÁUSULA QUARTA – DADOS DE REGISTRO</h3>
<p>Queremos informá-lo que sempre que você utilizar nosso Serviço, em caso de erro no aplicativo, coletamos dados e informações (por meio de produtos de terceiros) em seu telefone denominado Log Data. Estes dados de registro podem incluir informações como endereço de protocolo de Internet ("IP") do dispositivo, nome do dispositivo, versão do sistema operacional, configuração do aplicativo ao utilizar nosso serviço, hora e data de uso do serviço e outras estatísticas .</p>

<h3>CLÁUSULA QUINTA – PROVEDORES DE SERVIÇO</h3>
<p>Podemos empregar empresas terceirizadas e indivíduos pelos seguintes motivos:

Para facilitar nosso serviço;
Para fornecer o Serviço em nosso nome;
Para executar serviços relacionados com o serviço; ou
Para nos ajudar a analisar como nosso serviço é usado.
Queremos informar aos usuários deste Serviço que esses terceiros têm acesso às suas Informações Pessoais. O motivo é realizar as tarefas atribuídas a eles em nosso nome. No entanto, eles são obrigados a não divulgar ou usar as informações para qualquer outra finalidade.</p>

<h3>CLÁUSULA SEXTA – SEGURANÇA</h3>
<p>Valorizamos sua confiança em nos fornecer suas informações pessoais, portanto, estamos nos esforçando para usar meios comercialmente aceitáveis de protegê-las. Mas lembre-se que nenhum método de transmissão pela internet, ou método de armazenamento eletrônico é 100% seguro e confiável, e não podemos garantir sua segurança absoluta.</p>

<h3>CLÁUSULA SÉTIMA – PRIVACIDADE DAS CRIANÇAS</h3>
<p>Esses Serviços não se dirigem a ninguém com menos de 13 anos. Não coletamos intencionalmente informações de identificação pessoal de crianças com menos de 13 anos. No caso de descobrirmos que uma criança menor de 13 anos nos forneceu informações pessoais, nós as apagamos imediatamente de nossos servidores. Se você é um pai ou responsável e sabe que seu filho nos forneceu informações pessoais, entre em contato para que possamos tomar as medidas necessárias.</p>

<h3>CLÁUSULA OITAVA – CONTATO</h3>
<p>Caso o usuário pretenda obter mais esclarecimentos sobre nossa Política de Privacidade ou caso queira nos dar sua opinião sobre nossas práticas de privacidade ou sobre a forma de uso de seus dados pessoais, poderá entrar em contato conosco por meio do e-mail: contato@4bus.com.br .</p>

<h3>CLÁUSULA NONA – LEI E FORO APLICÁVEIS</h3>
<p>Eventuais litígios referentes ao uso da Plataforma ou relativo à Política de Privacidade deverão ser submetidos ao Foro Central da Comarca de São José/SC, Brasil, que prevalecerá sobre qualquer outro, por mais privilegiado que seja, e será julgado conforme as leis da República Federativa do Brasil.</p>



  <h4>Contato:</h4>              
  <p> E-mail: contato@4bus.com.br</p>
        <p> Suporte via chat no botão Ajuda ou através do App </p>
      </Container>
    </Box>
  );
}

export default CharterTermsOfUse;
