import { db } from "../firebase";
export const INSTALLMENTS_DATA_SUCCESS = "INSTALLMENTS_DATA_SUCCESS";
export const INSTALLMENTS_DATA_ERROR = "INSTALLMENTS_DATA_ERROR";

export const getInstallmentsData = () => {
  return (dispatch) => {
    const unsubscribe = db.collection("settings").get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        dispatch(installmentsDataSuccess(data));
      })
      .catch((error) => {
        dispatch(installmentsDataError(error));
      });
      return () => {
        unsubscribe();
      }
  };
};


const installmentsDataSuccess = (data) => ({
  type: INSTALLMENTS_DATA_SUCCESS,
  data,
});

const installmentsDataError = (error) => ({
  type: INSTALLMENTS_DATA_ERROR,
  error,
});

const installmentsActions = { getInstallmentsData };

export default installmentsActions;