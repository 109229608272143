import React, { useEffect, Fragment, useState } from 'react';

import 'date-fns';
import 'react-credit-cards/es/styles-compiled.css';
import { getInstallmentsData } from '../../../actions/installmentsActions'

import * as Yup from 'yup';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  makeStyles,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AppInputMask from '../../app-input-mask/AppInputMask';
import BookingApiService from '../../../services/BookingApiService';
import Cards from 'react-credit-cards';
import CreditCard from '@material-ui/icons/CreditCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import ROUTES from '../../../constants/routes';
import UserRepository from '../../../repositories/UserRepository';
import { bindActionCreators } from 'redux';
import clsx from 'clsx';
import { connect, useSelector } from 'react-redux';
import { FormControlLabel } from '@material-ui/core';
import "./payment.css"
import { cpfIsValid } from '../../../utils/validators';
import { enqueueSnackbar } from '../../../actions';
import { setBooking } from '../../../actions/checkoutActions';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import { faCalendar, faCity, faFlag, faHome, faIdCardAlt, faPhone, faMap, faKey, faRoad, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router-dom';
import ConfirmCash from './ConfirmCash';
import STORAGE from '../../../constants/storage';
import {bookingListenerPix} from '../../../actions/bookingListenerAction'

const styles = makeStyles((theme) => ({
  root: {},
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textField: {
    flex: '0 1 calc(33% + 16px)',
    '&:not(:last-child)': {
      flex: '0 1 calc(33% - 16px)',
      marginRight: theme.spacing(2),
    },
  },
  textFieldCPF: {
    flex: '1 1 380px;',
  },
  textFieldFull: {
    flex: '0 1 100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  formControl: {
    minWidth: 170,
  },
  button: {
    marginRight: theme.spacing(2),
  },
  inputField: {
    display: 'flex',
    marginRight: 20
  },
  inputFieldRight: {
    display: 'flex',

  },
  textCardAddress: {
    color: '#0FB8D4',
    fontWeight: 'bold',
    marginTop: 30
  },
  saveCardCheck: {
    color: '#0FB8D4',
    marginBottom: 15
  },

}));
function Payment(props) {
  const { user, enqueueSnackbar, history, onIsSaving,bookingListener, getInstallments, installments, setInstallments } = props;
  const classes = styles();
  const [focusedField, setFocusedField] = React.useState('number');
  const [userCardIsLoading, setUserCardIsLoading] = React.useState(false);
  const [userCard, setUserCard] = React.useState(null);
  const [useCustomCard, setUseCustomCard] = React.useState(false);
  const [paymentMethod, setPaymentMethod] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [bookingSeats, setBookingSeats] = React.useState([]);

  const installmentsStore = useSelector(state => state.checkout.installments);

  var installmentsOptions = 0;
  var installmentsFee = 0;
  
  installmentsStore.map(options => {
    installmentsOptions = options.maxInstallments;
    installmentsFee = options.installmentsFee;
  })

  useEffect(() => {
    var unmounted = false;

    if (!unmounted) {
      let uidFireBase;
      if (clientBySeller?.uidFirebase) {
        uidFireBase = clientBySeller.uidFirebase;
      } else {
        uidFireBase = user.uidFirebase;
      }
      setUserCardIsLoading(true);
      UserRepository.getCard(uidFireBase)
        .then((cards) => {
          if (!unmounted && cards && cards.length > 0) {
            const card = cards[0];

            setUseCustomCard(false);
            setUserCard(card);
          }
        })
        .finally(() => {
          setUserCardIsLoading(false);
        });
    }

    return () => {
      unmounted = true;
    };
  }, [setUserCard, setUserCardIsLoading, user.uidFirebase]);

  useEffect(() => {
    MountSeatsToBooking();
    getInstallments();
  }, []);

  const handleFocus = (event) => {
    setFocusedField(event.target.name);
  };

  const formSchema = Yup.object().shape({
    useCustomCard: Yup.boolean(),
    number: Yup.string().when('useCustomCard', {
      is: true,
      then: (s) => s.required('Digite o Número do Cartão'),
    }),
    expiry: Yup.string().when('useCustomCard', {
      is: true,
      then: (s) => s.required('Digite a data de Vencimento do Cartão'),
    }),
    cvc: Yup.string().when('useCustomCard', {
      is: true,
      then: (s) => {
        s.required('Código de Segurança Obrigátorio')
          .min(3, 'Código de Segurança inválido')
          .max(4, 'Código de Segurança inválido');
      },
    }),
    name: Yup.string().when('useCustomCard', {
      is: true,
      then: (s) =>
        s.required('Por favor digite o Nome como está escrito no Cartão'),
    }),
    cpf: Yup.string().when('useCustomCard', {
      is: true,
      then: (s) =>
        s
          .required('Digite o CPF do titular do Cartão')
          .test('CPF', 'CPF Inválido', (cpf) => cpfIsValid(cpf, true)),
    }),
    phone: Yup.string().when('useCustomCard', {
      is: true,
      then: (s) =>
        s.required('Digite seu telefone.'),
    }),
    cep: Yup.string().when('useCustomCard', {
      is: true,
      then: (s) =>
        s.required('Digite seu CEP.'),
    }),

    neighborhood: Yup.string().when('useCustomCard', {
      is: true,
      then: (s) =>
        s.required('Digite o nome do bairro.'),
    }),

    street: Yup.string().when('useCustomCard', {
      is: true,
      then: (s) =>
        s.required('Digite o nome da rua.'),
    }),

    addressNumber: Yup.string().when('useCustomCard', {
      is: true,
      then: (s) =>
        s.required('Digite o número da rua.'),
    }),

    city: Yup.string().when('useCustomCard', {
      is: true,
      then: (s) =>
        s.required('Digite sua cidade.'),
    }),

    state: Yup.string().when('useCustomCard', {
      is: true,
      then: (s) =>
        s.required('Digite seu estado.'),
    }),

  });
  const departTrip = JSON.parse(sessionStorage.getItem('departTrip'));
  const returnTrip = JSON.parse(sessionStorage.getItem('returnTrip'));
  const seatsGoing = JSON.parse(sessionStorage.getItem(STORAGE.SEATS_GOING));
  const seatsGoingConnection = JSON.parse(
    sessionStorage.getItem(STORAGE.SEATS_GOING_CONNECTION)
  );
  const seatsReturn = JSON.parse(sessionStorage.getItem(STORAGE.SEATS_RETURN));
  const seatsReturnConnection = JSON.parse(
    sessionStorage.getItem(STORAGE.SEATS_RETURN_CONNECTION)
  );

  const clientBySeller = JSON.parse(
    sessionStorage.getItem('clientSelectedBySeller')
  );
  const [confirmCashDialog, setConfirmCashDialog] = useState(false);
  const coupon = JSON.parse(sessionStorage.getItem('COUPON') || '{}');
  const useCredits =
    sessionStorage.getItem('USE_CREDITS') === 'true' ? true : false;
  const selectedPassengers = JSON.parse(
    sessionStorage.getItem('selectedPassengers') || '[]'
  );

  const MountSeatsToBooking = () => {
    const seats = [];
    seatsGoing.passengersSeats.map((seat) => {
      seats.push({
        passengerId: seat.passengerId,
        number: seat.seatNumber,
        decker: seat.decker,
        tripId: seatsGoing.tripId,
      });
    });

    if (seatsGoingConnection) {
      seatsGoingConnection.passengersSeats.map((seat) => {
        seats.push({
          passengerId: seat.passengerId,
          number: seat.seatNumber,
          decker: seat.decker,
          tripId: seatsGoingConnection.tripId,
        });
      });
    }

    if (returnTrip) {
      seatsReturn.passengersSeats.map((seat) => {
        seats.push({
          passengerId: seat.passengerId,
          number: seat.seatNumber,
          decker: seat.decker,
          tripId: seatsReturn ? seatsReturn.tripId : null,
        });
      });
      if (seatsReturnConnection) {
        seatsReturnConnection.passengersSeats.map((seat) => {
          seats.push({
            passengerId: seat.passengerId,
            number: seat.seatNumber,
            decker: seat.decker,
            tripId: seatsReturnConnection ? seatsReturnConnection.tripId : null,
          });
        });
      }
    }
    setBookingSeats(seats);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    switch(paymentMethod){
      case 'pix':
        
        pixPayment({ setSubmitting })
      break;

      case 'card':
        cardPayment(values, { setSubmitting })
      break;
    }
  };

  const createPaymentCash = async () => {
    setConfirmCashDialog(false);
    let userIdCustomer = clientBySeller.id;
    setLoading(true);
    try {
      const { data, status } = await BookingApiService.postBookingMoney(
        departTrip.id,
        returnTrip && returnTrip.id,
        selectedPassengers,
        //Parametros Coupon e useCredits estão indo pelo sessionStorage, motivo: ambos os campos iam com valor diferente em casos aleatórios
        userIdCustomer
      );
      if (status === 200) {
        // enqueueSnackbar({
        //   message: 'Pagamento realizado com sucesso!',
        //   options: {
        //     key: 'paymnentFormSuccessSnack',
        //     autoHideDuration: 2000,
        //     variant: 'success',
        //     anchorOrigin: {
        //       vertical: 'top',
        //       horizontal: 'center',
        //     },
        //   },
        // });

        setLoading(false);
        onIsSaving && onIsSaving(false);
        sessionStorage.setItem('checkoutBookingResult', JSON.stringify(data));
        history.push(ROUTES.CHECKOUT_SELECT_SEATS);
      }
    } catch (error) {

      const { data } = error;
      var errorMessage =
        'Não foi possivel efetuar o pagamento, se o problema persistir contate o administrador do sistema';

      if (error) {
        if (error.status === 406) {
          const errorCode = data.error.errorCode;
          if (errorCode === 1002 || errorCode === 1007) {
            errorMessage = data.error.error;
          }
        }
      }

      enqueueSnackbar({
        message: errorMessage,
        options: {
          key: 'paymnentFormErrorSnack',
          autoHideDuration: 3000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const cardPayment = async (values, { setSubmitting }) => {

    var submitValues = { ...values, installments };
    try {
      const mp = new window.MercadoPago(process.env.REACT_APP_MP_PUBLIC_KEY);

      if (!userCard) {
        if (
          !values.cpf ||
          !values.cvc ||
          !values.expiry ||
          !values.name ||
          !values.number ||
          !values.street ||
          !values.neighborhood ||
          !values.city ||
          !values.state ||
          !values.addressNumber ||
          !values.cep
        ) {
          enqueueSnackbar({
            message: 'Preencha todos os dados do cartão para finalizar o pedido.',
            options: {
              key: 'paymnentFormErrorSnack',
              autoHideDuration: 5000,
              variant: 'warning',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            },
          });

          return;
        }
      } else {
        submitValues.phone = submitValues.phone !== '' ? submitValues.phone : userCard.phone
        submitValues.cpf = submitValues.cpf !== '' ? submitValues.cpf : userCard.cpf
        submitValues.billingAddress = submitValues.number !== '' ? null : userCard.billingAddress
        submitValues.cardPaymentId = submitValues.number !== '' ? null : userCard.id
      }

      let userIdCustomer = null;
      if (clientBySeller?.id) {
        userIdCustomer = clientBySeller.id;
      }
      let token = userCard ? userCard.idMercadoPago : null
      if (values.useCustomCard) {
        let cardToken = await mp.createCardToken({
          cardNumber: submitValues.number.replace(/\s/g, ''),
          cardholderName: submitValues.name,
          cardExpirationMonth: submitValues.expiry.substring(0, 2),
          cardExpirationYear: submitValues.expiry.substring(5, 7),
          securityCode: submitValues.cvc.replace(/\s/g, ''),
          identificationType: "CPF",
          identificationNumber: submitValues.cpf.replace(/\./g, '').replace(/\-/g, ''),
        });
        token = cardToken.id
      }
      if (!token) {
        enqueueSnackbar({
          message: 'Não foi possivel fazer o pagamento, insira novamente os dados do cartão.',
          options: {
            key: 'paymnentFormErrorSnack',
            autoHideDuration: 3000,
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          },
        });
        return
      }
      submitValues.token = token
      onIsSaving && onIsSaving(true);
      try {
        const res = await BookingApiService.postBookingV3(
          departTrip ? departTrip.id : null,
          returnTrip ? returnTrip.id : null,
          bookingSeats,
          selectedPassengers,
          submitValues,
          !useCustomCard ? userCard.iuguPaymentMethodId : null,
          //Parametros Coupon e useCredits estão indo pelo sessionStorage, motivo: ambos os campos iam com valor diferente em casos aleatórios
          userIdCustomer
        )
        if (res.status === 200) {
          // enqueueSnackbar({
          //   message: 'Pagamento realizado com sucesso!',
          //   options: {
          //     key: 'paymnentFormSuccessSnack',
          //     autoHideDuration: 2000,
          //     variant: 'success',
          //     anchorOrigin: {
          //       vertical: 'top',
          //       horizontal: 'center',
          //     },
          //   },
          // });

          setSubmitting(false);
          onIsSaving && onIsSaving(false);

          sessionStorage.setItem(
            'checkoutBookingResult',
            JSON.stringify(res.data)
          );
          history.push(ROUTES.CHECKOUT_SUMMARY);
        }
      } catch (err) {
        var errorMessage =
          'Não foi possivel efetuar o pagamento, se o problema persistir contate o administrador do sistema';
        const res = err
        if (res) {
          if (res.status === 406) {
            errorMessage = res.data.error.error;
          }
        }

        enqueueSnackbar({
          message: errorMessage,
          options: {
            key: 'paymnentFormErrorSnack',
            autoHideDuration: 3000,
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          },
        });
        setSubmitting(false);
        onIsSaving && onIsSaving(false);
      }


    } catch (err) {
      enqueueSnackbar({
        message: 'Não foi possível iniciar a conexão com o Mercado pago, tente novamente mais tarde.',
        options: {
          key: 'paymnentFormErrorSnack',
          autoHideDuration: 3000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        },
      });
    }
  }



  const pixPayment = async ({ setSubmitting }) => {
    try {
      let userIdCustomer = null;
      if (clientBySeller?.id) {
        userIdCustomer = clientBySeller.id;
      }
      onIsSaving && onIsSaving(true);
      try {
        const res = await BookingApiService.postBookingV3PixPayment(
          departTrip ? departTrip.id : null,
          returnTrip ? returnTrip.id : null,
          bookingSeats,
          selectedPassengers,
          {},
          !useCustomCard ? userCard && userCard.iuguPaymentMethodId : null,
          userIdCustomer
        )

        if (res.status === 200) {

          setSubmitting(false);
          onIsSaving && onIsSaving(false);

          sessionStorage.setItem(
            'checkoutBookingResult',
            JSON.stringify(res.data)
          );

          history.push(ROUTES.CHECKOUT_SUMMARY);
          if(res.data.booking && res.data.booking.departBookingSegment)
            bookingListener(res.data.booking.departBookingSegment.bookingCode);
        }
      } catch (err) {
        var errorMessage =
          'Não foi possivel efetuar o pagamento, se o problema persistir contate o administrador do sistema';
        const res = err
        if (res) {
          if (res.status === 406) {
            errorMessage = res.data.error.error;
          }
        }

        enqueueSnackbar({
          message: errorMessage,
          options: {
            key: 'paymnentFormErrorSnack',
            autoHideDuration: 3000,
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          },
        });
        setSubmitting(false);
        onIsSaving && onIsSaving(false);
      }

    } catch (err) {
      enqueueSnackbar({
        message: 'Não foi possível iniciar a conexão com o Mercado pago, tente novamente mais tarde.',
        options: {
          key: 'paymnentFormErrorSnack',
          autoHideDuration: 3000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        },
      });
    }
  };

  return (
    <Fragment>
      <Formik
        initialValues={{
          useCustomCard: useCustomCard,
          number: '',
          expiry: '',
          cvc: '',
          name: '',
          cpf: (user && user.cpf) || '',
          street: '',
          cep: '',
          neighborhood: '',
          addressNumber: '',
          city: '',
          state: '',
          saveCard: false,
          phone: ''
        }}
        validationSchema={formSchema}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          isValid,
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => {

          return (
            <LoadingOverlay
              spinner
              text="Carregando..."
              active={userCardIsLoading}
            >
              <form
                id="form-checkout"
                onSubmit={handleSubmit}
                className={clsx(classes.form)}
              >
                <Box component={Paper} width="100%">
                  <Box
                    padding={2}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Typography variant="h4">Pagamento</Typography>
                  </Box>

                  <div className="payment-type-options">
                    <Button
                      onClick={() =>{ 
                        setUseCustomCard(true)
                        setPaymentMethod("card")
                      }}
                      variant={
                        paymentMethod === "card" ? "contained" : "outlined"
                      }
                      color="primary"
                    >
                      Cartão de Crédito
                      <CreditCardIcon />
                    </Button>
                    {user.type === 5 && (
                      <Button
                        onClick={() => setPaymentMethod("money")}
                        variant={
                          paymentMethod === "money" ? "contained" : "outlined"
                        }
                        color="primary"
                      >
                        Dinheiro
                        <AttachMoneyIcon />
                      </Button>
                    )}
                    <Button
                      onClick={() => setPaymentMethod("pix")}
                      variant={
                        paymentMethod === "pix" ? "contained" : "outlined"
                      }
                      color="primary"
                    >
                      Pix
                      <AccountBalanceIcon />
                    </Button>
                  </div>

                  {paymentMethod === "pix" && (
                    <>
                      <Box padding={2} width="100%">
                        <Grid container spacing={1} direction="column">
                          <br />
                          <Typography color="primary">
                            Regras para o cancelamento/estorno
                          </Typography>

                          <Typography>
                            • Prazo máximo: 3h do embarque, multa de 5%
                          </Typography>
                          <Typography>
                            • Não é possivel cancelar a reserva de apenas um
                            passageiro
                          </Typography>
                          <br />

                          <Typography>
                            Ao clicar em 'Pagar reserva' você concorda com as
                            regras acima.
                          </Typography>
                        </Grid>

                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="flex-end"
                          className={classes.root}
                          margin={2}
                        >
                          <Button
                            className={classes.button}
                            component={Link}
                            to={ROUTES.CHECKOUT}
                          >
                            Voltar
                          </Button>
                          <Button
                            variant="outlined"
                            color="primary"
                            type='submit'
                          >
                            Efetuar Pagamento
                          </Button>
                        </Box>
                      </Box>
                    </>
                  )}

                  {paymentMethod === "card" && (
                    <Fragment>
                      {!userCardIsLoading &&
                        (useCustomCard ||
                          !Boolean(userCard && userCard.cardData)) && (
                          <Box
                            padding={2}
                            display="flex"
                            flex="1 1 100%"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            {}

                            <Box paddingBottom={4}>
                              <Cards
                                number={values.number}
                                name={values.name}
                                expiry={values.expiry}
                                cvc={values.cvc}
                                focused={focusedField}
                                preview={false}
                                locale={{ valid: "válido até" }}
                                placeholders={{ name: "Seu Nome Aqui" }}
                              />
                            </Box>

                            <Box
                              display="flex"
                              flexDirection="column"
                              width="100%"
                            >
                              <AppInputMask
                                mask="9999 9999 9999 9999"
                                name="number"
                                className={clsx(
                                  classes.margin,
                                  classes.textFieldFull
                                )}
                                margin="dense"
                                label="Número do Cartão"
                                value={values.number}
                                onChange={(event) => {
                                  handleChange(event);
                                  handleFocus(event);
                                }}
                                onBlur={handleBlur}
                                InputProps={{
                                  autoFocus: true,
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <CreditCard />
                                    </InputAdornment>
                                  ),
                                }}
                                error={errors.number && touched.number}
                                helperText={
                                  errors.number && touched.number
                                    ? errors.number
                                    : null
                                }
                              />
                              <Box
                                display="flex"
                                flexDirection="row"
                                width="100%"
                              >
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  style={{ display: "flex" }}
                                >
                                  <Grid item xs={12} sm={12} md={6}>
                                    <AppInputMask
                                      style={{
                                        display: "flex",
                                        marginRight: 20,
                                      }}
                                      mask="9999"
                                      name="cvc"
                                      className={clsx(classes.inputField)}
                                      margin="dense"
                                      label="CVC"
                                      value={values.cvc}
                                      onChange={(event) => {
                                        handleChange(event);
                                        handleFocus(event);
                                      }}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <FontAwesomeIcon icon={faKey} />
                                          </InputAdornment>
                                        ),
                                      }}
                                      onBlur={handleBlur}
                                      error={errors.cvc && touched.cvc}
                                      helperText={
                                        errors.cvc && touched.cvc
                                          ? errors.cvc
                                          : null
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={6}>
                                    <AppInputMask
                                      mask={"99/9999"}
                                      name="expiry"
                                      className={clsx(classes.inputFieldRight)}
                                      margin="dense"
                                      maskChar="_"
                                      placeholder="10/2029"
                                      label="Válido até"
                                      value={values.expiry}
                                      style={{ marginRight: 56 }}
                                      onChange={(event) => {
                                        handleChange(event);
                                        handleFocus(event);
                                      }}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <FontAwesomeIcon
                                              icon={faCalendar}
                                            />
                                          </InputAdornment>
                                        ),
                                      }}
                                      onBlur={handleBlur}
                                      error={errors.expiry && touched.expiry}
                                      helperText={
                                        errors.expiry && touched.expiry
                                          ? errors.expiry
                                          : null
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </Box>

                              <Box
                                display="flex"
                                flexDirection="row"
                                width="100%"
                              >
                                <TextField
                                  fullWidth
                                  name="name"
                                  className={clsx(
                                    classes.margin,
                                    classes.textFieldFull
                                  )}
                                  margin="dense"
                                  variant="standard"
                                  type="text"
                                  label="Nome"
                                  value={values.name}
                                  onChange={(event) => {
                                    handleChange(event);
                                  }}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <AccountCircle />
                                      </InputAdornment>
                                    ),
                                  }}
                                  error={errors.name && touched.name}
                                  helperText={
                                    errors.name && touched.name
                                      ? errors.name
                                      : null
                                  }
                                />
                              </Box>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                style={{ display: "flex" }}
                              >
                                <Grid item xs={12} sm={12} md={6}>
                                  <AppInputMask
                                    mask="999.999.999-99"
                                    value={values.cpf}
                                    onChange={(event) => {
                                      handleChange(event);
                                    }}
                                    onBlur={handleBlur}
                                    name="cpf"
                                    label="CPF"
                                    className={clsx(classes.inputField)}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <FontAwesomeIcon icon={faIdCardAlt} />
                                        </InputAdornment>
                                      ),
                                    }}
                                    error={errors.cpf && touched.cpf}
                                    helperText={
                                      errors.cpf && touched.cpf
                                        ? errors.cpf
                                        : null
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                  <AppInputMask
                                    mask="(99)99999-9999"
                                    value={values.phone}
                                    onChange={(event) => {
                                      handleChange(event);
                                    }}
                                    onBlur={handleBlur}
                                    name="phone"
                                    label="Telefone"
                                    className={clsx(classes.inputFieldRight)}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <FontAwesomeIcon icon={faPhone} />
                                        </InputAdornment>
                                      ),
                                    }}
                                    error={errors.phone && touched.phone}
                                    helperText={
                                      errors.phone && touched.phone
                                        ? errors.phone
                                        : null
                                    }
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                style={{ display: "flex" }}
                              >
                                <Typography
                                  className={clsx(classes.textCardAddress)}
                                >
                                  Endereço de cobrança do cartão
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                style={{ display: "flex" }}
                              >
                                <Grid item xs={12} sm={12} md={12}>
                                  <AppInputMask
                                    mask="99999-999"
                                    value={values.cep}
                                    onChange={(event) => {
                                      handleChange(event);
                                    }}
                                    onBlur={handleBlur}
                                    name="cep"
                                    label="CEP"
                                    className={clsx(
                                      classes.margin,
                                      classes.textFieldFull
                                    )}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <FontAwesomeIcon icon={faMap} />
                                        </InputAdornment>
                                      ),
                                    }}
                                    error={errors.cep && touched.cep}
                                    helperText={
                                      errors.cep && touched.cep
                                        ? errors.cep
                                        : null
                                    }
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                style={{ display: "flex" }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={10}
                                  className={clsx(classes.inputField)}
                                >
                                  <TextField
                                    fullWidth
                                    name="street"
                                    margin="dense"
                                    variant="standard"
                                    type="text"
                                    label="Rua"
                                    value={values.street}
                                    onChange={(event) => {
                                      handleChange(event);
                                      handleFocus(event);
                                    }}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <FontAwesomeIcon icon={faRoad} />
                                        </InputAdornment>
                                      ),
                                    }}
                                    error={errors.street && touched.street}
                                    helperText={
                                      errors.street && touched.street
                                        ? errors.street
                                        : null
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                  <TextField
                                    fullWidth
                                    name="addressNumber"
                                    className={clsx(classes.inputFieldRight)}
                                    margin="dense"
                                    variant="standard"
                                    type="text"
                                    label="Número"
                                    value={values.addressNumber}
                                    onChange={(event) => {
                                      handleChange(event);
                                      handleFocus(event);
                                    }}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <FontAwesomeIcon icon={faHome} />
                                        </InputAdornment>
                                      ),
                                    }}
                                    error={
                                      errors.addressNumber &&
                                      touched.addressNumber
                                    }
                                    helperText={
                                      errors.addressNumber &&
                                      touched.addressNumber
                                        ? errors.addressNumber
                                        : null
                                    }
                                  />
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                style={{ display: "flex" }}
                              >
                                <TextField
                                  fullWidth
                                  name="neighborhood"
                                  margin="dense"
                                  variant="standard"
                                  type="text"
                                  label="Bairro"
                                  value={values.neighborhood}
                                  onChange={(event) => {
                                    handleChange(event);
                                    handleFocus(event);
                                  }}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <FontAwesomeIcon icon={faBuilding} />
                                      </InputAdornment>
                                    ),
                                  }}
                                  error={
                                    errors.neighborhood && touched.neighborhood
                                  }
                                  helperText={
                                    errors.neighborhood && touched.neighborhood
                                      ? errors.neighborhood
                                      : null
                                  }
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                style={{ display: "flex" }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  className={clsx(classes.inputField)}
                                >
                                  <TextField
                                    fullWidth
                                    name="city"
                                    margin="dense"
                                    variant="standard"
                                    type="text"
                                    label="Cidade"
                                    value={values.city}
                                    onChange={(event) => {
                                      handleChange(event);
                                      handleFocus(event);
                                    }}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <FontAwesomeIcon icon={faCity} />
                                        </InputAdornment>
                                      ),
                                    }}
                                    error={errors.city && touched.city}
                                    helperText={
                                      errors.city && touched.city
                                        ? errors.city
                                        : null
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                  <TextField
                                    fullWidth
                                    name="state"
                                    className={clsx(classes.inputFieldRight)}
                                    margin="dense"
                                    variant="standard"
                                    type="text"
                                    label="Estado"
                                    value={values.state}
                                    onChange={(event) => {
                                      handleChange(event);
                                      handleFocus(event);
                                    }}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <FontAwesomeIcon icon={faFlag} />
                                        </InputAdornment>
                                      ),
                                    }}
                                    error={errors.state && touched.state}
                                    helperText={
                                      errors.state && touched.state
                                        ? errors.state
                                        : null
                                    }
                                  />
                                </Grid>
                              </Grid>
                              <FormControlLabel
                                className={classes.saveCardCheck}
                                control={
                                  <Checkbox
                                    checked={values.saveCard}
                                    className={classes.saveCardCheckLabel}
                                    onChange={handleChange}
                                    name="saveCard"
                                    color="primary"
                                  />
                                }
                                label="Deseja salvar cartão para futuras compras?"
                              />
                              <Grid
                                className={classes.grid}
                                item
                                xs={12}
                                sm={12}
                              >
                                <FormControl
                                  // className={clsx(
                                  //   classes.margin,
                                  //   classes.formControl,
                                  //   classes.textFieldAuto
                                  // )}
                                  fullWidth
                                >
                                  <InputLabel htmlFor="installments">
                                    Parcelas
                                  </InputLabel>
                                  <Select
                                    style={{
                                      marginBottom: 15,
                                    }}
                                    value={installments}
                                    onChange={(event) => {
                                      setInstallments(event.target.value);
                                    }}
                                    onBlur={handleBlur}
                                    margin="dense"
                                    inputProps={{
                                      name: "installments",
                                      id: "installments",
                                    }}
                                  >
                                    {Array.from({length: installmentsOptions}, (_, i) => i+1).map((num) => {
                                      const isMobile = window.innerWidth < 768;
                                      const label = isMobile && num > 1 ? `${num} com juros` : `${num}`;
                                      return (
                                        <MenuItem key={num} value={num}>
                                          {label}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Button
                                variant="outlined"
                                color="primary"
                                type="button"
                                onClick={() => {
                                  setUseCustomCard(false);
                                  setFieldValue("useCustomCard", false);
                                }}
                              >
                                Usar cartão salvo
                              </Button>
                            </Box>
                          </Box>
                        )}

                      {!userCardIsLoading &&
                        !useCustomCard &&
                        Boolean(userCard && userCard.cardData) && (
                          <Box padding={2} width="100%">
                            <Grid container spacing={1} direction="column">
                              <Grid item>
                                <TextField
                                  fullWidth
                                  name="userCard"
                                  className={clsx(
                                    classes.margin,
                                    classes.textFieldFull
                                  )}
                                  margin="dense"
                                  variant="standard"
                                  type="text"
                                  label="Número do Cartão"
                                  disabled
                                  value={userCard.cardData.display_number}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <CreditCardIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                              <Grid
                                className={classes.grid}
                                item
                                xs={12}
                                sm={12}
                              >
                                <FormControl
                                  // className={clsx(
                                  //   classes.margin,
                                  //   classes.formControl,
                                  //   classes.textFieldAuto
                                  // )}
                                  fullWidth
                                >
                                  <InputLabel htmlFor="installments">
                                    Parcelas
                                  </InputLabel>
                                  <Select
                                    style={{
                                      marginBottom: 15,
                                    }}
                                    value={installments}
                                    onChange={(event) => {
                                      setInstallments(event.target.value);
                                    }}
                                    onBlur={handleBlur}
                                    margin="dense"
                                    inputProps={{
                                      name: "installments",
                                      id: "installments",
                                    }}
                                  >
                                    {Array.from({length: installmentsOptions}, (_, i) => i+1).map((num) => {
                                      const isMobile = window.innerWidth < 768;
                                      const label = isMobile && num > 1 ? `${num} com juros` : `${num}`;
                                      return (
                                        <MenuItem key={num} value={num}>
                                          {label}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <br />
                              <Button
                                variant="outlined"
                                color="primary"
                                type="button"
                                onClick={() => {
                                  setUseCustomCard(true);
                                  setFieldValue("useCustomCard", true);
                                }}
                              >
                                Alterar cartão
                              </Button>
                              <Grid item>
                                <br />
                                <Typography color="primary">
                                  Regras para o cancelamento/estorno
                                </Typography>

                                <Typography>
                                  • Prazo máximo: 3h antes do embarque (com multa de 5%).
                                </Typography>
                                <Typography>
                                  • Não é possivel cancelar a reserva de apenas
                                  um passageiro
                                </Typography>
                                <Typography>
                                  • Parcelamento com juros de {`${installmentsFee} %`} ao mês.
                                </Typography>
                                <br />

                                <Typography>
                                  Ao clicar em 'Pagar reserva' você concorda com
                                  as regras acima.
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        )}

                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        className={classes.root}
                        margin={2}
                      >
                        <Button
                          // onClick={handleBack}
                          className={classes.button}
                          component={Link}
                          to={ROUTES.CHECKOUT}
                        >
                          Voltar
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          type="submit"
                          id="form-checkout__submit"
                          disabled={useCustomCard && !isValid}
                        >
                          Efetuar Pagamento
                        </Button>
                      </Box>
                    </Fragment>
                  )}
                  {paymentMethod === "money" && (
                    <Fragment>
                      <Typography align="center" variant="subtitle1">
                        {" "}
                        Pagamento em dinheiro.
                      </Typography>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        className={classes.root}
                        margin={2}
                      >
                        <Button
                          // onClick={handleBack}
                          className={classes.button}
                          component={Link}
                          to={ROUTES.CHECKOUT}
                        >
                          Voltar
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          //type="submit"
                          disabled={loading}
                          onClick={() => setConfirmCashDialog(true)}
                        >
                          {loading ? (
                            <CircularProgress size={20} />
                          ) : (
                            "Efetuar Pagamento"
                          )}
                        </Button>
                      </Box>
                    </Fragment>
                  )}
                </Box>
              </form>
            </LoadingOverlay>
          );
        }}
      </Formik>
      <ConfirmCash
        title='Confirme o pedido em dinheiro'
        open={confirmCashDialog}
        setOpen={setConfirmCashDialog}
      >
        <Typography variant='subtitle1'>
          Deseja confirmar o pedido em dinheiro ?
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => setConfirmCashDialog(false)}
          >
            Cancelar
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={() => createPaymentCash()}
          >
            Confirmar
          </Button>
        </div>
      </ConfirmCash>
    </Fragment >
  );
}

const mapStateToProps = (store) => ({
  user: store.auth.user,
  checkout: store.checkout.installments,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      enqueueSnackbar: enqueueSnackbar,
      setBooking: setBooking,
      bookingListener: bookingListenerPix,
      getInstallments: getInstallmentsData
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Payment));
