import React, {useEffect} from 'react';

import { withRouter } from 'react-router-dom';
import SignIn from "../components/sign-in/SignIn"

import {authSignInEmailPassword, enqueueSnackbar, removeSnackbar} from './../actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ROUTES from '../constants/routes';

const Login = (props) => {
  const {
    signInEmailPassword,
    isLoading,
    isAuthModalOpen,
    error,
    enqueueSnackbar,
    user,
    history
  } = props;

  useEffect(() => {
    if(!user) {
      history.push(ROUTES.SIGNIN);
    }
  }, [history, user]);

  return (
    <SignIn isAuthModalOpen={isAuthModalOpen} />
  );
}

const mapStateToProps = store => ({
  user: store.auth.user,
  isLoading: store.auth.isLoading,
  error: store.auth.error,
  isAuthModalOpen: store.auth.isModalOpen
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signInEmailPassword: authSignInEmailPassword,
      enqueueSnackbar: enqueueSnackbar,
      removeSnackbar: removeSnackbar
    },
    dispatch
  );

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Login));