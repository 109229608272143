/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from 'react';
import {
  withStyles,
  FormControlLabel,
  Switch,
  Button,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { db } from '../../firebase';

import TripApiService from '../../services/TripApiService';
import { generateRows } from './generateSeats';
import frontBus from './bus-front.png';
import Loader from '../shared/loader/Loader';
// import SeatPicker from 'react-seat-picker'

// import './styles.css'
const styles = (theme) => ({
  seats: {
    width: 40,
    height: 40,
    fontSize: 14,
    cursor: 'pointer',
    border: '1px solid #666',
    borderBottom: 'solid 5px',
    color: '#666',
    borderRadius: '5px 5px 0 0 ',
    textAlign: 'center',
    marginRight: 10,
    marginBottom: 10,
    // '&:last-child': {
    //   display: 'none'
    // }
  },
  spanSeat: {
    display: 'inline-block',
    margin: '5px 0',
  },
  seatsSelected: {
    color: '#fff',
    background: '#0FB8D4',
    border: '1px solid #ccc',
    borderBottom: '5px solid #ccc',
  },
  seatBusy: {
    color: '#ccc',
    cursor: 'default',
    borderColor: '#ccc',
  },
  freeSeat: {
    width: 40,
    height: 40,
    border: 'none',
  },
  hallSeats: {
    width: 40,
    height: 40,
    border: 'none',
  },
});

const SeatsMapNew = ({
  tripDetails,
  classes,
  busTypeId,
  busySeats,
  busySeatsSecondDecker,
  showSecondDecker,
  setShowSecondDecker,
  selectSeat,
  selectedSeats,
  numStartDriverSide,
  driverSideColumnSeat,
  driverSideSeats,
  passengerSideColumnSeat,
  passengerSideSeats,
  selectSeatSecondDecker,
  onDoubleDeckerBus,
  selectedSeatsSecondDecker,
  passengerSideColumnSeatSecondDecker,
  driverSideSeatsSecondDecker,
  passengerSideSeatsSecondDecker,
  driverSideColumnSeatSecondDecker,
  numStartDriverSideSecondDecker,
  hasReturnTrip,
  secondDecker,
  firstDecker,
  showTypes,
  unselectSeat,
  changeSeatsPassengers,
  passengers,
}) => {
  // const bookingSegments = [];
  // bookingSegments.push(JSON.parse(sessionStorage.getItem('departTrip')));
  // bookingSegments.push(JSON.parse(sessionStorage.getItem('returnTrip')));

  const [trip, setTrip] = useState(null);
  const [decker, setDecker] = useState(1);
  const [seats, setSeats] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rowsSeats, setRowsSeats] = useState([]);
  // const { tripId } = bookingSegments[hasReturnTrip ? 1 : 0];
  const [noSeats, setNoSeats] = useState(false);

  useEffect(() => {
    setLoading(true);
    getBusSeats();
    setLoading(false);
  }, [firstDecker, secondDecker, decker]);
  // useEffect(() => {
  //   if (!secondDecker) {
  //     setDecker(1);
  //   } else {
  //     setDecker(2);
  //   }
  // }, [secondDecker]);

  // useEffect(() => {
  //   if(selectedSeats1){
  //     setSeats(selectedSeats1)
  //   }
  // },[selectedSeats1])

  // const getBusSeats = async () => {
  //   try {
  //     const { data, status } = await TripApiService.getBusSeats(tripId, decker);
  //     console.log('getBusSeats', status);
  //     if (status === 204) {
  //       setNoSeats(true);
  //     }
  //     setSeats(generateRows(data));
  //   } catch (error) {
  //     console.log('getBusSeats', error);
  //   }
  // };

  const getBusSeats = () => {
    if (!firstDecker && !secondDecker) {
      setNoSeats(true);
    } else {
      if (decker === 1 && firstDecker) {
        setSeats(generateRows(firstDecker.busMapSeats));
      } else if (decker === 2 && secondDecker) {
        setSeats(generateRows(secondDecker.busMapSeats));
      }
    }
  };

  return (
    <Grid
      container
      justify='center'
      style={{
        marginTop: 20,
        // height: '120%',
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        style={{
          background: '#f5f5f5',
        }}
      >
        <Grid container justify='space-between' style={{ padding: 10 }}>
          {showTypes && (
            <Fragment>
              <Grid item xs={3}>
                <div className={classes.seats} />
                <Typography> Disponivel</Typography>
              </Grid>
              <Grid item xs={3}>
                <div className={`${classes.seats} ${classes.seatsSelected}`} />
                <Typography> Escolhido </Typography>
              </Grid>
              <Grid item xs={3}>
                <div className={`${classes.seats} ${classes.seatBusy}`} />
                <Typography> Ocupado</Typography>
              </Grid>
            </Fragment>
          )}
        </Grid>
      </Grid>
      {secondDecker && (
        <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
          <Grid container justify='center'>
            <Grid item xs={3} style={{ textAlign: 'center' }}>
              <Button
                color='primary'
                variant={decker === 1 ? 'contained' : 'outlined'}
                onClick={(e) => decker !== 1 && setDecker(1)}
              >
                1 Andar
              </Button>
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'center' }}>
              <Button
                color='primary'
                variant={decker === 2 ? 'contained' : 'outlined'}
                onClick={(e) => decker !== 2 && setDecker(2)}
              >
                2 Andar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid xs={12} />
      <Grid>
        <Grid
          item
          xs={6}
          sm={7}
          style={{
            maxWidth: 270,
            height: '100vh',
            margin: '-12px auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            src={frontBus}
            alt='Frente Onibus'
            style={{ width: 380, paddingTop: 10 }}
          />
          <table
            cellspacing='10'
            style={{
              width: 313,

              // zIndex: 9999,
            }}
          >
            {seats &&
              seats.rows.map((row) => (
                <tr style={{ paddingBottom: 20 }}>
                  {row.map((e, index) => {
                    if (!e) return <></>
                    if (e.type === 'HALL') {
                      return (
                        <td className={`${classes.hallSeats}`}>
                          {e.identifier}
                        </td>
                      );
                    }
                    if (e.type === 'SEAT') {
                      return (
                        <td
                          className={
                            `${classes.seats} ${e.busy && classes.seatBusy} 
                            ${passengers.find(
                              (p) => p.seatNumber === e.number
                            ) && classes.seatsSelected
                            }`
                            // ${showRuleSecond() &&
                            //   busySeatsSecondDecker.find(e => e === stPa1) &&
                            //   this.props.classes.seatBusy}
                            // ${showRuleFirst() &&
                            //   busySeats.find(e => e === stPa1) &&
                            //   this.props.classes.seatBusy}
                            //   ${showRuleSecond() &&
                            //     selectedSeatsSecondDecker.find(e => e === stPa1) &&
                            //     this.props.classes.seatsSelected}
                            //   ${showRuleFirst() &&
                            //     selectedSeats.find(e => e === stPa1) &&
                            //     this.props.classes.seatsSelected}
                          }
                          onClick={(event) => {
                            console.log('oi');
                            console.log('assentos -->', e);
                            if (e.number === 0) {
                              return;
                            }
                            if (!e.busy) {
                              if (
                                !passengers.find(
                                  (p) => p.seatNumber === e.number
                                )
                              ) {
                                changeSeatsPassengers(e.number, e.decker);
                              } else {
                                unselectSeat(e.number, e.decker);
                              }
                            }
                          }}
                        >
                          {e.identifier}
                        </td>
                      );
                    }
                    if (e.type === 'FREE') {
                      return (
                        <td className={`${classes.freeSeat}`}>
                          {e.identifier}
                        </td>
                      );
                    }
                  })}
                </tr>
              ))}
            {!seats && (
              <div>
                {/* <h3>Carregando assentos...</h3> */}
                <br />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress />
                </div>
              </div>
            )}
          </table>
        </Grid>
      </Grid>
    </Grid>
  );
};

// class SeatsMapNew extends Component {
//   state = {
//     loading: false,
//     trip: null
//   };
//   async componentDidMount() {
//     console.log('props', this.props);
//     const { busTypeId } = this.props;
//     const res = JSON.parse(sessionStorage.getItem('checkoutBookingResult'));

//     const { tripId } = res.booking.bookingSegments[0];

//     await db
//       .collection('trip')
//       .doc(`${tripId}`)
//       .get()
//       .then(doc => {
//         const trip = doc.data();
//         this.setState({ trip });
//       });
//     TripApiService.getBusSeats(this.state.trip.id, 1).then(e =>
//       console.log('PLACES', e.data)
//     );
//   }

//   generateSeats2 = (
//     driverSideColumnSeat,
//     passengerSideColumnSeat,
//     driverSideSeats,
//     passengerSideSeats,
//     numStartDriverSide,
//     busySeats,
//     selectSeat,
//     selectedSeats,
//     selectedSeatsSecondDecker = [],
//     busySeatsSecondDecker = [],
//     // selectSeatSecondDecker,
//     showSecondDecker
//   ) => {
//     const quantL = parseInt(driverSideSeats); // Quantidade de poltronas do lado esquerdo
//     const quantR = parseInt(passengerSideSeats); // Quantidade de poltronas do lado direito
//     // driverSideColumnSeat
//     const numAl = parseInt(driverSideColumnSeat); // Número de fileiras do lado esquerdo
//     const numAr = parseInt(passengerSideColumnSeat); // Número de fileiras do lado direito

//     const driverSider = numStartDriverSide; // lado do motorista ou n

//     const sortedList = [[], [], [], []]; // A sua merda de array com os arrays
//     const quantA = numAl + numAr;

//     const salt = quantA;

//     const firstItem = [];
//     const listAL = [0, 0, 0, 0];
//     // const listAL = [[], [], [], []];
//     console.log('selectedSeatsSecondDecker >', selectedSeatsSecondDecker);
//     const { doubleDeckerBus, firstDeckerTripId } = this.props;

//     const showRuleFirst = () => {
//       if (doubleDeckerBus) {
//         if (!showSecondDecker) {
//           return true;
//         }
//       } else if (!firstDeckerTripId) {
//         return true;
//       } else {
//         return false;
//       }
//     };
//     const showRuleSecond = () => {
//       if (doubleDeckerBus) {
//         if (showSecondDecker) {
//           return true;
//         }
//       } else if (firstDeckerTripId) {
//         return true;
//       } else {
//         return false;
//       }
//     };

//     console.log('showRuleFirst', showRuleFirst());
//     console.log('showRuleSecond', showRuleSecond());

//     // Descobrir o tamanho de cada Array
//     for (let i = 0; i < quantA; i++) {
//       switch (i) {
//         case 0:
//         case 1:
//           if (numAl === 1) {
//             listAL[0] = quantL;
//             listAL[1] = quantR;
//           } else if (quantL % 2 === 0) {
//             listAL[0] = quantL / 2;
//             listAL[1] = quantL / 2;
//           } else {
//             listAL[0] = (quantL + 1) / 2;
//             listAL[1] = (quantL - 1) / 2;
//           }
//           break;
//         case 2:
//         case 3:
//           if (numAr === 1) {
//             listAL[2] = quantR;
//           } else if (quantR % 2 === 0) {
//             if (numAl === 1) {
//               listAL[2] = quantR / 2;
//               listAL[1] = quantR / 2;
//             } else {
//               listAL[2] = quantR / 2;
//               listAL[3] = quantR / 2;
//             }
//           } else {
//             if (numAl === 1) {
//               listAL[2] = (quantR + 1) / 2;
//               listAL[1] = (quantR - 1) / 2;
//             } else {
//               listAL[3] = (quantR + 1) / 2;
//               listAL[2] = (quantR - 1) / 2;
//             }
//           }
//           break;
//         default:
//           break;
//       }
//     }

//     console.log('size', listAL);

//     // Definir os primeiros itens
//     if (!driverSider) {
//       let il = numAl - 1;
//       let il2 = 0;
//       let ir = 1;

//       for (let i = salt; i >= 1; i--) {
//         if (i <= numAl) {
//           firstItem[il] = salt - il2;
//           il--;
//           il2++;
//         } else {
//           firstItem[salt - ir] = salt - i + 1;
//           ir++;
//         }
//       }
//     } else {
//       let il = 0;
//       for (let i = 1; i <= salt; i++) {
//         if (i > numAl) {
//           firstItem[i - 1] = salt - il;
//           il++;
//         } else {
//           firstItem[i - 1] = i;
//         }
//       }
//     }

//     let higtL = 0;

//     // Pegar o maior tamanho de array
//     for (let i = 0; i < salt; i++) {
//       if (higtL < listAL[i]) {
//         higtL = listAL[i];
//       }
//     }

//     let beforeOrder = [...firstItem];

//     console.log('firstItem', beforeOrder);

//     let indexsList = [];
//     for (let i = 0; i < salt; i++) {
//       indexsList.push({
//         indexOrder: i,
//         indexCorrect: beforeOrder.findIndex(e => i + 1 === e)
//       });
//     }

//     console.log(
//       'indexsList',
//       indexsList[0],
//       indexsList[1],
//       indexsList[2],
//       indexsList[3]
//     );

//     const populatedList = [[], [], [], []];

//     let lastValue = 1;

//     for (let indexValue = 0; indexValue < higtL; indexValue++) {
//       for (let indexArray = 0; indexArray < salt; indexArray++) {
//         if (listAL[indexsList[indexArray].indexCorrect] > indexValue) {
//           populatedList[indexArray][indexValue] = lastValue;
//           lastValue++;
//         }
//       }
//     }

//     let tempIndex = 0;

//     beforeOrder.forEach(fI => {
//       let list = populatedList.find(e => e[0] === fI);
//       sortedList[tempIndex] = list;
//       tempIndex++;
//     });

//     const seatsDriver0 = [];
//     const seatsDriver1 = [];

//     const seatsPass0 = [];
//     const seatsPass1 = [];

//     sortedList[0].map(sDr =>
//       seatsDriver0.push(
//         <div
//           className={`${this.props.classes.seats}
//           ${showRuleSecond() &&
//             busySeatsSecondDecker.find(e => e === sDr) &&
//             this.props.classes.seatBusy}
//             ${showRuleSecond() &&
//               selectedSeatsSecondDecker.find(e => e === sDr) &&
//               this.props.classes.seatsSelected}
//             }
//           ${showRuleFirst() &&
//             busySeats.find(e => e === sDr) &&
//             this.props.classes.seatBusy}
//             ${showRuleFirst() &&
//               selectedSeats.find(e => e === sDr) &&
//               this.props.classes.seatsSelected}
//             `}
//           key={sDr}
//           onClick={() => {
//             if (showRuleFirst()) {
//               if (!busySeats.find(e => e === sDr)) {
//                 selectSeat(sDr, 1);
//               }
//             }
//             if (showRuleSecond()) {
//               if (!busySeatsSecondDecker.find(c => c === sDr)) {
//                 selectSeat(sDr, 2);
//               }
//             }
//           }}
//         >
//           {' '}
//           <span className={`${this.props.classes.spanSeat}`}>{sDr}</span>
//         </div>
//       )
//     );

//     sortedList[1].map(sdr1 =>
//       seatsDriver1.push(
//         <div
//           className={`${this.props.classes.seats}
//           ${showRuleSecond() &&
//             busySeatsSecondDecker.find(e => e === sdr1) &&
//             this.props.classes.seatBusy}
//           ${showRuleFirst() &&
//             busySeats.find(e => e === sdr1) &&
//             this.props.classes.seatBusy}
//             ${showRuleSecond() &&
//               selectedSeatsSecondDecker.find(e => e === sdr1) &&
//               this.props.classes.seatsSelected}
//             ${showRuleFirst() &&
//               selectedSeats.find(e => e === sdr1) &&
//               this.props.classes.seatsSelected}
//             `}
//           key={sdr1}
//           onClick={() => {
//             if (showRuleFirst()) {
//               if (!busySeats.find(e => e === sdr1)) {
//                 selectSeat(sdr1, 1);
//               }
//             }
//             if (showRuleSecond()) {
//               if (!busySeatsSecondDecker.find(c => c === sdr1)) {
//                 selectSeat(sdr1, 2);
//               }
//             }
//           }}
//         >
//           {' '}
//           <span className={`${this.props.classes.spanSeat}`}>{sdr1}</span>
//         </div>
//       )
//     );
//     sortedList[2].map(stPa =>
//       seatsPass0.push(
//         <div
//           className={`${this.props.classes.seats}
//           ${showRuleSecond() &&
//             busySeatsSecondDecker.find(e => e === stPa) &&
//             this.props.classes.seatBusy}
//           ${showRuleFirst() &&
//             busySeats.find(e => e === stPa) &&
//             this.props.classes.seatBusy}
//             ${showRuleSecond() &&
//               selectedSeatsSecondDecker.find(e => e === stPa) &&
//               this.props.classes.seatsSelected}
//             ${showRuleFirst() &&
//               selectedSeats.find(e => e === stPa) &&
//               this.props.classes.seatsSelected}
//             `}
//           key={stPa}
//           onClick={() => {
//             if (showRuleFirst()) {
//               if (!busySeats.find(e => e === stPa)) {
//                 selectSeat(stPa, 1);
//               }
//             }
//             if (showRuleSecond()) {
//               if (!busySeatsSecondDecker.find(c => c === stPa)) {
//                 selectSeat(stPa, 2);
//               }
//             }
//           }}
//         >
//           {' '}
//           <span className={`${this.props.classes.spanSeat}`}>{stPa}</span>
//         </div>
//       )
//     );
//     sortedList[3].map(stPa1 =>
//       seatsPass1.push(
//         <div
//           className={`${this.props.classes.seats}
//           ${showRuleSecond() &&
//             busySeatsSecondDecker.find(e => e === stPa1) &&
//             this.props.classes.seatBusy}
//           ${showRuleFirst() &&
//             busySeats.find(e => e === stPa1) &&
//             this.props.classes.seatBusy}
//             ${showRuleSecond() &&
//               selectedSeatsSecondDecker.find(e => e === stPa1) &&
//               this.props.classes.seatsSelected}
//             ${showRuleFirst() &&
//               selectedSeats.find(e => e === stPa1) &&
//               this.props.classes.seatsSelected}
//             `}
//           key={stPa1}
//           onClick={() => {
//             if (showRuleFirst()) {
//               if (!busySeats.find(e => e === stPa1)) {
//                 selectSeat(stPa1, 1);
//               }
//             }
//             if (showRuleSecond()) {
//               if (!busySeatsSecondDecker.find(c => c === stPa1)) {
//                 selectSeat(stPa1, 2);
//               }
//             }
//           }}
//         >
//           {' '}
//           <span className={`${this.props.classes.spanSeat}`}>{stPa1}</span>
//         </div>
//       )
//     );

//     return { seatsDriver0, seatsDriver1, seatsPass0, seatsPass1 };
//   };
//   render() {
//     const {
//       classes,
//       busTypeId,
//       busySeats,
//       busySeatsSecondDecker,
//       showSecondDecker,
//       setShowSecondDecker,
//       selectSeat,
//       selectedSeats,
//       numStartDriverSide,
//       driverSideColumnSeat,
//       driverSideSeats,
//       doubleDeckerBus,
//       passengerSideColumnSeat,
//       passengerSideSeats,
//       selectSeatSecondDecker,
//       onDoubleDeckerBus,
//       selectedSeatsSecondDecker,
//       passengerSideColumnSeatSecondDecker,
//       driverSideSeatsSecondDecker,
//       passengerSideSeatsSecondDecker,
//       driverSideColumnSeatSecondDecker,
//       numStartDriverSideSecondDecker,
//       firstDeckerTripId,
//       showTypes
//     } = this.props;
//     console.log('firstDeckerTripId', firstDeckerTripId);

//     //Primeiro Andar
//     const driverSeats0 = this.generateSeats2(
//       driverSideColumnSeat,
//       passengerSideColumnSeat,
//       driverSideSeats,
//       passengerSideSeats,
//       numStartDriverSide,
//       busySeats,
//       selectSeat,
//       selectedSeats,
//       selectedSeatsSecondDecker,
//       busySeatsSecondDecker,
//       showSecondDecker
//     ).seatsDriver0;

//     const driverSeats1 = this.generateSeats2(
//       driverSideColumnSeat,
//       passengerSideColumnSeat,
//       driverSideSeats,
//       passengerSideSeats,
//       numStartDriverSide,
//       busySeats,
//       selectSeat,
//       selectedSeats,
//       selectedSeatsSecondDecker,
//       busySeatsSecondDecker,
//       showSecondDecker
//     ).seatsDriver1;
//     const seatsPass0 = this.generateSeats2(
//       driverSideColumnSeat,
//       passengerSideColumnSeat,
//       driverSideSeats,
//       passengerSideSeats,
//       numStartDriverSide,
//       busySeats,
//       selectSeat,
//       selectedSeats,
//       selectedSeatsSecondDecker,
//       busySeatsSecondDecker,
//       showSecondDecker
//     ).seatsPass0;
//     const seatsPass1 = this.generateSeats2(
//       driverSideColumnSeat,
//       passengerSideColumnSeat,
//       driverSideSeats,
//       passengerSideSeats,
//       numStartDriverSide,
//       busySeats,
//       selectSeat,
//       selectedSeats,
//       selectedSeatsSecondDecker,
//       busySeatsSecondDecker,
//       showSecondDecker
//     ).seatsPass1;
//     // Segundo Andar

//     const driverSeatsSecondDecker0 = this.generateSeats2(
//       driverSideColumnSeatSecondDecker,
//       passengerSideColumnSeatSecondDecker,
//       driverSideSeatsSecondDecker,
//       passengerSideSeatsSecondDecker,
//       numStartDriverSideSecondDecker,
//       busySeats,
//       selectSeat,
//       selectedSeats,
//       selectedSeatsSecondDecker,
//       busySeatsSecondDecker,
//       showSecondDecker
//     ).seatsDriver0;
//     const driverSeatsSecondDecker1 = this.generateSeats2(
//       driverSideColumnSeatSecondDecker,
//       passengerSideColumnSeatSecondDecker,
//       driverSideSeatsSecondDecker,
//       passengerSideSeatsSecondDecker,
//       numStartDriverSideSecondDecker,
//       busySeats,
//       selectSeat,
//       selectedSeats,
//       selectedSeatsSecondDecker,
//       busySeatsSecondDecker,
//       showSecondDecker
//     ).seatsDriver1;
//     const seatsPassSecondDecker0 = this.generateSeats2(
//       driverSideColumnSeatSecondDecker,
//       passengerSideColumnSeatSecondDecker,
//       driverSideSeatsSecondDecker,
//       passengerSideSeatsSecondDecker,
//       numStartDriverSideSecondDecker,
//       busySeats,
//       selectSeat,
//       selectedSeats,
//       selectedSeatsSecondDecker,
//       busySeatsSecondDecker,
//       showSecondDecker
//     ).seatsPass0;
//     const seatsPassSecondDecker1 = this.generateSeats2(
//       driverSideColumnSeatSecondDecker,
//       passengerSideColumnSeatSecondDecker,
//       driverSideSeatsSecondDecker,
//       passengerSideSeatsSecondDecker,
//       numStartDriverSideSecondDecker,
//       busySeats,
//       selectSeat,
//       selectedSeats,
//       selectedSeatsSecondDecker,
//       busySeatsSecondDecker,
//       showSecondDecker
//     ).seatsPass1;

//     console.log('SEAT MAP TRIP', this.props.trip);

//     return (
//       <div>
//         <Grid
//           container
//           justify="center"
//           style={{
//             marginTop: 20
//           }}
//         >
//           <Grid
//             item
//             xs={12}
//             md={6}
//             style={{
//               background: '#f5f5f5'
//             }}
//           >
//             <Grid container justify="space-between" style={{ padding: 10 }}>
//               {showTypes && (
//                 <Fragment>
//                   <Grid item xs={3}>
//                     <div className={classes.seats} />
//                     <Typography> Disponivel</Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <div
//                       className={`${classes.seats} ${classes.seatsSelected}`}
//                     />
//                     <Typography> Escolhido </Typography>
//                   </Grid>
//                   <Grid item xs={3}>
//                     <div className={`${classes.seats} ${classes.seatBusy}`} />
//                     <Typography> Ocupado</Typography>
//                   </Grid>
//                 </Fragment>
//               )}
//             </Grid>
//           </Grid>
//           {doubleDeckerBus && (
//             <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
//               <Grid container justify="center">
//                 <Grid item xs={3} style={{ textAlign: 'center' }}>
//                   <Button
//                     color="primary"
//                     variant={!showSecondDecker ? 'contained' : 'outlined'}
//                     onClick={e => setShowSecondDecker(false)}
//                   >
//                     1 Andar
//                   </Button>
//                 </Grid>
//                 <Grid item xs={3} style={{ textAlign: 'center' }}>
//                   <Button
//                     color="primary"
//                     variant={showSecondDecker ? 'contained' : 'outlined'}
//                     onClick={e => setShowSecondDecker(true)}
//                   >
//                     2 Andar
//                   </Button>
//                 </Grid>
//               </Grid>
//             </Grid>
//           )}
//           {/* // eslint-disable-next-line jsx-a11y/alt-text */}
//           <img
//             src={frontBus}
//             alt="Frente Onibus"
//             style={{ width: 380, margin: 'auto', paddingTop: 10 }}
//           />
//           <Grid xs={12} />
//           {/* Primeiro Andar */}
//           {doubleDeckerBus
//             ? !showSecondDecker && (
//                 // Primeiro Andar
//                 <Fragment>
//                   <Grid
//                     item
//                     xs={6}
//                     sm={7}
//                     style={{
//                       maxWidth: 400,
//                       height: '100vh',
//                       margin: '-12px auto'
//                     }}
//                   >
//                     <Grid container direction="row">
//                       <Grid
//                         item
//                         xs={12}
//                         style={{ textAlign: 'center', paddingTop: 10 }}
//                       >
//                         <Typography variant="h6" align="center">
//                           Primeiro Andar
//                         </Typography>
//                       </Grid>
//                       <Grid item xs={10} style={{ padding: 20 }}>
//                         <Grid
//                           container
//                           justify="center"
//                           style={{ flexWrap: 'nowrap' }}
//                         >
//                           <Grid
//                             item
//                             xs={12}
//                             sm={6}
//                             style={{
//                               display: 'flex'
//                             }}
//                           >
//                             <div style={{ width: 40 }}>{driverSeats0}</div>
//                             <div style={{ padding: 5 }} />
//                             <div style={{ width: 40 }}>{driverSeats1}</div>
//                             <div style={{ padding: 10 }} />
//                             <div style={{ width: 40 }}>{seatsPass0}</div>
//                             <div style={{ padding: 5 }} />
//                             <div style={{ width: 40 }}>{seatsPass1}</div>
//                           </Grid>
//                         </Grid>
//                       </Grid>
//                     </Grid>
//                   </Grid>
//                 </Fragment>
//               )
//             : !firstDeckerTripId && (
//                 // Primeiro Andar
//                 <Fragment>
//                   <Grid
//                     item
//                     xs={6}
//                     sm={6}
//                     style={{
//                       maxWidth: 400,
//                       height: '100vh',
//                       margin: '-12px auto'
//                     }}
//                   >
//                     <Grid container direction="row">
//                       <Grid
//                         item
//                         xs={12}
//                         style={{ textAlign: 'center', paddingTop: 10 }}
//                       >
//                         <Typography variant="h6" align="center">
//                           Primeiro Andar
//                         </Typography>
//                       </Grid>
//                       <Grid item xs={10} style={{ padding: 20 }}>
//                         <Grid
//                           container
//                           justify="center"
//                           style={{ flexWrap: 'nowrap' }}
//                         >
//                           <Grid
//                             item
//                             xs={12}
//                             sm={6}
//                             style={{
//                               display: 'flex'
//                             }}
//                           >
//                             <div style={{ width: 40 }}>{driverSeats0}</div>
//                             <div style={{ padding: 5 }} />
//                             <div style={{ width: 40 }}>{driverSeats1}</div>
//                             <div style={{ padding: 10 }} />
//                             <div style={{ width: 40 }}>{seatsPass0}</div>
//                             <div style={{ padding: 5 }} />
//                             <div style={{ width: 40 }}>{seatsPass1}</div>
//                           </Grid>
//                         </Grid>
//                       </Grid>
//                     </Grid>
//                   </Grid>
//                 </Fragment>
//               )}
//           {/* Segundo Andar */}
//           {doubleDeckerBus
//             ? showSecondDecker && (
//                 // Segundo
//                 <Grid
//                   item
//                   xs={6}
//                   sm={6}
//                   style={{
//                     height: '100vh',
//                     maxWidth: 400,
//                     margin: '-12px auto'
//                   }}
//                 >
//                   <Grid container direction="row">
//                     <Grid
//                       item
//                       xs={12}
//                       style={{ textAlign: 'center', paddingTop: 10 }}
//                     >
//                       <Typography variant="h6" align="center">
//                         Segundo Andar
//                       </Typography>
//                     </Grid>
//                     <Grid item xs={10} style={{ padding: 20 }}>
//                       <Grid container justify="center">
//                         {/* { for(let i = 0; i < driverSideSeats; i+++){ */}
//                         <Grid
//                           item
//                           xs={12}
//                           sm={6}
//                           style={{
//                             display: 'flex'
//                           }}
//                         >
//                           <div style={{ width: 40 }}>
//                             {driverSeatsSecondDecker0}
//                           </div>
//                           <div style={{ padding: 5 }} />
//                           <div style={{ width: 40 }}>
//                             {driverSeatsSecondDecker1}
//                           </div>
//                           <div style={{ padding: 10 }} />
//                           <div style={{ width: 40 }}>
//                             {seatsPassSecondDecker0}
//                           </div>
//                           <div style={{ padding: 5 }} />

//                           <div style={{ width: 40 }}>
//                             {seatsPassSecondDecker1}
//                           </div>
//                         </Grid>
//                         {/* }} */}
//                       </Grid>
//                     </Grid>
//                   </Grid>
//                 </Grid>
//               )
//             : firstDeckerTripId && (
//                 // Segundo
//                 <Grid
//                   item
//                   xs={6}
//                   sm={6}
//                   style={{
//                     height: '100vh',
//                     maxWidth: 400,
//                     margin: '-12px auto'
//                   }}
//                 >
//                   <Grid container direction="row">
//                     <Grid
//                       item
//                       xs={12}
//                       style={{ textAlign: 'center', paddingTop: 10 }}
//                     >
//                       <Typography variant="h6" align="center">
//                         Segundo Andar
//                       </Typography>
//                     </Grid>
//                     <Grid item xs={10} style={{ padding: 20 }}>
//                       <Grid container justify="center">
//                         {/* { for(let i = 0; i < driverSideSeats; i+++){ */}
//                         <Grid
//                           item
//                           xs={12}
//                           sm={6}
//                           style={{
//                             display: 'flex'
//                           }}
//                         >
//                           <div style={{ width: 40 }}>
//                             {driverSeatsSecondDecker0}
//                           </div>
//                           <div style={{ padding: 5 }} />
//                           <div style={{ width: 40 }}>
//                             {driverSeatsSecondDecker1}
//                           </div>
//                           <div style={{ padding: 10 }} />
//                           <div style={{ width: 40 }}>
//                             {seatsPassSecondDecker0}
//                           </div>
//                           <div style={{ padding: 5 }} />

//                           <div style={{ width: 40 }}>
//                             {seatsPassSecondDecker1}
//                           </div>
//                         </Grid>
//                       </Grid>
//                     </Grid>
//                   </Grid>
//                 </Grid>
//               )}
//         </Grid>
//       </div>
//     );
//   }
// }

SeatsMapNew.propTypes = {
  showTypes: PropTypes.bool,
  numStartDriverSide: PropTypes.bool,
  onDoubleDeckerBus: PropTypes.func,
  doubleDeckerBus: PropTypes.bool,
  driverSideColumnSeatSecondDecker: PropTypes.number,
  passengerSideColumnSeatSecondDecker: PropTypes.number,
  driverSideSeatsSecondDecker: PropTypes.number,
  passengerSideSeatsSecondDecker: PropTypes.number,
  passengerSideSeats: PropTypes.number,
  passengerSideColumnSeat: PropTypes.number,
  driverSideSeats: PropTypes.number,
  driverSideColumnSeat: PropTypes.number,
  classes: PropTypes.objectOf(PropTypes.any),
};
export default withStyles(styles)(SeatsMapNew);
