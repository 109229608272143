import Axios from 'axios';

export default class TripApiService {
  static getTripDiscount(tripId) {
    const url = `${process.env.REACT_APP_API_BASE_URL}/trip/discount`;

    return Axios.get(url);
  }

  static getFirstTripDiscount(
    tripIdDepart,
    tripIdReturn,
    passengers,
    userIdCustomer
  ) {
    let url = `${process.env.REACT_APP_API_BASE_URL}/trip/discount/${tripIdDepart}/${tripIdReturn}/${passengers}`;
    if (userIdCustomer) {
      url = `${url}?${userIdCustomer} `;
    }
    return Axios.get(url);
  }
  static getBusSeats(tripId, decker) {
    const url = `${process.env.REACT_APP_API_BASE_URL}/trip/bus-map-seats/${tripId}/${decker}`;
    return Axios.get(url);
  }

  // static getBusSeatsNew(
  //   departTripId,
  //   departServiceId,
  //   departDate,
  //   returnTripId,
  //   returnServiceId,
  //   returnDate
  // ) {
  //   const url = `${process.env.REACT_APP_API_BASE_URL}/bus-map-seats/list`;
  //   const data = {
  //     departTripId: departServiceId ? null : departTripId,
  //     departServiceId,
  //     departDate,
  //     returnTripId: returnServiceId ? null : returnTripId,
  //     returnServiceId,
  //     returnDate,
  //   };

  //   return Axios.post(url, data);
  // }

  static getBusSeatsNew(departTripId, returnTripId) {
    console.log('departTripId', departTripId);
    console.log('returnTripId', returnTripId);
    let url = `${process.env.REACT_APP_API_BASE_URL}/bus-map-seats?departTripId=${departTripId}`;
    if (returnTripId) {
      url += `&returnTripId=${returnTripId}`;
    }
    console.log('TripId', url);
    return Axios.get(url);
  }

  static getTotalbusTrips(departureCityId, arrivalCityId, departureDate) {
    const url = `${process.env.REACT_APP_API_TOTALBUS_URL}/integrations/trips/${departureCityId}/${arrivalCityId}/${departureDate}`;
    return Axios.get(url);
  }
}
