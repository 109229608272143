import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
  FileCopy,
  Done
} from '@material-ui/icons'
import AuthApiService from '../../services/AuthApiService'
import Skeleton from '@material-ui/lab/Skeleton';
import SettingsRepository from '../../repositories/SettingsRepository'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

function MyDiscount(props) {
  const { user } = props
  const [discountData, setDiscountData] = useState(null)
  const [discountDesc, setDiscountDesc] = useState(null)
  const [discountLoading, setDiscountLoading] = useState(true)
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    let unmounted = false;

    SettingsRepository.getSettings().then(res => {
      if (!unmounted) {
        setDiscountDesc(res.data().discountDescription)
      }
    })

    AuthApiService.getUser().then(res => {
      if (!unmounted) {
        setDiscountData({
          code: res.data.codeCoupon,
          qtd: res.data.couponsUsed
        })
      }
    });

    if (!unmounted) {
      setDiscountLoading(false);
    }

    return () => {
      // console.log('cleanup');
      unmounted = true;
    };
  }, [user.uidFirebase]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 3000);
    }
  }, [copied])

  return (
    <>
      <Box>
        <Typography variant="h4" style={{ marginBottom: '10px' }}>Desconto</Typography>

        <Box component={Paper} style={{ padding: '20px 25px' }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'flex-start', margin: '5px 0' }}>
            {discountLoading || discountData === null ? (
              <>
                <Skeleton variant="rect" height={40} style={{ padding: 2 }}>
                  <Skeleton variant="text" width={250} />
                  <Skeleton variant="text" width={100} />
                </Skeleton>
              </>
            ) : (
              discountData.code === null ? (
                <Typography variant="body1">Ainda não existe um cupom de desconto gerado.</Typography>
              ) : (
                <>
                  <Typography variant="body1" style={{ flexBasis: '100%', marginBottom: '10px' }}>Meu cupom de desconto:</Typography>
                  <Typography variant="h5" style={{ fontWeight: 'bold', marginRight: '10px' }}>{discountData.code}</Typography>
                  <CopyToClipboard
                    text={discountData.code}
                    onCopy={() => setCopied(true)}
                  >
                    {copied ? <Done style={{ color: '#4caf50' }} /> : <FileCopy style={{ color: '#cccccc', cursor: 'pointer' }} />}
                  </CopyToClipboard>
                </>
              )
            )}
          </div>
        </Box>
        {discountLoading || discountDesc === null || discountData === null ? (
          <Skeleton variant="rect" height={40} style={{ padding: 2, margin: '10px 0' }}>
            <Skeleton variant="text" width={300} />
          </Skeleton>
        ) : (
          discountData.code !== null && <Typography variant="body2" style={{ padding: '10px' }}>{discountDesc}</Typography>
        )}

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', color: 'rgb(2, 179, 212)' }}>
          {discountLoading || discountData === null ? (
            <Skeleton variant="rect" height={40} style={{ padding: 2 }}>
              <Skeleton variant="text" width={150} style={{ marginRight: 2 }} />
            </Skeleton>
          ) : (
            discountData.code !== null && (
              <>
                <span style={{ fontSize: '35px', marginRight: '10px' }}>{discountData.qtd}</span>
                <Typography variant="h5">pessoas utilizaram seu cupom</Typography>
              </>
            )
          )}
        </div>
      </Box>
    </>
  );
}

const mapStateToProps = store => ({
  user: store.auth.user,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyDiscount);
