import { db } from "../firebase";
export const LISTENER_PIX_SUCCESS = "LISTENER_PIX_SUCCESS";
export const LISTENER_PIX_ERROR = "LISTENER_PIX_ERROR";

export const bookingListenerPix = (bookingCode) => {
  return (dispatch) => {
    const unsubscribe = db.collection("bookings")
      .doc(bookingCode)
      .onSnapshot(
        (doc) => {
          const data = doc.data()
          if(data.statusId === 3){
            dispatch(bookingListenerSuccess(data));
            unsubscribe()
          }
        },
        (reason) => {
          dispatch(bookingListenerError(reason));
          unsubscribe()
        }
      );
  };
};

const bookingListenerSuccess = (data) => ({
  type: LISTENER_PIX_SUCCESS,
  data,
});

const bookingListenerError = (error) => ({
  type: LISTENER_PIX_ERROR,
  error,
});

const ListenerActions = { bookingListenerPix };

export default ListenerActions;
