export const generateRows = seats => {
  const pLW = []
  const pLC = []
  const pRW = []
  const pRC = []
  let hasPLW = false
  let hasPLC = false
  let hasPRW = false
  let hasPRC = false
  console.log('generate -->', seats)
  if (seats[0]) {
    const size = seats.length
    let index = 0
    console.log('generateRows', seats[seats.length - 1].number)
    // const lastNumber = seats[seats.length - 1].number;

    const vazio = {
      type: 'DISABLED'
    }

    const corredor = {
      type: 'HALL'
    }

    // eslint-disable-next-line no-inner-declarations
    function createPoltrona (item) {
      item.type = 'SEAT'
      item.identifier = item.number
      return item
    }

    let lastNumber = seats[seats.length - 1].number

    seats.forEach(item => {
      if (item.number > lastNumber) {
        lastNumber = item.number
      }
    })
    let currentRow = seats[0].row

    seats.forEach(item => {
      if (item.row !== currentRow) {
        currentRow = item.row
        verifyOthers(item.row)
      }

      if (item.side === 'L') {
        if (item.window === true) {
          hasPLW = true
          pLW.push(createPoltrona(item))
        } else {
          hasPLC = true
          pLC.push(createPoltrona(item))
        }
      } else {
        if (item.window === true) {
          hasPRW = true
          pRW.push(createPoltrona(item))
        } else {
          hasPRC = true
          pRC.push(createPoltrona(item))
        }
      }

      if (size - 1 === index) {
        console.log('ITEM -->', item)
        verifyOthers(currentRow)
      }
      index++
    })

    // eslint-disable-next-line no-inner-declarations
    function verifyOthers (currentRow) {
      console.log('verify other', currentRow)
      if (!hasPLW) {
        pLW.push({
          ...vazio,
          window: true,
          side: 'L',
          row: currentRow
        })
      }
      if (!hasPLC) {
        pLC.push({
          ...vazio,
          window: false,
          side: 'L',
          row: currentRow
        })
      }
      if (!hasPRC) {
        pRC.push({
          ...vazio,
          window: false,
          side: 'R',
          row: currentRow
        })
      }
      if (!hasPRW) {
        pRW.push({
          ...vazio,
          window: true,
          side: 'R',
          row: currentRow
        })
      }
      resetHas()
    }

    // eslint-disable-next-line no-inner-declarations
    function resetHas () {
      hasPLW = false
      hasPLC = false
      hasPRC = false
      hasPRW = false
    }

    const corredorArray = []

    pLW.forEach(item => {
      corredorArray.push(corredor)
    })

    const arrays = []

    arrays.push(pLW)
    arrays.push(pLC)
    arrays.push(corredorArray)
    arrays.push(pRC)
    arrays.push(pRW)

    const rows = []

    for (let i = 0; i < pLW.length; i++) {
      const row = []

      for (let i2 = 0; i2 < 5; i2++) {
        row.push(arrays[i2][i])
      }

      rows.push(row)
    }

    console.log(rows)

    const layout = {
      rows: rows
    }

    return layout
  }
}
