import {
  GET_FOR_SALE_TRIPS,
  FOR_SALE_TRIPS_SUCCESS,
  FOR_SALE_TRIPS_ERROR
} from "../actions/actionTypes"

const initialState = {
  trips: [],
  isLoading: false,
  error: null,
  isEmpty: null
}

const forSaleTripsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FOR_SALE_TRIPS: {
      return {
        ...state,
        isLoading: true,
        trips: [],
        error: null,
        isEmpty: null
      }
    }
    case FOR_SALE_TRIPS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        trips: action.trips,
        isEmpty: action.trips === null || action.trips.length === 0
      }
    }
    case FOR_SALE_TRIPS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: state.error
      }

    default:
      return state
  }
}

export default forSaleTripsReducer