import {
  Icon,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  makeStyles,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import { green } from '@material-ui/core/colors';

const ColorlibConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 22,
    [theme.breakpoints.down('sm')]: {
      top: 15,
    },
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,#66bb6a 0%,#4BB0C2 50%,#4BB0C2 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,#66bb6a 0%,#66bb6a 50%,#66bb6a 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
}))(StepConnector);

const useColorlibStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 30,
      height: 30,
    },
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundColor: green[400],
  },
  icon: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '19px',
    },
  },
  label: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
}));

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <Icon className={classes.icon}>group-add</Icon>,
    2: <Icon className={classes.icon}>event_seat</Icon>,
    3: <FontAwesomeIcon className={classes.icon} icon={faCheckDouble} />,
    4: <Icon className={classes.icon}> directions_bus</Icon>,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

function getSteps() {
  return [
    'Selecione os passageiros',
    'Seleção de Assento',
    'Confirme a reserva',
    'Concluído',
  ];
}

function CheckoutStepper(props) {
  const { activeStep } = props;
  const steps = getSteps();
  const classes = useColorlibStepIconStyles();
  return (
    <div>
      <Stepper
        style={{ padding: '24px 30px', marginTop: '70px' }}
        activeStep={activeStep}
        alternativeLabel
        connector={<ColorlibConnector />}
      >
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <span className={classes.label}>{label}</span>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}

export default CheckoutStepper;
