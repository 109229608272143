import { Box, Grid, Icon } from '@material-ui/core';

import React from 'react';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

function TripDirections(props) {
  const { trip } = props;
  return (
    <>
      <Box textAlign="center">
        <Grid container spacing={1} justify="center">
          <Grid item xs={5}>
            <h2 style={{ margin: 0 }}>
              {moment.utc(trip.etd).format('HH:mm')}
            </h2>
            <Typography variant="caption">{trip.departure.name}</Typography>
            <h4 style={{ margin: 0, minHeight: '40px' }}>
              {trip.departure.city.name}
              <span style={{ fontSize: '1.2em' }}>
                , {trip.departure.city.uf}
              </span>
            </h4>
          </Grid>
          <Grid item xs={2} style={{ alignSelf: 'center' }}>
            <Icon>arrow_forward</Icon>
          </Grid>
          <Grid item xs={5}>
            <h2 style={{ margin: 0 }}>
              {moment.utc(trip.eta).format('HH:mm')}
            </h2>
            <Typography variant="caption">{trip.arrival.name}</Typography>
            <h4 style={{ margin: 0, minHeight: '40px' }}>
              {trip.arrival.city.name}
              <span style={{ fontSize: '1.2em' }}>
                , {trip.arrival.city.uf}
              </span>
            </h4>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default TripDirections;
