import { applyMiddleware, createStore } from "redux";

import Reducers from "../reducers";
// import STORAGE from "../constants/storage";
// import { storage } from "../utils/storage";
// import throttle from "lodash/throttle";
import thunk from "redux-thunk";

// import { loadState, saveState } from "./localStorage.middleware";


const middlewares = [thunk];


const devTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
// const persistedState = loadState();

// console.log("persistedState", persistedState);
const store = applyMiddleware(thunk)(createStore)(Reducers,devTools)
// const Store = createStore(
//   Reducers,

//   // persistedState,
//   applyMiddleware(
//     ...middlewares
//     // store => {
//     //   return next => action => {
//     //     console.groupCollapsed("[ACTION] :: " + action.type);
//     //     console.log("[BEFORE Dispatch] :: " + action.type, action);

//     //     // Call the next dispatch method in the middleware chain.
//     //     const returnValue = next(action);

//     //     console.log(
//     //       "[AFTER Dispatch] :: " + action.type + " || State -> ",
//     //       store.getState()
//     //     );

//     //     console.groupEnd();

//     //     // This will likely be the action itself, unless
//     //     // a middleware further in chain changed it.
//     //     return returnValue;
//     //   };
//     // },
//     // store => {
//     //   return next => action => {
//     //     return next(action);
//     //   };
//     // }
//   )
// )

// Store.subscribe(
//   throttle(() => {
//     saveState(Store.getState());
//   }),
//   1000
// );

export default store;
