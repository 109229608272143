import React, { useEffect } from "react";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { Link, withRouter } from "react-router-dom";
import clsx from "clsx";
import { Formik, Field } from "formik";
import styled from "styled-components";
import {
  CssBaseline,
  Checkbox,
  CircularProgress,
  Paper,
  Button,
  TextField,
  FormControlLabel,
  Breadcrumbs,
  Typography,
} from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";
import MaskedInput from "react-text-mask";

import ROUTES from "./../constants/routes";

import { authSignUp, enqueueSnackbar, removeSnackbar } from "./../actions";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: "80px",
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 350,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  link: {
    fontSize: "14px",
    color: "#0FB8D4",
    textDecoration: "none",
    "&:hover": {
      color: "rgb(10, 128, 148)",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  loadingButtonWrapper: {
    textAlign: "center",
    position: "relative",
    width: "100%",
  },
  submit: {
    textTransform: "none",
    margin: theme.spacing(1, 0, 2),
  },
}));
const CellPhoneMask = (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,

        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      //   showMask
      keepCharPositions={true}
    />
  );
};
const CustomTextField = styled(TextField)`
  label {
    transform: translate(12px, 16px) scale(1);
  }
  input {
    padding: 14px 12px;
  }
`;

const Signup = (props) => {
  const { error, signUpAction, enqueueSnackbar, user, history } = props;
  const [isLoading, setIsLoading] = React.useState(false);

  const phoneNumberMask = [];

  const classes = useStyles();

  if (!!error) {
    let errorMessage = "Erro desconhecido";
    let autoHideDuration = 4000;

    if (error.code === "auth/email-already-in-use") {
      errorMessage = "Esse email já está em uso";
    }

    enqueueSnackbar({
      message: errorMessage,
      options: {
        key: "signUpSnack",
        autoHideDuration: autoHideDuration,
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      },
    });
  }

  const signupSchema = Yup.object().shape({
    name: Yup.string().required("Por favor digite seu nome"),
    email: Yup.string()
      .email("Email inválido")
      .required("Por favor digite seu email"),
    cellphone: Yup.string()
      .required("Por favor digite o seu celular")
      .trim()
      .min(15, "Celular Inválido"),

    password: Yup.string()
      .min(6, "Senha muito fraca")
      .required("Por favor digite uma senha"),
    passwordConfirm: Yup.string()
      .min(6, "Senha muito fraca")
      .oneOf([Yup.ref("password")], "As senhas não conferem")
      .required("Por favor digite novamente sua senha"),
    acceptTerms: Yup.boolean().oneOf(
      [true],
      "É necessário aceitar os Termos de Uso e Privacidade"
    ),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    setIsLoading(true);
    signUpAction(
      values.name,
      values.email,
      values.password,
      values.cellphone,
      () => {
        setSubmitting(false);
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    if (user) {
      history.push(ROUTES.HOME);
    }
  }, [history, user]);

  return (
    <>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link className={classes.link} color="inherit" to={ROUTES.SIGNIN}>
              Inicie sessão
            </Link>
            <Typography style={{ fontSize: "14px" }} color="textPrimary">
              Criar sua conta
            </Typography>
          </Breadcrumbs>

          <Formik
            initialValues={{
              name: "",
              email: "",
              password: "",
              passwordConfirm: "",
              cellphone: "",
              acceptTerms: false,
            }}
            validationSchema={signupSchema}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={handleSubmit}
          >
            {({
              isValid,
              values,
              errors,
              status,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              field,
            }) => {
              console.log("Values Form -->", values);
              console.log("handleChange  -->", handleChange);

              return (
                <form onSubmit={handleSubmit}>
                  <CustomTextField
                    fullWidth
                    id="name"
                    autoFocus={true}
                    className={clsx(classes.margin, classes.textField)}
                    margin="normal"
                    variant="outlined"
                    type="text"
                    label="Nome"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isLoading}
                    InputProps={{
                      inputProps: {
                        autoComplete: "off",
                        form: {
                          autoComplete: "off",
                        },
                      },
                    }}
                    error={errors.name && touched.name}
                    helperText={
                      errors.name && touched.name ? errors.name : null
                    }
                  />

                  <CustomTextField
                    fullWidth
                    id="email"
                    className={clsx(classes.margin, classes.textField)}
                    margin="normal"
                    variant="outlined"
                    type="text"
                    label="Email"
                    value={values.email}
                    onChange={handleChange}
                    disabled={isLoading}
                    onBlur={handleBlur}
                    InputProps={{
                      inputProps: {
                        autoComplete: "off",
                        form: {
                          autoComplete: "off",
                        },
                      },
                    }}
                    error={errors.email && touched.email}
                    helperText={
                      errors.email && touched.email ? errors.email : null
                    }
                  />

                  <Field
                    name="cellphone"
                    render={({ field }) => (
                      <CustomTextField
                        {...field}
                        mask={phoneNumberMask}
                        fullWidth
                        id="cellphone"
                        className={clsx(classes.margin, classes.textField)}
                        margin="normal"
                        variant="outlined"
                        type="text"
                        label="Celular"
                        value={values.cellphone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isLoading}
                        InputProps={{
                          inputComponent: CellPhoneMask,
                          inputProps: {
                            autoComplete: "off",
                            form: {
                              autoComplete: "off",
                            },
                          },
                        }}
                        error={errors.cellphone && touched.cellphone}
                        helperText={
                          errors.cellphone && touched.cellphone
                            ? errors.cellphone
                            : null
                        }
                      />
                    )}
                  />

                  <CustomTextField
                    fullWidth
                    id="password"
                    className={clsx(classes.margin, classes.textField)}
                    margin="normal"
                    variant="outlined"
                    type="password"
                    label="Senha"
                    value={values.password}
                    onChange={handleChange}
                    disabled={isLoading}
                    onBlur={handleBlur}
                    InputProps={{
                      inputProps: {
                        autoComplete: "off",
                        form: {
                          autoComplete: "off",
                        },
                      },
                    }}
                    error={errors.password && touched.password}
                    helperText={
                      errors.password && touched.password
                        ? errors.password
                        : null
                    }
                  />

                  <CustomTextField
                    fullWidth
                    id="passwordConfirm"
                    className={clsx(classes.margin, classes.textField)}
                    margin="normal"
                    variant="outlined"
                    type="password"
                    label="Confirme sua Senha"
                    value={values.passwordConfirm}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isLoading}
                    InputProps={{
                      inputProps: {
                        autoComplete: "new-password",
                        form: {
                          autoComplete: "off",
                        },
                      },
                    }}
                    error={errors.passwordConfirm && touched.passwordConfirm}
                    helperText={
                      errors.passwordConfirm && touched.passwordConfirm
                        ? errors.passwordConfirm
                        : null
                    }
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        name="acceptTerms"
                        checked={values.acceptTerms}
                        onChange={handleChange}
                        value={true}
                      />
                    }
                    label={
                      <div style={{ margin: "16px 0" }}>
                        <span style={{ fontSize: "14px" }}>
                          Declaro que li e aceito os
                        </span>{" "}
                        <Link
                          className={classes.link}
                          href="https://4bus.page.link/politica-privacidade-termos-uso"
                          target="_blank"
                        >
                          Termos de Uso e Privacidade
                        </Link>
                      </div>
                    }
                  />
                  <div className={classes.loadingButtonWrapper}>
                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!isValid || isSubmitting}
                      className={classes.submit}
                    >
                      Cadastrar
                    </Button>
                    {isLoading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </form>
              );
            }}
          </Formik>
        </Paper>
      </main>
    </>
  );
};

const mapStateToProps = (store) => ({
  user: store.auth.user,
  isLoading: store.auth.isLoading,
  error: store.auth.signUpError,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signUpAction: authSignUp,
      enqueueSnackbar: enqueueSnackbar,
      removeSnackbar: removeSnackbar,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signup));
