import { Box, Grid, Paper, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';

import TripSteps from '../trip-steps/TripSteps';
import UserRepository from '../../../repositories/UserRepository';
import moment from 'moment';

function BookingGroups(props) {
  const { user } = props;
  const [loading, setLoading] = React.useState(true);
  const [trips, setTrips] = React.useState([]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      setLoading(true);
      UserRepository.geTripGroups(user.id).then(trips => {
        if (!unmounted) {
          setLoading(false);
          setTrips(trips);
          // console.log('trips', trips);
        }
      });
      // console.log('mounted');
    }

    // console.log('out');

    return () => {
      // console.log('cleanup');
      unmounted = true;
    };
  }, [setLoading, user.id]);

  // console.log('trips', trips);

  const getTripSteps = trip => {
    return [
      { title: `${trip.departureCity.name}, ${trip.departureCity.uf}` },
      { title: `${trip.arrivalCity.name}, ${trip.arrivalCity.uf}` },
    ];
  };

  return (
    <>
      {!loading &&
        trips.map(trip => (
          <Box component={Paper} padding={2} margin={2} key={trip.id}>
            <Grid container spacing={1} direction="column">
              <Grid item>
                <TripSteps steps={getTripSteps(trip)} spacing={10} />
              </Grid>
              <Grid item>
                <Typography variant="caption">
                  {moment.utc(trip.departDate).format('dddd')}
                </Typography>
                <Typography variant="h5">{`${moment
                  .utc(new Date(trip.departDateTimestamp))
                  .format('DD')} ${moment
                  .utc(new Date(trip.departDateTimestamp))
                  .format('MMM')}`}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2" align="right" color="primary">
                  {trip.status.name}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ))}

      {!loading && trips && trips.length === 0 && (
        <Box component={Paper} textAlign="center" padding="5em">
          <Typography variant="h5">Nenhum grupo encontrado</Typography>
        </Box>
      )}
    </>
  );
}

export default BookingGroups;
