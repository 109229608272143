import { Icon, IconButton, Typography } from '@material-ui/core';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { makeStyles } from '@material-ui/styles';

const styles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  titleRoot: {
    margin: 0,
    padding: theme.spacing(2),
  },
}));

const DialogTitle = props => {
  const classes = styles();
  const { children, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.titleRoot}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Icon>close</Icon>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

export default DialogTitle;
