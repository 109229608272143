import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AppConfirmation = props => {
  const { title, message, actions, open, handleClose } = props;

  if (!Boolean(open)) {
    return null;
  }

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {Boolean(title) && (
          <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        )}

        {Boolean(message) && <DialogContent>{message}</DialogContent>}

        <DialogActions>
          {Boolean(actions) &&
            actions.map(action => (
              <Button
                key={Math.random() + Date.now}
                onClick={action.onClick}
                disabled={action.disabled === undefined ? false : action.disabled}
                {...action.buttonProps}
              >
                {action.label}
              </Button>
            ))}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AppConfirmation;
