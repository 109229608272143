import * as Yup from 'yup';
import { KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBRLocale from "date-fns/locale/pt-BR";

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { Field, Formik } from 'formik';

import AppDatePicker from '../app-datepicker/AppDatePicker';
import AutoComplete from '../autocomplete/AutoComplete';
import CitiesRepository from '../../repositories/CitiesRepository';
import LoadingOverlay from 'react-loading-overlay';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import TripGroupApiService from '../../services/TripGroupApiService';
import Typography from '@material-ui/core/Typography';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import deburr from 'lodash/deburr';
import { enqueueSnackbar } from '../../actions';
import { green } from '@material-ui/core/colors';
import moment from 'moment';

const styles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  titleRoot: {
    margin: 0,
    padding: theme.spacing(2),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  buttonSpacer: {
    marginTop: theme.spacing(1),
  },
  loadingButtonWrapper: {
    position: 'relative',
    width: '100%',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexFlow: 'column',
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textField: {
    flexBasis: 200,
  },
}));

const DatePickerField = ({ field, form, className, format, ...other }) => {
  const currentError = form.errors[field.name];

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
      <KeyboardDatePicker
        clearable
        name={field.name}
        value={field.value}
        format={format || "dd/MM/yyyy"}
        onError={(error) => {
          // handle as a side effect
          if (error !== currentError) {
            form.setFieldError(field.name, error);
          }
        }}
        onChange={(date) => form.setFieldValue(field.name, date)}
        inputVariant="standard"
        maskChar="_"
        {...other}
      />
    </MuiPickersUtilsProvider>
  );
};
 

const DialogTitle = props => {
  const classes = styles();
  const { children, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.titleRoot}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Icon>close</Icon>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

function SuggestTrip(props) {
  const { open, handleClose, enqueueSnackbar } = props;
  const { cityDeparture, cityArrival, departDate = new Date() } = props;

  const classes = styles();
  const [isLoading, setIsLoading] = React.useState(false);
  const [suggestions, setSuggestions] = React.useState([]);
  const citiesRepository = new CitiesRepository();
  // console.log('props', props)

  const getSuggestions = (suggestions, value) => {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength === 0
      ? []
      : suggestions.filter(suggestion => {
          const keep =
            count <= 5 &&
            suggestion.nameFormat.slice(0, inputLength).toLowerCase() ===
              inputValue;

          if (keep) {
            count += 1;
          }

          return keep;
        });
  };

  const getSuggestionValue = suggestion => {
    return `${suggestion.name}, ${suggestion.uf}`;
  };

  const handleSuggestionsFetchRequested = ({ value }) => {
    if (value.length >= 3) {
      citiesRepository.searchCities(value, false).then(snapshot => {
        // console.log("querySnapshot", querySnapshot);
        // console.log("querySnapshot.docs", querySnapshot.docs);
        const res = snapshot.docs.map(i => i.data());

        setSuggestions(getSuggestions(res || [], value));
      });
    }
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const formSchema = Yup.object().shape({
    departCity: Yup.object().shape({
      id: Yup.string().required('Local de partida é obrigatório'),
    }),
    arrivalCity: Yup.object().shape({
      id: Yup.string().required('Local de destino é obrigatório'),
    }),
    departDate: Yup.date()
      .nullable()
      .required('Data de Ida é obrigatória')
      .typeError('Data de Ida inválida'),
    seats: Yup.number()
      .required('Quantidade de passageiros é obrigatório')
      .min(3, 'Quantidade mínima de 3 passageiros'),
    note: Yup.string().notRequired(),
  });

  const searchTrip = (values, { setSubmitting }) => {
    setSubmitting(true);
    setIsLoading(true);
    // console.log('searchTrip values', values);

    TripGroupApiService.create(
      values.departCity.id,
      values.arrivalCity.id,
      moment(values.departDate).valueOf(),
      values.seats,
      values.note,
    ).then(
      res => {
        if (res.status === 200 || res.status === 201) {
          enqueueSnackbar({
            message: `Sugestão de viagem enviada com sucesso!`,
            options: {
              key: 'saveTripGroupSnack',
              autoHideDuration: 2500,
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            },
          });

          handleClose && handleClose();
        }
      },
      error => {
        enqueueSnackbar({
          message: `Não foi possivel enviar sua sugestão de viagem. Faça login e tente novamente.`,
          options: {
            key: 'saveTripGroupSnack',
            autoHideDuration: 3500,
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          },
        });
      },
    );
  };

  return (
    <LoadingOverlay spinner active={isLoading}>
      <Dialog
        fullWidth
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle onClose={handleClose}>Sugerir linha</DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <Formik
              initialValues={{
                // departCity: { name: 'Sao Paulo', uf: 'SP', id: 5274 },
                // arrivalCity: { name: 'Balneario', uf: 'SC', id: 4443 },
                // departDate: new Date(2019, 8, 15),
                // returnDate: new Date(2019, 8, 20),
                departCity: cityDeparture || {},
                arrivalCity: cityArrival || {},
                departDate: departDate,
                seats: "",
                note: "",
              }}
              validationSchema={formSchema}
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={searchTrip}
            >
              {({
                isValid,
                values,
                errors,
                status,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldError,
              }) => (
                <form onSubmit={handleSubmit}>
                  <AutoComplete
                    name="departCity"
                    label="Local de partida"
                    placeholder="Ex.: São Paulo"
                    value={cityDeparture}
                    suggestions={suggestions}
                    getSuggestionValue={getSuggestionValue}
                    onBlur={(event) => {
                      handleBlur(event);
                      handleSuggestionsClearRequested();
                    }}
                    onSuggestionChanged={(fieldName, suggestion) => {
                      setFieldValue(fieldName, suggestion, false);
                    }}
                    onSuggestionsFetch={handleSuggestionsFetchRequested}
                    onSuggestionsClear={handleSuggestionsClearRequested}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon edge="end">room</Icon>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(errors.departCity && errors.departCity.id)}
                    helperText={
                      errors.departCity && errors.departCity.id
                        ? errors.departCity.id
                        : null
                    }
                  />

                  <AutoComplete
                    name="arrivalCity"
                    label="Local de destino"
                    placeholder="Ex.: Curitiba"
                    value={cityArrival}
                    suggestions={suggestions}
                    getSuggestionValue={getSuggestionValue}
                    onBlur={(event, ...other) => {
                      // console.log('event, other', event, other);
                      handleBlur(event);
                      handleSuggestionsClearRequested();
                    }}
                    onSuggestionChanged={(fieldName, suggestion) => {
                      setFieldValue(fieldName, suggestion, false);
                    }}
                    onSuggestionsFetch={handleSuggestionsFetchRequested}
                    onSuggestionsClear={handleSuggestionsClearRequested}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon edge="end">room</Icon>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(errors.arrivalCity && errors.arrivalCity.id)}
                    helperText={
                      errors.arrivalCity && errors.arrivalCity.id
                        ? errors.arrivalCity.id
                        : null
                    }
                  />

                  <Field
                    name="departDate"
                    component={DatePickerField}
                    disablePast
                    label="Data de Ida"
                    fullWidth
                    onBlur={handleBlur}
                    onError={(fieldName, error, _value) =>
                      setFieldError(fieldName, error, false)
                    }
                    margin="dense"
                    invalidDateMessage="Data inválida"
                    minDateMessage="Data inválida"
                    autoOk
                    maxDateMessage="Data inválida"
                    error={Boolean(errors.departDate && touched.departDate)}
                    helperText={
                      errors.departDate && touched.departDate
                        ? errors.departDate
                        : null
                    }
                  />

                  <TextField
                    fullWidth
                    autoFocus={true}
                    type="number"
                    name="seats"
                    value={values.seats}
                    label="Quantidade de Passageiros"
                    margin="none"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.seats && errors.seats)}
                    helperText={
                      touched.seats && errors.seats ? errors.seats : null
                    }
                  />

                  <TextField
                    fullWidth
                    name="note"
                    multiline
                    rowsMax={4}
                    value={values.note}
                    label="Observação"
                    margin="dense"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.note && errors.note)}
                    helperText={
                      touched.note && errors.note ? errors.note : null
                    }
                  />

                  <Typography variant="caption">
                    Sua sugestão é sempre bem vinda, iremos analisa-la e
                    informaremos caso a viagem seja liberada.
                  </Typography>

                  <Box
                    display="flex"
                    flexDirection="row-reverse"
                    alignItems="flex-end"
                    justifyContent="space-between"
                    paddingY={2}
                  >
                    <Button
                      type="submit"
                      variant="outlined"
                      color="primary"
                      //disabled={!isValid || isSubmitting}
                    >
                      Enviar
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </div>
        </DialogContent>
      </Dialog>
    </LoadingOverlay>
  );
}

const mapStateToProps = store => ({
  user: store.auth.user,
  cityDeparture: store.searchTripState.cityDeparture,
  cityArrival: store.searchTripState.cityArrival,
  departDate: store.searchTripState.departDate,
  returnDate: store.searchTripState.returnDate,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      enqueueSnackbar: enqueueSnackbar,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SuggestTrip);
