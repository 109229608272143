import ReactGA from 'react-ga';

const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_CODE;

const googleAnalytics = (pathname, event,transaction) => { 
    

    if (pathname !== "")
    {
        ReactGA.initialize(trackingId,{ standardImplementation: true });
        ReactGA.pageview(pathname);
    }

    if (event !== "")
    {
        ReactGA.initialize(trackingId,{ standardImplementation: true });
        ReactGA.event(event);
    }

    if (transaction !== "") {
        ReactGA.ga('create', trackingId, 'auto');
        ReactGA.ga('require', 'ecommerce');
        ReactGA.ga('ecommerce:addTransaction', {
            'id': transaction.transactionId,
            'revenue': transaction.value,
            'currency': 'BRL'
        });
        ReactGA.ga('ecommerce:send');
        ReactGA.ga('ecommerce:clear');
        console.log(transaction);
    }
}

export default googleAnalytics;