import CustomerApiService from '../services/CustomerApiService';
import { enqueueSnackbar } from '.';

const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
const GET_CUSTOMER_ERROR = 'GET_CUSTOMER_ERROR';

const SAVE_CUSTOMER_SUCCESS = 'SAVE_CUSTOMER_SUCCESS';
const SAVE_CUSTOMER_ERROR = 'SAVE_CUSTOMER_ERROR';

const SET_IS_NATURAL_PERSON = 'SET_IS_NATURAL_PERSON';

const getCustomer = () => {
  return dispatch => {
    CustomerApiService.getCustomer().then(
      data => {
        // console.log(data);
        setTimeout(() => {
          dispatch(getCustomerSuccess(data.data));
        }, 250);
      },
      reason => {
        dispatch(getCustomerError(reason));
      },
    );
  };
};

const getCustomerSuccess = data => ({
  type: GET_CUSTOMER_SUCCESS,
  data,
});

const getCustomerError = error => ({
  type: GET_CUSTOMER_ERROR,
  error,
});

const setIsNaturalPerson = isNaturalPerson => ({
  type: SET_IS_NATURAL_PERSON,
  isNaturalPerson,
});

const saveCustomer = (isNaturalPerson, customer, user, onFinish) => {
  return dispatch => {
    CustomerApiService.saveCustomer(isNaturalPerson, customer, user)
      .then(
        data => {
          dispatch(saveCustomerSuccess(data));
          dispatch(
            enqueueSnackbar({
              message: `Cadastro ${
                !!customer.id ? 'atualizado' : 'realizado'
              } com sucesso!`,
              options: {
                key: 'saveCustomerSnack',
                autoHideDuration: 3000,
                variant: 'success',
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
              },
            }),
          );
        },
        reason => {
          // console.log('reason', reason);
          dispatch(saveCustomerError(reason));
          dispatch(
            enqueueSnackbar({
              message: `Ocorreu um erro ao realizar a operação`,
              options: {
                key: 'saveCustomerSnack',
                autoHideDuration: 3000,
                variant: 'error',
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
              },
            }),
          );
        },
      )
      .finally(() => {
        onFinish && onFinish();
      });
  };
};

const saveCustomerSuccess = data => ({
  type: SAVE_CUSTOMER_SUCCESS,
  data,
});

const saveCustomerError = error => ({
  type: SAVE_CUSTOMER_ERROR,
  error,
});

const types = {
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_ERROR,
  SAVE_CUSTOMER_SUCCESS,
  SAVE_CUSTOMER_ERROR,
  SET_IS_NATURAL_PERSON
};

const customerActions = { types, getCustomer, saveCustomer, setIsNaturalPerson };

export default customerActions;
