import {
  GET_USER_LAST_SEARCHES,
  USER_LAST_SEARCHES_ERROR,
  USER_LAST_SEARCHES_SUCCESS
} from "../actions/actionTypes";

const initialState = {
  trips: [],
  isLoading: false,
  isEmpty: null,
  error: null
};

const userLastSearchesReducer = (state = initialState, action) => {
  // console.log("action", action.type, action);
  switch (action.type) {
    case GET_USER_LAST_SEARCHES: {
      // console.log("action GET_USER_LAST_SEARCHES", action);
      return {
        ...state,
        isLoading: true,
        trips: [],
        isEmpty: null
      };
    }
    case USER_LAST_SEARCHES_SUCCESS: {
      // console.log("action USER_LAST_SEARCHES_SUCCESS", action);
      return {
        ...state,
        isLoading: false,
        trips: action.trips,
        isEmpty: action.trips === null || action.trips.length === 0
      };
    }
    case USER_LAST_SEARCHES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: state.error,
        trips: []
      };
    default:
      return state;
  }
};

export default userLastSearchesReducer;
