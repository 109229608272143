import React, { useEffect } from 'react'
import { Box, Container, makeStyles } from '@material-ui/core'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { searchForSaleTrips } from '../../actions'
import ForSaleCard from './ForSaleCard'

const styles = makeStyles((theme) => ({
  tripSearchCards: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(2),
  },
  tripSearchCardsTitle: {
    width: '100%',
    marginBottom: theme.spacing(2),
  }
}))

function ForSaleTrips(props) {
  const { searchForSaleTrips, forSaleTrips } = props
  const {trips: saleTrips, isEmpty } = forSaleTrips

  const classes = styles()

  useEffect(() => {
    searchForSaleTrips()
  }, [])

  if (Boolean(isEmpty)) {
    return null
  }

  return (
    <>
      <Box marginBottom={4}>
        <Container className={classes.tripSearchCards}>
          <Box className={classes.tripSearchCardsTitle}>
            <h1>Promoções</h1>
          </Box>
          <>
            {saleTrips.map(trip => (
                <ForSaleCard trip={trip} />
            ))}
          </>
        </Container>
      </Box>
    </>
  )
}

const mapStateToProps = (store) => ({
  forSaleTrips: store.forSaleTrips
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      searchForSaleTrips: searchForSaleTrips
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ForSaleTrips)