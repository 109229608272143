import "date-fns";

import * as Yup from "yup";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import Actions, { authSignInSuccess, enqueueSnackbar } from "../../../actions";
import {
  Box,
  Button,
  Icon,
  InputAdornment,
  TextField,
  makeStyles,
} from "@material-ui/core";
import {
  faAddressCard,
  faCity,
  faIdCardAlt,
} from "@fortawesome/free-solid-svg-icons";

import AccountCircle from "@material-ui/icons/AccountCircle";
import AppInputMask from "../../app-input-mask/AppInputMask";
import AuthApiService from "../../../services/AuthApiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, Field } from "formik";
import React from "react";
import { bindActionCreators } from "redux";
import clsx from "clsx";
import { connect } from "react-redux";
import { green } from "@material-ui/core/colors";
import MaskedInput from "react-text-mask";

const styles = makeStyles((theme) => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  buttonSpacer: {
    marginTop: theme.spacing(1),
  },
  loadingButtonWrapper: {
    position: "relative",
    width: "100%",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  cellphoneTextfield: {
    paddingRight: theme.spacing(2),
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textField: {
    flex: "0 1 calc(50%)",
    "&:first-child": {
      flex: "0 1 calc(50% - 16px)",
      marginRight: theme.spacing(2),
    },
  },
  textFieldFull: {
    flex: "0 1 100%",
  },
  form: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
}));
const CellPhoneMask = (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,

        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      //   showMask
      keepCharPositions={true}
    />
  );
};

function LegalPersonForm(props) {
  const { saveCustomer, refreshUser, customer, user, onSave } = props;
  // console.log('props', props);
  const classes = styles();
  const isMobile = useMediaQuery("(max-width:768px)");
  const formSchema = Yup.object().shape({
    cnpj: Yup.string().required("Por favor digite seu CNPJ"),
    socialName: Yup.string().required("Por favor digite a Razão Social"),
    address: Yup.string().required("Por favor digite seu Endereço"),
    contactName: Yup.string().required(
      "Por favor digite o Nome do Responsável"
    ),
    cellphone: Yup.string()
      .required("Por favor digite seu Celular")
      .trim()
      .min(15, "Celular Inválido"),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    const submitValues = { ...customer, ...values };
    // console.log('handleSubmit -> values, submitValues', values, submitValues);
    saveCustomer(true, submitValues, user, async () => {
      setSubmitting(false);

      const newUser = (await AuthApiService.getUser()).data;
      refreshUser(newUser);

      onSave && onSave();
    });
  };

  return (
    <Box>
      <Formik
        initialValues={{
          cnpj: (customer && customer.cnpj) || "",
          socialName: (customer && customer.socialName) || "",
          address: (customer && customer.address) || "",
          contactName: (customer && customer.contactName) || "",
          cellphone: (customer && customer.cellphone) || "",
          // cellphone: (customer && customer.cellphone) || "11988856996",
        }}
        validationSchema={formSchema}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          isValid,
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} className={clsx(classes.form)}>
          
              <AppInputMask
                mask="99.999.999/9999-99"
                value={values.cnpj}
                onChange={handleChange}
                onBlur={handleBlur}
                name="cnpj"
                label="CNPJ"
                autoFocus={true}
                className={isMobile ? clsx(classes.margin, classes.textFieldFull) : clsx(classes.margin, classes.textField)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <FontAwesomeIcon icon={faIdCardAlt} />
                    </InputAdornment>
                  ),
                  autoFocus: true,
                }}
                error={errors.cnpj && touched.cnpj}
                helperText={errors.cnpj && touched.cnpj ? errors.cnpj : null}
              />
          

              <TextField
                name="socialName"
                className={isMobile ? clsx(classes.margin, classes.textFieldFull) : clsx(classes.margin, classes.textField)}
                margin="dense"
                variant="standard"
                type="text"
                label="Razão Social"
                value={values.socialName}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <FontAwesomeIcon icon={faCity} />
                    </InputAdornment>
                  ),
                }}
                error={errors.socialName && touched.socialName}
                helperText={
                  errors.socialName && touched.socialName
                    ? errors.socialName
                    : null
                }
              />

              <Field
                name="cellphone"
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="cellphone"
                    className={isMobile ? clsx(classes.margin, classes.textFieldFull) : clsx(classes.margin, classes.textField, classes.cellphoneTextfield)}
                    variant="standard"
                    type="text"
                    label="Celular"
                    value={values.cellphone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      inputComponent: CellPhoneMask,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon style={{ fontSize: 18 }}>contact_phone</Icon>
                        </InputAdornment>
                      ),
                      inputProps: {
                        autoComplete: "off",
                        form: {
                          autoComplete: "off",
                        },
                      },
                    }}
                    error={errors.cellphone && touched.cellphone}
                    helperText={
                      errors.cellphone && touched.cellphone
                        ? errors.cellphone
                        : null
                    }
                  />
                )}
              />

              <TextField
                name="contactName"
                className={isMobile ? clsx(classes.margin, classes.textFieldFull) : clsx(classes.margin, classes.textField)}
                variant="standard"
                type="text"
                label={isMobile ? "Responsável" : "Nome do Responsável"}
                value={values.contactName}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                error={errors.contactName && touched.contactName}
                helperText={
                  errors.contactName && touched.contactName
                    ? errors.contactName
                    : null
                }
              />

            <TextField
              name="address"
              className={clsx(classes.margin, classes.textFieldFull)}
              margin="dense"
              variant="standard"
              type="text"
              label="Endereço"
              value={values.address}
              onChange={handleChange}
              onBlur={handleBlur}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FontAwesomeIcon icon={faAddressCard} />
                  </InputAdornment>
                ),
              }}
              error={errors.address && touched.address}
              helperText={
                errors.address && touched.address ? errors.address : null
              }
            />

            <Box
              display="flex"
              flexDirection="row-reverse"
              alignItems="flex-end"
              justifyContent="space-between"
              paddingY={2}
              flex="1 1 100%"
            >
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                disabled={!isValid || isSubmitting}
              >
                Salvar
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
}

const mapStateToProps = (store) => ({
  user: store.auth.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveCustomer: Actions.customer.saveCustomer,
      refreshUser: authSignInSuccess,
      enqueueSnackbar: enqueueSnackbar,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LegalPersonForm);
