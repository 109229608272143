import AuthRepository from "../repositories/AuthRepository";

export const AUTH_FORGOT_PASSWORD_SUCCESS = "AUTH_FORGOT_PASSWORD_SUCCESS";
export const AUTH_FORGOT_PASSWORD_ERROR = "AUTH_FORGOT_PASSWORD_ERROR";



const authForgotPassword = (email, onFinish) => {
  return dispatch => {
    AuthRepository.sendPasswordReset(email)
      .then(
        data => {
          dispatch(authForgotPasswordSuccess(data));
        },
        reason => {
          dispatch(authForgotPasswordError(reason));
        }
      )
      .finally(() => {
        onFinish && onFinish();
      });
  };
};

const authForgotPasswordSuccess = data => ({
  type: AUTH_FORGOT_PASSWORD_SUCCESS,
  data
});

const authForgotPasswordError = error => ({
  type: AUTH_FORGOT_PASSWORD_ERROR,
  error
});

const authActions = { authForgotPassword };

export default authActions;
