import Axios from "axios";
import STORAGE from "../constants/storage";

export default class AuthApiService {
  static getUser(accessToken) {
    const url = `${process.env.REACT_APP_API_BASE_URL}/user`;
    return Axios.get(
      url,
      Boolean(accessToken)
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : {}
    ).then((res) => {
      localStorage.setItem(STORAGE.USER, JSON.stringify(res.data));

      return res;
    });
  }

  static verifyEmail(email) {
    const url = `${process.env.REACT_APP_API_BASE_URL}/user/${email}/2`;
    const token = localStorage.getItem("ACCESS_TOKEN");
    return Axios.get(url, { headers: { Authorization: token } });
  }
  static getFullUser() {
    const url = `${process.env.REACT_APP_API_BASE_URL}/user`;
    const token = localStorage.getItem("ACCESS_TOKEN");
    return Axios.get(url, { headers: { Authorization: token } });
  }

  static createAccount(userName, name, uidFirebase, cellphone) {
    const url = `${process.env.REACT_APP_API_BASE_URL}/account`;

    return Axios.post(url, {
      userName,
      cellphone,
      name,
      type: 2,
      uidFirebase,
    });
  }

  static createUser(name, userName) {
    const url = `${process.env.REACT_APP_API_BASE_URL}/user`;

    return Axios.post(url, {
      name,
      userName,
      type: 2,
    });
  }
}
