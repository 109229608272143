import Actions, { enqueueSnackbar } from '../../actions';
import { FormControlLabel, Switch, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';

import LegalPersonForm from './legal-person-form/LegalPersonForm';
import NaturalPersonForm from './natural-person-form/NaturalPersonForm';
import Skeleton from '@material-ui/lab/Skeleton';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

function MyAccountHolder(props) {
  const { customer, getCustomer, isNaturalPerson, setIsNaturalPerson } = props;
  const [personTypeChecked, setPersonTypeChecked] = React.useState(
    Boolean(isNaturalPerson),
  );
  const [reload, setReload] = React.useState(false);

  const toggleIsNaturalPerson = () => {
    setPersonTypeChecked(prev => {
      setIsNaturalPerson(!isNaturalPerson);
      return !prev;
    });
  };

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      // console.log('mounted');
      getCustomer();
    }

    // console.log('out');

    return () => {
      // console.log('cleanup');
      unmounted = true;
    };
  }, [getCustomer, reload]);

  // console.log('render', isNaturalPerson);

  const onSave = () => {
    setReload(prev => !prev);
  };

  return (
    <>
      {/* <LoadingOverlay spinner active={customer === null}> */}

      <div>
        <Typography variant="h4">Minha conta</Typography>

        {customer === null && (
          <Skeleton variant="rect" height={250} style={{ padding: 10 }}>
            <Skeleton width="60%" />
            <Skeleton width="60%" />
            <Skeleton width="80%" />
          </Skeleton>
        )}
        {Boolean(customer) && (
          <>
            <FormControlLabel
              control={
                <Switch
                  checked={personTypeChecked}
                  onChange={toggleIsNaturalPerson}
                />
              }
              label={isNaturalPerson ? 'Pessoa Física' : 'Pessoa Jurídica'}
            />
            {isNaturalPerson && (
              <NaturalPersonForm customer={customer} onSave={onSave} />
            )}
            {!isNaturalPerson && (
              <LegalPersonForm customer={customer} onSave={onSave} />
            )}
          </>
        )}
      </div>
      {/* </LoadingOverlay> */}
    </>
  );
}

const mapStateToProps = store => ({
  customer: store.customer.customer,
  isNaturalPerson: store.customer.isNaturalPerson,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCustomer: Actions.customer.getCustomer,
      enqueueSnackbar: enqueueSnackbar,
      setIsNaturalPerson: Actions.customer.setIsNaturalPerson,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyAccountHolder);
