import * as Yup from 'yup';

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Icon,
  InputAdornment,
  Link,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { authSignUp, enqueueSnackbar } from '../../actions';

import AccountCircle from '@material-ui/icons/AccountCircle';
import { Formik } from 'formik';
import IconButton from '@material-ui/core/IconButton';
import LoadingOverlay from 'react-loading-overlay';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { bindActionCreators } from 'redux';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/styles';

const styles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  titleRoot: {
    margin: 0,
    padding: theme.spacing(2),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  buttonSpacer: {
    marginTop: theme.spacing(1),
  },
  loadingButtonWrapper: {
    position: 'relative',
    width: '100%',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexFlow: 'column',
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textField: {
    flexBasis: 200,
  },
}));

const GreenCheckbox = withStyles({
  root: {
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const DialogTitle = props => {
  const classes = styles();
  const { children, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.titleRoot}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Icon>close</Icon>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

function SignUp(props) {
  const { open, handleClose, error, signUpAction, enqueueSnackbar } = props;
  const classes = styles();
  const [showPassword, setShowPassword] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  if (!!error) {
    let errorMessage = 'Erro desconhecido';
    let autoHideDuration = 4000;

    // console.log("SignUp error", error);

    if (error.code === 'auth/email-already-in-use') {
      errorMessage = 'Esse email já está em uso';
    }

    enqueueSnackbar({
      message: errorMessage,
      options: {
        key: 'signUpSnack',
        autoHideDuration: autoHideDuration,
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      },
    });
  }

  const signupSchema = Yup.object().shape({
    name: Yup.string().required('Por favor digite seu nome'),
    email: Yup.string()
      .email('Email inválido')
      .required('Por favor digite seu email'),
    password: Yup.string()
      .min(6, 'Senha muito fraca')
      .required('Por favor digite uma senha'),
    passwordConfirm: Yup.string()
      .min(6, 'Senha muito fraca')
      .oneOf([Yup.ref('password')], 'As senhas não conferem')
      .required('Por favor digite novamente sua senha'),
    acceptTerms: Yup.boolean().oneOf(
      [true],
      'É necessário aceitar os Termos de Uso e Privacidade',
    ),
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setIsLoading(true);
    signUpAction(values.name, values.email, values.password, () => {
      setSubmitting(false);
      setIsLoading(false);
    });
  };

  return (
    <LoadingOverlay spinner active={isLoading}>
      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle onClose={handleClose}>Crie sua conta</DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <Formik
              initialValues={{
                name: '',
                email: '',
                password: '',
                passwordConfirm: '',
                acceptTerms: false,
                // name: 'Leandro Barral',
                // email: 'barral@ikecode.com.br',
                // password: 'l34ndr0',
                // passwordConfirm: 'l34ndr0',
              }}
              validationSchema={signupSchema}
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={handleSubmit}
            >
              {({
                isValid,
                values,
                errors,
                status,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    id="name"
                    autoFocus={true}
                    className={clsx(classes.margin, classes.textField)}
                    margin="dense"
                    variant="standard"
                    type="text"
                    label="Nome"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {/* <IconButton> */}
                            <AccountCircle />
                          {/* </IconButton> */}
                        </InputAdornment>
                      ),
                      inputProps: {
                        autoComplete: 'off',
                        form: {
                          autoComplete: 'off',
                        },
                      },
                    }}
                    error={errors.name && touched.name}
                    helperText={
                      errors.name && touched.name ? errors.name : null
                    }
                  />

                  <TextField
                    fullWidth
                    id="email"
                    className={clsx(classes.margin, classes.textField)}
                    margin="dense"
                    variant="standard"
                    type="text"
                    label="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {/* <IconButton> */}
                            <Icon>email</Icon>
                          {/* </IconButton> */}
                        </InputAdornment>
                      ),
                      inputProps: {
                        autoComplete: 'off',
                        form: {
                          autoComplete: 'off',
                        },
                      },
                    }}
                    error={errors.email && touched.email}
                    helperText={
                      errors.email && touched.email ? errors.email : null
                    }
                  />

                  <TextField
                    fullWidth
                    id="password"
                    className={clsx(classes.margin, classes.textField)}
                    margin="dense"
                    variant="standard"
                    type={showPassword ? 'text' : 'password'}
                    label="Senha"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Box
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </Box>
                        </InputAdornment>
                      ),
                      inputProps: {
                        autoComplete: 'off',
                        form: {
                          autoComplete: 'off',
                        },
                      },
                    }}
                    error={errors.password && touched.password}
                    helperText={
                      errors.password && touched.password
                        ? errors.password
                        : null
                    }
                  />

                  <TextField
                    fullWidth
                    id="passwordConfirm"
                    className={clsx(classes.margin, classes.textField)}
                    margin="dense"
                    variant="standard"
                    type="password"
                    label="Confirme sua Senha"
                    value={values.passwordConfirm}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {(errors.passwordConfirm &&
                            touched.passwordConfirm) ||
                          !touched.passwordConfirm ? (
                            <LockOpen />
                          ) : (
                            <Lock />
                          )}
                        </InputAdornment>
                      ),
                      inputProps: {
                        autoComplete: 'new-password',
                        form: {
                          autoComplete: 'off',
                        },
                      },
                    }}
                    error={errors.passwordConfirm && touched.passwordConfirm}
                    helperText={
                      errors.passwordConfirm && touched.passwordConfirm
                        ? errors.passwordConfirm
                        : null
                    }
                  />

                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        name="acceptTerms"
                        checked={values.acceptTerms}
                        onChange={handleChange}
                        value={true}
                      />
                    }
                    label={
                      <>
                        <span>Declaro que li e aceito os</span>{' '}
                        <Link
                          href="https://4bus.page.link/politica-privacidade-termos-uso"
                          target="_blank"
                        >
                          Termos de Uso e Privacidade
                        </Link>
                      </>
                    }
                  />

                  <Box
                    display="flex"
                    flexDirection="row-reverse"
                    alignItems="flex-end"
                    justifyContent="space-between"
                    paddingY={2}
                  >
                    <Button
                      type="submit"
                      variant="outlined"
                      color="primary"
                      disabled={!isValid || isSubmitting}
                    >
                      Cadastrar
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </div>
        </DialogContent>
      </Dialog>
    </LoadingOverlay>
  );
}

const mapStateToProps = store => ({
  error: store.auth.signUpError,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      signUpAction: authSignUp,
      enqueueSnackbar: enqueueSnackbar,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignUp);
