import 'date-fns';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import React from 'react';
import ptBRLocale from 'date-fns/locale/pt-BR';

const AppDatePicker = props => {
  const { field, form, className, format, onChange, onError, value, ...other } = props;
  const [fieldValue, setFieldValue] = React.useState(value || null);
  const currentError = form.errors[field.name];

  // console.log('props', form, field);

  return (
    <MuiPickersUtilsProvider  utils={DateFnsUtils} locale={ptBRLocale}>
      <KeyboardDatePicker
        clearable
        cancelLabel="Cancelar"
        name={field.name}
        className="datePicker"
        value={fieldValue}
        format={format || 'dd/MM/yyyy'}
        onChange={date => {
          onChange && onChange(field.name, date);
          setFieldValue(date);
        }}
        onError={(error, value) => {
          // console.log('error, value', error, value);
          if (currentError !== error) {
            onError && onError(field.name, error, value);
          }
        }}
        maskChar="_"
        {...other}
        InputProps={{
          fullWidth: true,
          className:className,
          disableUnderline: true,
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default AppDatePicker;
