import React from 'react'
import { Box } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

const Card = (props) => {
    const { title,
        subtitle,
        tag,
        image,
        link} = props

    const imgUrl = Array.isArray(image) && typeof image[1] == "string" && image[1] ? image[1] : "https://blog.4bus.com.br/wp-content/uploads/2021/12/o-que-fazer-em-tocantins-para-incluir-na-sua-lista-de-desejos-o-que-fazer-em-tocantins-para-incluir-na-sua-lista-de-desejos.png"
    return (
        <Box style={{ paddingLeft: '8px' }} onClick={() => { window.location.replace(link) }} marginBottom={4}>

            <Box>

                <div className="blog-image" style={{
                    width: "100%",
                    height: 150,
                    backgroundColor: "#03b3d4",
                    overflow: 'hidden',
                    boxShadow: 'inset 0 0 0 1000px #23adc4'
                }}>
                    <img 
                        style={{
                            opacity: 0.3,
                            height: "150px",
                        }} src={imgUrl}></img>
                    </div>
                <div className="tag" style={{
                    padding: '10px 0px 10px 0px', color: '#03b3d4', textAlign: 'left', textTransform: 'uppercase', fontWeight: '400'
                }}>{tag}</div>
                <h3 style={{ textAlign: "left", float: 'left', textTransform: 'uppercase', fontWeight: "bolder" }}>{title}</h3>
                <p style={{ textAlign: 'left', float: 'left', fontSize: 13 }}>{subtitle.length > 120 ? `${subtitle.substring(0, 120)}...` : subtitle}</p>
            </Box>

        </Box>
    )
}
export default withRouter(Card)