import {
  CANCEL_TRIP_REQUEST,
  CANCEL_TRIP_SUCCESS,
  CANCEL_TRIP_ERROR,
} from "../actions/cancelTripAction";

const initialState = {
  loading: false,
  success: false,
  error: null,
};

const tripCancelReducer = (state = initialState, action) => {
  switch (action.type) {
    case CANCEL_TRIP_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };

    case CANCEL_TRIP_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
      };

    case CANCEL_TRIP_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default tripCancelReducer;
