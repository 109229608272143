import React, { memo, forwardRef } from "react";
import clsx from "clsx";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";

const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PaperComponent = (props) => {
  return <Paper {...props} />;
};

const styles = () => ({
  dialog: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

const CustomDialog = memo(
  ({
    title,
    open,
    setOpen,
    classes,
    width,
    children,
    color = "#02b3d4",
    paddingDialog = true,
    height = "auto",
    backdropclick = false,
  }) => {
    return (
      <Dialog
        PaperComponent={PaperComponent}
        disableBackdropClick={backdropclick}
        disableEscapeKeyDown={backdropclick}
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
        PaperProps={{
          elevation: 0,
          style: {
            minWidth: width,
            maxWidth: width,
            maxHeight: height,
            margin: 0,
          },
        }}
      >
        <DialogTitle
          disableTypography
          style={{ background: color }}
          className={clsx(classes.dialog)}
          id="draggable-dialog-title"
        >
          <Typography
            variant={"h6"}
            className={"font-700 text-white"}
            style={{ color: "#FFF", whiteSpace: "nowrap" }}
          >
            {title}
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent className={"p-20"}>{children}</DialogContent>
      </Dialog>
    );
  }
);

export default withStyles(styles)(CustomDialog);
