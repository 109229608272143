import {
  SET_BOOKING,
} from '../actions/checkoutActions';

import {
  INSTALLMENTS_DATA_SUCCESS,
  INSTALLMENTS_DATA_ERROR
} from '../actions/installmentsActions';

const initialState = {
  error: null,
  departTrip: null,
  returnTrip: null,
  passengers: [],
  coupon: null,
  paymentData: null,
  paymentSuccess: null,
  paymentError: null,
  booking:null,
  installments: []
};

const checkoutReducer = (state = initialState, action) => {

  switch (action.type) {
    case SET_BOOKING:{
      return{
        ...state,
        booking:action.payload
      }
    }
    
    case INSTALLMENTS_DATA_SUCCESS: {
      return {
      ...state,
        installments: action.data
      };
    }
    
    case INSTALLMENTS_DATA_ERROR: {
      return {
      ...state,
        error: "Error ao buscar os dados de parcelamento"
      };
    }

    default:
      return state;
  }
};

export default checkoutReducer;