export const CHECKOUT_SAVE_DEPART = 'CHECKOUT_SAVE_DEPART';
export const CHECKOUT_SAVE_RETURN = 'CHECKOUT_SAVE_RETURN';
export const CHECKOUT_SAVE_PASSENGERS = 'CHECKOUT_SAVE_PASSENGERS';
export const CHECKOUT_ORDER_PAYMENT = 'CHECKOUT_ORDER_PAYMENT';
export const CHECKOUT_GET_COUPON_SUCCESS = 'CHECKOUT_GET_COUPON_SUCCESS';
export const SET_BOOKING = "SET_BOOKING"

const saveDepart = trip => ({
  type: CHECKOUT_SAVE_DEPART,
  trip,
});

const saveReturn = trip => ({
  type: CHECKOUT_SAVE_RETURN,
  trip,
});

const savePassengers = passengers => ({
  type: CHECKOUT_SAVE_PASSENGERS,
  passengers,
});

const orderPayment = trip => ({
  type: CHECKOUT_ORDER_PAYMENT,
  trip,
});

const getCoupon = coupon => {
  return dispatch => {
    dispatch(getCouponSuccess({ coupon }));
  };
};

const getCouponSuccess = coupon => ({
  type: CHECKOUT_GET_COUPON_SUCCESS,
  coupon,
});

export const setBooking = booking => {
  console.log('ACTION SET BOOKING')
  return dispatch => {
    console.log('dispatch',dispatch)
    console.log('dispatch',dispatch)
    dispatch({
      type:SET_BOOKING,
      payload: booking

    })
  }
}
const checkoutActions = {
  saveDepart,
  saveReturn,
  savePassengers,
  orderPayment,
  getCoupon,
};
export default checkoutActions;
