import { Box, Container, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';

import MyAccountHolder from '../components/my-account-holder/MyAccountHolder';
import MyTrips from '../components/my-trips/MyTrips';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MyDiscount from '../components/my-discount/MyDiscount';
import PropTypes from 'prop-types';
import ROUTES from '../constants/routes';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { enqueueSnackbar } from '../actions';
import { makeStyles } from '@material-ui/styles';
import withAuth from '../hocs/withAuth';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import clsx from 'clsx';
import LoadingOverlay from 'react-loading-overlay';

const CustomTab = styled(Tab)`
  @media (max-width: 768px) {
    font-size: 0.775rem !important;
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const isMobile = useMediaQuery('(max-width:768px)');
  return (
    <Box
      style={{
        width: isMobile ? '100%' : '80%',
        padding: isMobile ? '10px' : '24px',
      }}
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {children}
  </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
    },
  },
  loading: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerMarginMobile:{
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  tabs: {
    width: '250px',
    marginTop: '30px',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  containerMargin:{
    marginTop: '140px',
  }
}));

function MyAccount(props) {
  const { history } = props;
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 768px)');
  let initialTab = 0;
  switch (history.location.pathname) {
    case ROUTES.MY_ACCOUNT:
      initialTab = 0;
      break;
    case ROUTES.MY_TRIPS:
      initialTab = 1;
      break;
    default:
      break;
  }

  const [value] = React.useState(initialTab);
  function handleChange(_event, newValue) {
    switch (newValue) {
      case 0:
        history.push(ROUTES.MY_ACCOUNT);
        break;
      case 1:
        history.push(ROUTES.MY_TRIPS);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      // console.log('mounted');
    }

    return () => {
      unmounted = true;
    };
  }, []);

  const loadingCancelTrip = useSelector((state) => state.cancelTrip);

  return (
    <div>
      <LoadingOverlay
        text="Aguarde em quanto cancelamos a sua reserva..."
        spinner
        active={loadingCancelTrip.loading}
        className={classes.loading}
      >
        <Container className={isMobile ? clsx(classes.containerMarginMobile) : clsx(classes.containerMargin)}>
          <Box component={Paper} className={clsx(classes.root)}>
            <Tabs
              orientation={isMobile ? 'horizontal' : 'vertical'}
              variant={isMobile ? 'fullWidth' : 'scrollable'}
              value={value}
              onChange={handleChange}
              className={classes.tabs}
            >
              <CustomTab label='Minha Conta' {...a11yProps(0)} />
              <CustomTab label='Viagens' {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <MyAccountHolder />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <MyTrips />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <MyDiscount />
            </TabPanel>
          </Box>
        </Container>
      </LoadingOverlay>
    </div>
  );
}

const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      enqueueSnackbar: enqueueSnackbar,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth(withRouter(MyAccount)));
