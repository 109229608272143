import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import StepConnector from '@material-ui/core/StepConnector';
import Typography from '@material-ui/core/Typography';
import { DirectionsBusOutlined, WhereToVoteOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import { storage } from '../../firebase';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '90%',
    padding: 0,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#02B3D4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#02B3D4',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      <div className={classes.circle} />
    </div>
  );
}
const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#02B3D4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#02B3D4',
    },
  },
  line: {
    display: 'none',
  },
})(StepConnector);

const BusIcon = withStyles({
  root: {
    color: '#02B3D4',
  },
})(DirectionsBusOutlined);
const ArrivalIcon = withStyles({
  root: {
    color: '#02B3D4',
  },
})(WhereToVoteOutlined);
export default function VerticalLinearStepper({
  trip,
  departure,
  arrival,
  tripsStop = [],
}) {


  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Stepper
        orientation='vertical'
        style={{ padding: 5 }}
        connector={<QontoConnector />}
      >
        <Step active={true}>
          <StepLabel StepIconComponent={BusIcon}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {departure && (
                <Typography>
                  <strong>{moment.utc(trip.etd).format('HH:mm')}</strong> -
                  {departure.city.name}, {departure.city.uf}
                </Typography>
              )}
              
            </div>
          </StepLabel>
          <StepContent>
            {departure && (
              <Typography>
                {departure.address} ({departure.name})
              </Typography>
            )}
            {/* <Typography>{getStepContent(departure)}</Typography> */}
          </StepContent>
        </Step>
        {tripsStop &&
          _.sortBy(tripsStop, ['etd']).map((stop, index) => (
            <Step active={true}>
              <StepLabel StepIconComponent={QontoStepIcon}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {stop && (
                    <Typography>
                      <strong>{moment.utc(stop.etd).format('HH:mm')}</strong> -{' '}
                      {stop.departure.city.name}, {stop.departure.city.uf}
                    </Typography>
                  )}
                  {stop.imgUrl && (
                    <img
                      src={stop.imgUrl}
                      style={{
                        width: 75,
                        height: 50,
                        borderRadius: 10,
                        marginLeft: 10,
                      }}
                      alt='Propaganda'
                    />
                  )}
                </div>
              </StepLabel>
              <StepContent>
                {stop && (
                  <Typography>
                    {stop.departure.address} ({stop.departure.name})
                  </Typography>
                )}
              </StepContent>
            </Step>
          ))}
        <Step active={true}>
          <StepLabel StepIconComponent={ArrivalIcon}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {arrival && (
                <Typography>
                  <strong>{moment.utc(trip.eta).format('HH:mm')}</strong> -{' '}
                  {arrival.city.name}, {arrival.city.uf}
                </Typography>
              )}
            
            </div>
          </StepLabel>
          <StepContent>
            {arrival && (
              <Typography>
                {arrival.address} ({arrival.name})
              </Typography>
            )}
            {/* <Typography>{getStepContent(departure)}</Typography> */}
          </StepContent>
        </Step>
        {/* {steps.map((label, index) => (
          <Step key={label} active={true}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography>{getStepContent(departure)}</Typography>
            </StepContent>
          </Step>
        ))} */}
      </Stepper>
    </div>
  );
}
