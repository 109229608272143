import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FrequentlyAskedQuestions from '../components/frequently-asked-questions';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop:42,
  },
  rootMobile:{
    margin: '0',
    padding: '0 !important',
  }
}));

function Help() {

  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <div className={isMobile ? clsx(classes.rootMobile) : clsx(classes.root)}>
      <FrequentlyAskedQuestions title="Perguntas frequentes" />
    </div> 
  );
}
export default Help;