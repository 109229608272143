import React from 'react';
import moment from 'moment';

export function TabLabel(props) {
  const { isGoing,oldDate,nextDate, date,showLabel, showDate = true } = props;
  // console.log('date', date);
  const stringDate = moment(date).format('L');
  return (
    <>
      <div>
        {oldDate ? (
          ''
        ) : (
          nextDate ? '' : (
            showLabel && <label>{isGoing ? 'Ida' : 'Volta'}</label>
          )
        )}
      


      </div>
      <div>
        {showDate && <span>{stringDate}</span>}
      </div>
    </>
  );
}
