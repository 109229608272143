import React, { useEffect, useState } from "react";
import { Box, Paper } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import BookingPanel from "../booking-panel/BookingPanel";
import PixInfo from "../../checkout/trip-summary/pix-infos/PixInfo";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import UserRepository from "../../../repositories/UserRepository";
import { makeStyles } from "@material-ui/core/styles";
import BookingPixPanel from "../booking-panel/BookingPixPanel";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
}));

function BookingPanels(props) {
  const { statuses, user } = props;
  const classes = useStyles();
  const [bookings, setBookings] = React.useState([]);
  const [bookingsFiltered, setBookingFiltered] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [reload, setReload] = React.useState(false);
  const [searchTherm, setSearchTherm] = React.useState("");
  const [useBookingFiltered, setUseBookingFiltered] = useState(false);

  const handleOnBookingCanceled = () => {
    setReload((prev) => !prev);
  };

  useEffect(() => {
    let unmounted = false;
    setLoading(true);
    if (user.type === 5) {
      UserRepository.getBookingsSeller(user.id, statuses).then((bookings) => {
        if (!unmounted) {
          setBookings(bookings);
          setBookingFiltered(bookings);
          setLoading(false);
        }
      });
    } else {
      UserRepository.getBookings(user.uidFirebase, statuses).then(
        (bookings) => {
          if (!unmounted) {
            setBookings(bookings);
            setLoading(false);
          }
        }
      );
    }

    return () => {
      unmounted = true;
    };
  }, [statuses, setBookings, user.uidFirebase, reload]);

  useEffect(() => {
    if (searchTherm.length >= 3) {
      let res = bookings.filter((o) =>
        o.user.name.toLowerCase().includes(searchTherm)
      );
      setBookingFiltered(res);
      setUseBookingFiltered(true);
    } else {
      setUseBookingFiltered(false);
    }
  }, [searchTherm]);

  const bookingCodePix = JSON.parse(sessionStorage.getItem("checkoutBookingResult"));

  return (
    <div className={classes.root}>
      {loading && (
        <Box component={Paper}>
          <Skeleton variant="rect" height={70} style={{ padding: 10 }}>
            <Skeleton width="55%" />
          </Skeleton>

          <Skeleton variant="rect" height={210} style={{ padding: 10 }}>
            <Skeleton width="45%" height={100} />
            <Skeleton width="75%" />
            <Skeleton width="80%" />
            <Skeleton width="65%" />
          </Skeleton>
        </Box>
      )}
      {!loading && bookings && bookings.length > 0 && (
        <TextField
          variant="standard"
          color="primary"
          margin="dense"
          placeholder="Pesquisar..."
          onChange={(e) => setSearchTherm(e.target.value)}
        />
      )}
      {!useBookingFiltered &&
        !loading &&
        bookings &&
        bookings.length > 0 &&
        bookings.map((booking, index) => {
          if (booking.statusId === 4) {
            return null;
          }
          if (bookingCodePix && bookingCodePix.booking.departBookingSegment.bookingCode == booking.bookingCode) {
             return (
               <div>
                 <BookingPixPanel
                    key={booking.bookingCode}
                    booking={booking}
                    expanded={index === 0}
                    onBookingCanceled={handleOnBookingCanceled}
                    setReload={setReload}
                  />
               </div>
             );
           }
            return (
              <div>
                <BookingPanel
                  key={booking.bookingCode}
                  booking={booking}
                  expanded={index === 0}
                  onBookingCanceled={handleOnBookingCanceled}
                  setReload={setReload}
                />
              </div>
            );
        })}
      {useBookingFiltered &&
        !loading &&
        bookings &&
        bookings.length > 0 &&
        bookings.map((booking, index) => {
          return (
            <div>
              <BookingPanel
                key={booking.bookingCode}
                booking={booking}
                expanded={index === 0}
                onBookingCanceled={handleOnBookingCanceled}
                setReload={setReload}
              />
            </div>
          );
        })}

      {!loading && bookings && bookings.length === 0 && (
        <Box component={Paper} textAlign="center" padding="5em">
          <Typography variant="h5">Nenhuma reserva encontrada</Typography>
        </Box>
      )}
    </div>
  );
}

export default BookingPanels;