import {
  FEATURED_TRIPS_ERROR,
  FEATURED_TRIPS_SUCCESS,
  GET_FEATURED_TRIPS
} from "../actions/actionTypes";

const initialState = {
  trips: [],
  isLoading: false,
  error: null,
  isEmpty: null
};

export const featuredTripsReducer = (state = initialState, action) => {
  // console.log("action", action.type, action);
  switch (action.type) {
    case GET_FEATURED_TRIPS: {
        // console.log("action GET_FEATURED_TRIPS", action);
      return {
        ...state,

        showResults: true,
        isLoading: true,

        trips: [],
        error: null,
        isEmpty: null
      };
    }
    case FEATURED_TRIPS_SUCCESS: {
        // console.log("action FEATURED_TRIPS_SUCCESS", action);
      return {
        ...state,
        isLoading: false,
        trips: action.trips,
        isEmpty: action.trips === null || action.trips.length === 0
      };
    }
    case FEATURED_TRIPS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: state.error
      };

    default:
      return state;
  }
};
