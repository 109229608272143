import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogContent,
  FormControlLabel,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import {
  authSignInCloseModal,
  authSignInEmailPassword,
  authSignInGoogle,
  enqueueSnackbar,
  removeSnackbar
} from '../../actions';

import "./Styles/SignIn.css"
import AccountCircle from '@material-ui/icons/AccountCircle';
import ERRORS from '../../constants/errors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ForgotPassword from '../forgot-password/ForgotPassword';
import Lock from '@material-ui/icons/Lock';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import SignUp from '../sign-up/SignUp';
import Typography from '@material-ui/core/Typography';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { styles } from './Styles/DialogTitle';
import ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';

const DialogTitle = props => {
  const classes = styles();
  const { children } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.titleRoot}>
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
};

function SignIn(props) {
  const {
    signInEmailPassword,
    signInGoogle,
    isLoading,
    error,
    isAuthModalOpen,
    closeModal,
    enqueueSnackbar,
    history,
    user
  } = props;
  const classes = styles();
  const [email, setEmail] = React.useState((user && user.email) || '');
  const [password, setPassword] = React.useState('');
  const [rememberMe, setRememberMe] = React.useState(true);
  const [isOpenSignUp, setIsOpenSignUp] = React.useState(false);
  const [isOpenForgotPassword, setIsOpenForgotPassword] = React.useState(false);
  const handleClose = () => {
    closeModal();
  };
  useEffect(() => {
    var unmounted = false;
    if (!!error) {
      let errorMessage = 'Erro desconhecido';
      let autoHideDuration = 4000;

      if (error.code === 'auth/wrong-password') {
        errorMessage = 'Credenciais inválidas, verifique seus dados';
      } else if (error.code === ERRORS.INVALID_USER_TYPE) {
        errorMessage = 'Tipo de usuário inválido';
        autoHideDuration = 3000;
      }

      enqueueSnackbar({
        message: errorMessage,
        options: {
          // style:{
          //   zIndex:9999
          // },
          key: 'signInSnack',
          autoHideDuration: autoHideDuration,
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          }
        }
      });
    }
    return () => {
      unmounted = true;
    };
  }, [error, enqueueSnackbar]);
  useEffect(()=>{
    if(user){
      history.push(ROUTES.HOME);
    }
  },[user,history])
  const handleSubmit = () => {
    if (!!email && email.length > 0 && !!password && password.length > 0) {
      signInEmailPassword(email, password, rememberMe);
    }
  };

  const handleGoogleSignIn = () => {
    signInGoogle();
  };

  const handleCreateAccount = () => {
    setIsOpenSignUp(true);
  };

  const handleSignUpCloseClick = () => {
    setIsOpenSignUp(false);
  };

  const handleForgotPasswordClick = () => {
    setIsOpenForgotPassword(true);
  };

  const handleForgotPasswordClose = () => {
    setIsOpenForgotPassword(false);
  };

  const handleRememberMe = event => {
    setRememberMe(event.target.checked);
  };

  useEffect(() => {
    let unmounted = false;

    if (!unmounted && !isAuthModalOpen) {

      setIsOpenSignUp(false);
      history.push(ROUTES.HOME);
    }

    return () => {
      unmounted = true;
    };
  }, [isAuthModalOpen, setIsOpenSignUp]);


  return (
    <div className='ComponentContainer'>
      <form onSubmit={handleSubmit} className="formResponsive">
        <DialogTitle onClose={handleClose}>Faça seu login</DialogTitle>
        <DialogContent>
          <div className='container'>
            <TextField
              autoFocus
              margin="dense"
              variant="standard"
              id="email"
              label="E-mail"
              type="email"
              value={email}
              onChange={event => setEmail(event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {/* <IconButton> */}
                    <AccountCircle />
                    {/* </IconButton> */}
                  </InputAdornment>
                )
              }}
              fullWidth
            />

            <TextField
              margin="dense"
              variant="standard"
              id="password"
              label="Senha"
              type="password"
              value={password}
              onChange={event => setPassword(event.target.value)}
              onKeyPress={e => {
                e.target.keyCode === 13 && handleSubmit();
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {/* <IconButton aria-label="senha"> */}
                    <Lock />
                    {/* </IconButton> */}
                  </InputAdornment>
                )
              }}
              fullWidth
            />
          </div>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            pt={1}
            pb={1}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={handleRememberMe}
                  value={rememberMe}
                  color="primary"
                />
              }
              label="Lembrar"
            />

            <Button
              onClick={handleForgotPasswordClick}
              type="button"
              variant="text"
            >
              Esqueci minha senha
            </Button>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            paddingBottom={2}
          >
            <div className={classes.loadingButtonWrapper}>
              <Button
                fullWidth
                onClick={handleSubmit}
                color="primary"
                variant="outlined"
                type="submit"
                disabled={isLoading}
              >
                Acessar minha conta
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>

            <Button
              fullWidth
              onClick={handleCreateAccount}
              color="primary"
              variant="outlined"
              type="button"
              className={classes.buttonSpacer}
            >
              Criar conta
            </Button>

            <Box p={2}>Ou</Box>

            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              aria-label="add"
              type="button"
              onClick={handleGoogleSignIn}
            >
              <FontAwesomeIcon icon={faGoogle} className={classes.leftIcon} />
              Entrar com Google
            </Button>
          </Box>
        </DialogContent>
      </form>

      <SignUp open={isOpenSignUp} handleClose={handleSignUpCloseClick} />
      <ForgotPassword
        open={isOpenForgotPassword}
        handleClose={handleForgotPasswordClose}
      />
    </div>
  );
}

const mapStateToProps = store => ({
  user: store.auth.user,
  isLoading: store.auth.isLoading,
  error: store.auth.error,
  isAuthModalOpen: store.auth.isModalOpen,
  forgotPasswordSuccess: store.auth.forgotPasswordSuccess,
  forgotPasswordError: store.auth.forgotPasswordError
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      signInEmailPassword: authSignInEmailPassword,
      signInGoogle: authSignInGoogle,
      closeModal: authSignInCloseModal,
      enqueueSnackbar: enqueueSnackbar,
      removeSnackbar: removeSnackbar
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SignIn)
);
