const RSS_URL = `https://blog.4bus.com.br/feed`;
export default class BlogRepository {
  static getFeed = () => {
    return fetch(RSS_URL)
    .then(response => response.text())
    .then(str => {
      
    
      return new window.DOMParser().parseFromString(str, "text/xml")
    })
    .then(data => data)
    .catch(err=>console.log(err))
  }
}
function parseXml(xml) {
  var dom = null;
  if (window.DOMParser) {
    try {
        dom = (new DOMParser()).parseFromString(xml, "text/xml");
    } catch (e) {
        dom = null;
    }
  } 
  return dom;
}
function extractJson() {
  var xml = '<?xml version="1.0" encoding="utf-8"?><string xmlns="http://tempuri.org/">[ {"x":"y"},{"a":"b"}]</string>';
  var json = JSON.parse(parseXml(xml).firstChild.textContent);
  console.log(json)
}
