import Axios from 'axios';
// import moment from 'moment';

export default class BookingApiService {
  static postBooking(
    tripDepartId,
    tripReturnId,
    passengers,
    paymentData,
    customerPaymentMethodId,
    //couponCode,
    //useCredits,
    userIdCustomer
  ) {
    let cardData = null;

    if (customerPaymentMethodId) {
      paymentData = null;
    } else {
      var numberPattern = /\d+/g;
      paymentData.cpf = paymentData.cpf.match(numberPattern).join('');

      const expirySplit = paymentData.expiry.split('/');

      //'KARLA SANJULIAO:4111111111111111:03:2021:566'
      const paymentDataParsed = `${paymentData.name}:${paymentData.number
        .match(numberPattern)
        .join('')}:${expirySplit[0]}:${expirySplit[1]}:${paymentData.cvc}`;

      cardData = {
        paymentData: paymentDataParsed,
        cpf: paymentData.cpf,
        cnpj: null,
      };
    }
    const couponStorage = JSON.parse(sessionStorage.getItem('COUPON'));
    const useCreditsStorage =
      sessionStorage.getItem('USE_CREDITS') === 'true' ? true : false;
    const passengerIds = passengers.map((passenger) => passenger.id);

    const body = {
      customerPaymentMethodId: customerPaymentMethodId,
      cardData: cardData,
      bookingData: {
        tripIdDepart: tripDepartId,
        tripIdReturn: tripReturnId,
        passengers: passengerIds,
        coupon: couponStorage !== null ? couponStorage.code : null,
        useCredits: useCreditsStorage,
      },
    };
    if (userIdCustomer) {
      body.userIdCustomer = userIdCustomer;
    }

    const url = `${process.env.REACT_APP_API_BASE_URL}/trip/booking`;
    console.log('postBooking', body);
    return Axios.post(url, body);
  }

  static postBookingV2(
    departTripId,
    returnTripId,
    seatsDetails,
    passengers,
    paymentData,
    customerPaymentMethodId,
    //couponCode,
    //useCredits,
    userIdCustomer
  ) {
    let cardData = null;

    if (customerPaymentMethodId) {
      paymentData = null;
    } else {
      var numberPattern = /\d+/g;
      paymentData.cpf = paymentData.cpf.match(numberPattern).join('');

      const expirySplit = paymentData.expiry.split('/');

      //'KARLA SANJULIAO:4111111111111111:03:2021:566'
      const paymentDataParsed = `${paymentData.name}:${paymentData.number
        .match(numberPattern)
        .join('')}:${expirySplit[0]}:${expirySplit[1]}:${paymentData.cvc}`;

      cardData = {
        paymentData: paymentDataParsed,
        cpf: paymentData.cpf,
        cnpj: null,
      };
    }
    const couponStorage = JSON.parse(sessionStorage.getItem('COUPON'));
    const useCreditsStorage =
      sessionStorage.getItem('USE_CREDITS') === 'true' ? true : false;
    const passengerIds = passengers.map((passenger) => passenger.id);

    const body = {
      customerPaymentMethodId: customerPaymentMethodId,
      cardData: cardData,
      bookingDataRequest: {
        departTripId,
        returnTripId,
        passengers: passengerIds,
        coupon: couponStorage !== null ? couponStorage.code : null,
        useCredits: useCreditsStorage,
        bookingSeats: seatsDetails,
      },
    };
    if (userIdCustomer) {
      body.userIdCustomer = userIdCustomer;
    }

    const url = `${process.env.REACT_APP_API_BASE_URL}/trip/bookingV2`;
    console.log('postBooking', body);
    return Axios.post(url, body);
  }
  static postBookingV3(
    departTripId,
    returnTripId,
    seatsDetails,
    passengers,
    paymentData,
    customerPaymentMethodId,
    //couponCode,
    //useCredits,
    userIdCustomer
  ) {
    var numberPattern = /\d+/g;
    let cardData = {
      paymentData: null,
      cpf: paymentData.cpf,
      cnpj: null,
    }
    var {
      street,
      cep: zipCode,
      neighborhood,
      city,
      state,
      addressNumber: number,
      saveCard,
      cpf,
      phone,
      installments,
      billingAddress,
      cardPaymentId=null,
      token
    } = paymentData
    if (customerPaymentMethodId) {
      paymentData = null;
      
    } else {
      phone = phone && phone !== '' ? phone.match(numberPattern).join('') : ''
      zipCode = zipCode && zipCode !== '' ? zipCode.match(numberPattern).join('') : ''
      cpf = cpf && cpf !== '' ? cpf.match(numberPattern).join('') : ''
      billingAddress = {
        state,
        city,
        street,
        number,
        zipCode,
        neighborhood
      }
      

      const expirySplit = paymentData.expiry.split('/');

      //'KARLA SANJULIAO:4111111111111111:03:2021:566'
      const paymentDataParsed = `${paymentData.name}:${paymentData.number
        .match(numberPattern)
        .join('')}:${expirySplit[0]}:${expirySplit[1]}:${paymentData.cvc}`;

      cardData = {
        paymentData: paymentDataParsed,
        cpf,
        cnpj: null,
      };
    }

    const couponStorage = JSON.parse(sessionStorage.getItem('COUPON'));
    const useCreditsStorage =
      sessionStorage.getItem('USE_CREDITS') === 'true' ? true : false;
    const passengerIds = passengers.map((passenger) => passenger.id);

    const body = {
      customerPaymentMethodId: customerPaymentMethodId,
      bookingDataRequest: {
        departTripId,
        returnTripId,
        passengers: passengerIds,
        coupon: couponStorage !== null ? couponStorage.code : null,
        useCredits: useCreditsStorage,
        bookingSeats: seatsDetails,
      },
      paymentData: {
        cashPayment: false,
        cardPaymentId: cardPaymentId,
        cardPaymentData: cardData.paymentData,
        installments,
        token,
        cpf,
        phone,
        saveCard,
        billingAddress
      },
    };
    if (userIdCustomer) {
      body.userIdCustomer = userIdCustomer;
    }

    const url = `${process.env.REACT_APP_API_BASE_URL}/trip/bookingV3`;
    console.log('postBooking', body);
    return Axios.post(url, body);
  }

  static postBookingV3PixPayment(
    departTripId,
    returnTripId,
    seatsDetails,
    passengers,
    paymentData,
    customerPaymentMethodId,
    userIdCustomer
  ) {

    const couponStorage = JSON.parse(sessionStorage.getItem('COUPON'));
    const useCreditsStorage =
      sessionStorage.getItem('USE_CREDITS') === 'true' ? true : false;
    const passengerIds = passengers.map((passenger) => passenger.id);

    const body = {
      customerPaymentMethodId: customerPaymentMethodId,
      bookingDataRequest: {
        departTripId,
        returnTripId,
        passengers: passengerIds,
        coupon: couponStorage !== null ? couponStorage.code : null,
        useCredits: useCreditsStorage,
        bookingSeats: seatsDetails,
      },
      paymentData: {
        paymentType: "pix",
        cashPayment: false,
        installments: 1,
        saveCard: false,
      },
    };
    if (userIdCustomer) {
      body.userIdCustomer = userIdCustomer;
    }

    const url = `${process.env.REACT_APP_API_BASE_URL}/trip/bookingV3`;
    console.log('postBooking', body);
    return Axios.post(url, body);
  }

  static postBookingMoney(
    tripDepartId,
    tripReturnId,
    passengers,
    // useCredits,
    // couponCode,
    userIdCustomer
  ) {
    const couponStorage = JSON.parse(sessionStorage.getItem('COUPON'));
    const useCreditsStorage =
      sessionStorage.getItem('USE_CREDITS') === 'true' ? true : false;
    const passengerIds = passengers.map((passenger) => passenger.id);
    const body = {
      customerPaymentMethodId: null,
      cardData: null,
      cashPayment: true,
      bookingData: {
        tripIdDepart: tripDepartId,
        tripIdReturn: tripReturnId,
        passengers: passengerIds,
        coupon: couponStorage !== null ? couponStorage.code : null,
        useCredits: useCreditsStorage,
      },
      userIdCustomer,
    };
    const url = `${process.env.REACT_APP_API_BASE_URL}/trip/booking`;
    console.log('postBookingMoney', body);
    return Axios.post(url, body);
  }

  static validateCoupon = (code, tripIdDepart, tripIdReturn) => {
    // console.log(
    //   'validateCoupon (code, tripIdDepart, tripIdReturn)',
    //   code,
    //   tripIdDepart,
    //   tripIdReturn,
    // );
    const codeF = code.replace('#', '%23');
    const url = `${process.env.REACT_APP_API_BASE_URL}/coupon/check/${codeF}/${tripIdDepart || 0
      }/${tripIdReturn || 0}`;
    return Axios.get(url);
  };

  static setSeats = (seats) => {
    console.log('SET SETAS', seats);

    const body = {
      ...seats[0],
    };
    const url = `${process.env.REACT_APP_API_BASE_URL}/trip/booking/seats`;
    return Axios.post(url, seats);
  };

  static getPassengersSeats = (tripId) => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/trip/${tripId}/passengersSeats`;
    return Axios.get(url);
  };

  static refundTrip = (code) => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/booking/refund/${code}`;
    return Axios.patch(url);
  };
}
