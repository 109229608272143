import {
  Box,
  Button,
  Grid,
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useEffect } from "react";
import AppConfirmation from "../../shared/app-confirmation/AppConfirmation";
import PassengerApiService from "../../../services/PassengerApiService";
import PassengerForm from "../passenger-form/PassengerForm";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { enqueueSnackbar } from "../../../actions";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import styled from "styled-components";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    position: "relative",
  },
  title: {
    fontSize: "25px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: "block",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  divider: {
    height: theme.spacing(2),
  },
}));

const AddPassengerButton = styled(Button)`
  text-transform: none !important;
  @media only screen and (max-width: 768px) {
    margin: 16px 0 0 0 !important;
    padding: 2px 4px !important;
    line-height: 18px !important;
  }
`;

function PassengerSelection(props) {
  const { enqueueSnackbar, onPassengerSelected } = props;
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(
    false
  );
  const [deleteData, setDeleteData] = React.useState(null);
  const clientBySeller = JSON.parse(
    sessionStorage.getItem("clientSelectedBySeller")
  );
  // console.log('selectedPassengers', selectedPassengers);

  const isMobile = window.innerWidth < 768;

  const columnsObj = isMobile
    ? [
        {
          title: "",
          field: "id",
          render: (rowData) => (
            <Grid container display="flex" spacing={1}>
              <Grid item>
                <Tooltip
                  title={
                    rowData.selected
                      ? "Desfazer seleção"
                      : "Selecionar Passageiro"
                  }
                >
                  <IconButton
                    color={rowData.selected ? "primary" : "default"}
                    size="small"
                    onClick={() => {
                      setTableState((prevState) => {
                        const newData = prevState.data.map((data) => {
                          if (data.id === rowData.id) {
                            data.selected = !Boolean(data.selected);
                          }

                          return data;
                        });

                        // console.log(newData);

                        const selected = newData.filter((d) =>
                          Boolean(d.selected)
                        );
                        onPassengerSelected(selected);

                        sessionStorage.setItem(
                          "selectedPassengers",
                          JSON.stringify(selected)
                        );

                        return {
                          ...prevState,
                          data: newData,
                        };
                      });
                    }}
                  >
                    <Icon>
                      {rowData.selected ? (
                        <CheckBox />
                      ) : (
                        <CheckBoxOutlineBlank />
                      )}
                    </Icon>
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          ),
        },
        { title: "Nome", field: "name" },
      ]
    : [
        {
          title: "",
          field: "id",
          render: (rowData) => (
            <Grid container display="flex" spacing={1}>
              <Grid item>
                <Tooltip
                  title={
                    rowData.selected
                      ? "Desfazer seleção"
                      : "Selecionar Passageiro"
                  }
                >
                  <IconButton
                    color={rowData.selected ? "primary" : "default"}
                    size="small"
                    onClick={() => {
                      setTableState((prevState) => {
                        const newData = prevState.data.map((data) => {
                          if (data.id === rowData.id) {
                            data.selected = !Boolean(data.selected);
                          }

                          return data;
                        });

                        // console.log(newData);

                        const selected = newData.filter((d) =>
                          Boolean(d.selected)
                        );
                        onPassengerSelected(selected);

                        sessionStorage.setItem(
                          "selectedPassengers",
                          JSON.stringify(selected)
                        );

                        return {
                          ...prevState,
                          data: newData,
                        };
                      });
                    }}
                  >
                    <Icon>
                      {rowData.selected ? (
                        <CheckBox />
                      ) : (
                        <CheckBoxOutlineBlank />
                      )}
                    </Icon>
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          ),
        },
        { title: "Nome", field: "name" },
        {
          title: "Tipo do Documento",
          field: "idType",
          render: (rowData) => (
            <>
              {rowData.idType === "RG" && <>RG</>}
              {rowData.idType === "P" && <>Passaporte</>}
              {rowData.idType === "CN" && <>Certidão de Nascimento</>}
            </>
          ),
        },
        {
          title: "Documento",
          field: "idNumber",
        },
        {
          title: "CPF",
          field: "cpf",
        },
        {
          title: "Data de Nascimento",
          field: "birthdate",
          render: (rowData) => (
            <span>
              {moment(rowData.birthdate)
                .utcOffset(3)
                .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
                .format("DD/MM/YYYY")}
            </span>
          ),
        },
      ];

  const detailPanelObj = isMobile
    ? [
        {
          icon: () => (
            <span style={{ marginLeft: "14px" }}>
              <Visibility />
            </span>
          ),
          openIcon: () => (
            <span style={{ marginLeft: "14px" }}>
              <VisibilityOff />
            </span>
          ),
          tooltip: "Ver detalhes do passageiro",
          render: (rowData) => {
            return (
              <div style={{ background: "#efefef", padding: "4px" }}>
                <Paper style={{ margin: "4px", padding: "4px" }}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">
                          {rowData.idType === "RG" && <b>RG: </b>}
                          {rowData.idType === "P" && <b>Passaporte: </b>}
                          {rowData.idType === "CN" && (
                            <b>Certidão de Nascimento: </b>
                          )}
                          {rowData.idNumber}
                        </TableCell>
                      </TableRow>
                      {rowData.cpf && (
                        <TableRow>
                          <TableCell align="left">
                            <b>CPF: </b>
                            {rowData.cpf}
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell align="left">
                          <b>Data de Nascimento:</b>{" "}
                          {moment(rowData.birthdate)
                            .utcOffset(3)
                            .set({
                              hour: 12,
                              minute: 0,
                              second: 0,
                              millisecond: 0,
                            })
                            .format("DD/MM/YYYY")}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </div>
            );
          },
        },
      ]
    : null;

  const [tableState, setTableState] = React.useState({
    localization: {
      body: {
        emptyDataSourceMessage: "Nenhum passageiro encontrado",
      },
      toolbar: {
        searchTooltip: "Pequise por Nome, Documento ou CPF",
        searchPlaceholder: isMobile ? "Pequisar..." : "Pequisar passageiro",
        nRowsSelected: "{0} passageiro(s) selecionado(s)",
      },
      header: {
        actions: "Ações",
      },
      pagination: {
        labelRowsSelect: "itens",
        labelDisplayedRows: " {from}-{to} de {count}",
        firstTooltip: "Primeira página",
        previousTooltip: "Página anterior",
        nextTooltip: "Próxima página",
        lastTooltip: "Última página",
      },
    },
    options: {
      search: true,
      // searchFieldAlignment: 'left',
      // selection: true,
      // selectionProps: rowData => {
      //   return {
      //     checked: Boolean(rowData.newRow) || Boolean(rowData.checked),
      //   };
      // },
      maxBodyHeight: "45vh",
      paging: false,
      showSelectAllCheckbox: false,
      showTextRowsSelected: false,
      actionsColumnIndex: -1,
      padding: "dense",
    },
    components: {
      Toolbar: (props) => {
        // console.log('toolbar props', props);

        return (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box paddingLeft={2}>
              <AddPassengerButton
                variant="outlined"
                color="primary"
                onClick={(_event) => {
                  setIsModalOpen(true);
                }}
              >
                <Icon>add</Icon>
                Adicionar passageiro
              </AddPassengerButton>
            </Box>
            <Box>
              <MTableToolbar {...props} />
            </Box>
          </Box>
        );
      },
    },
    columns: columnsObj,
    actions: [
      (rowData) => ({
        icon: "delete",
        tooltip: "Excluir passageiro",
        onClick: (event, rowData) => {
          // console.log('delete', rowData);
          setDeleteData(rowData);
          setConfirmationModalOpen(true);
        },
        iconProps: {
          color: "secondary",
        },
      }),
    ],
  });

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      // console.log('mounted');
      const getPassengers = () => {
        setIsLoading(true);
        PassengerApiService.getListByCustomer(clientBySeller?.id).then(
          (res) => {
            setIsLoading(false);

            const selectedPassengers = JSON.parse(
              sessionStorage.getItem("selectedPassengers") || "[]"
            );

            const data = res.data.map((d) => {
              const alreadySelected = (
                selectedPassengers.find((s) => s.id === d.id) || {}
              ).selected;
              d.selected = Boolean(alreadySelected);
              return d;
            });

            setTableState((prevState) => ({ ...prevState, data }));

            const selected = data.filter((d) => Boolean(d.selected));
            onPassengerSelected(selected);
          }
        );
      };

      const getPassengersBySellerClient = async () => {
        setIsLoading(true);
        try {
          const { data } = await PassengerApiService.getListBySellerClient(
            clientBySeller?.id
          );

          const selectedPassengers = JSON.parse(
            sessionStorage.getItem("selectedPassengers") || "[]"
          );

          data.map((d) => {
            const alreadySelected = (
              selectedPassengers.find((s) => s.id === d.id) || {}
            ).selected;
            d.selected = Boolean(alreadySelected);
            return d;
          });

          setTableState((prevState) => ({ ...prevState, data }));

          const selected = data.filter((d) => Boolean(d.selected));
          onPassengerSelected(selected);
        } catch (error) {
        } finally {
          setIsLoading(false);
        }
      };

      if (clientBySeller?.id) {
        getPassengersBySellerClient();
      } else {
        getPassengers();
      }
    }

    // console.log('out');

    return () => {
      // console.log('cleanup');
      unmounted = true;
    };
  }, [setTableState, setIsLoading, onPassengerSelected]);

  const onSaveSuccess = (passenger) => {
    setIsModalOpen(false);
    passenger.selected = true;

    setTableState((prevState) => {
      const newData = [passenger, ...prevState.data];

      // console.log(newData);

      const selected = newData.filter((d) => Boolean(d.selected));
      onPassengerSelected(selected);

      sessionStorage.setItem("selectedPassengers", JSON.stringify(selected));

      return {
        ...prevState,
        data: newData,
      };
    });
  };

  return (
    <Box className={classes.root}>
      <h1 className={classes.title}>Selecione os passageiros</h1>
      <MaterialTable
        title={""}
        localization={tableState.localization}
        options={tableState.options}
        onSelectionChange={tableState.onSelectionChange}
        columns={tableState.columns}
        data={tableState.data}
        actions={tableState.actions}
        components={tableState.components}
        isLoading={isLoading}
        detailPanel={detailPanelObj}
      />

      {isModalOpen && (
        <PassengerForm
          isModalOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
          onSaveSuccess={onSaveSuccess}
        />
      )}

      <AppConfirmation
        title="Excluir passageiro"
        message={
          <>
            <Typography>Deseja realmente excluir esse Passageiro?</Typography>
          </>
        }
        open={confirmationModalOpen}
        handleClose={() => setConfirmationModalOpen(false)}
        actions={[
          {
            label: "Cancelar",
            onClick: () => {
              setConfirmationModalOpen(false);
            },
          },
          {
            label: "Confirmar",
            onClick: () => {
              PassengerApiService.delete(deleteData.id)
                .then(
                  (res) => {
                    if (res.status === 200) {
                      enqueueSnackbar({
                        message: "Passageiro removido com sucesso!",
                        options: {
                          key: "passengerFormSuccessSnack",
                          autoHideDuration: 2000,
                          variant: "success",
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                          },
                        },
                      });

                      setTableState((prevState) => {
                        const newData = prevState.data.filter(
                          (data) => data.id !== deleteData.id
                        );

                        const selected = newData.filter((d) =>
                          Boolean(d.selected)
                        );
                        onPassengerSelected(selected);

                        sessionStorage.setItem(
                          "selectedPassengers",
                          JSON.stringify(selected)
                        );

                        return {
                          ...prevState,
                          data: newData,
                        };
                      });
                    }
                  },
                  (error) => {
                    if (error.status === 400) {
                      enqueueSnackbar({
                        message:
                          "Passageiro com passagem ativa, imporssivel excluir.",
                        options: {
                          key: "passengerFormErrorSnack",
                          autoHideDuration: 3500,
                          variant: "error",
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                          },
                        },
                      });
                    }
                  }
                )
                .finally(() => {
                  setConfirmationModalOpen(false);
                  setDeleteData(null);
                });
            },
            buttonProps: {
              color: "primary",
            },
          },
        ]}
      />
    </Box>
  );
}

const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      enqueueSnackbar: enqueueSnackbar,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PassengerSelection);
