import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import StepperTrip from './StepperTrip';
import { storage } from '../../firebase';

import facebookPixel from '../../utils/facebookPixel';
import googleAnalytics from '../../utils/googleAnalytics';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function DetailTrip({ trip }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      googleAnalytics(
        '',
        {
          category: 'engagement',
          action: 'view_content',
          label: 'add_to_cart',
        },
        ''
      );
      facebookPixel('', {
        type: 'ViewContent',
        data: {
          content_name:
            trip.departure.city.name + ' > ' + trip.arrival.city.name,
          value: trip.ticketValue,
          currency: 'BRL',
        },
      });
    }
  };



  return (
    <div className={classes.root}>
      <ExpansionPanel
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <ExpansionPanelSummary
          //   expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography className={classes.heading}>
              {expanded === 'panel1' ? (
                <div
                  style={{
                    fontWeight: 'bold',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <ArrowBack style={{ fontSize: '17px' }} /> Ver menos
                </div>
              ) : (
                <div
                  style={{
                    fontWeight: 'bold',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <ArrowForward style={{ fontSize: '17px' }} /> Ver mais
                </div>
              )}
            </Typography>
            <Typography
              variant='caption'
              display='block'
              gutterBottom
              style={{
                color: trip.seatsAvailable <= 5 && 'red',
                fontWeight: 'bold',
              }}
            >
              {trip.seatsAvailable <= 10
                ? trip.seatsAvailable + ' vagas disponíveis'
                : trip.seatsAvailable >= 20
                ? 'Viagem com vagas disponíveis'
                : 'Viagem com poucas vagas'}
            </Typography>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <StepperTrip
            trip={trip}
            departure={trip.departure}
            arrival={trip.arrival}
            tripsStop={trip.tripsStop}
            imgsTripStops
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
