import { auth } from "../firebase";
import firebase from "firebase/app";

export default class AuthRepository {
  signInEmailPassword = (email, password, rememberMe) => {
    return firebase
      .auth()
      .setPersistence(
        rememberMe
          ? firebase.auth.Auth.Persistence.LOCAL
          : firebase.auth.Auth.Persistence.SESSION
      )
      .then(() => auth.signInWithEmailAndPassword(email, password));
  };

  signInGoogle = () => {
    return auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  };

  signUp = async (name, email, password) => {
    return auth
      .createUserWithEmailAndPassword(email, password)
      .then(async (res) => {
        await auth.currentUser.updateProfile({ displayName: name });
        return res;
      });
  };

  static sendPasswordReset = (email) => {
    return auth.sendPasswordResetEmail(email);
  };

  signOut = async () => {
    await auth.signOut();
    window.location.reload();
  };

  getCurrentUser = () => {
    return auth.currentUser;
  };
}
