import Axios from "axios";
import moment from "moment";

export default class CustomerApiService {
  static getCustomer() {
    const url = `${process.env.REACT_APP_API_BASE_URL}/customer`;
    return Axios.get(url);
  }

  static saveCustomer(isNaturalPerson, data, user) {
    data.birthdate = moment(new Date(data.birthdate)).valueOf();

    var numberPattern = /\d+/g;

    data.cellphone = data.cellphone.match(numberPattern).join("");

    if (isNaturalPerson) {
      data.cnpj = null;
      data.cpf = data.cpf.match(numberPattern).join("");
    } else {
      data.rg = null;
      data.cpf = null;
      data.cnpj = data.cnpj.match(numberPattern).join("");
    }

    if (data.id === undefined || data.id === null) {
      data.userId = user.id;
    }

    // console.log('data', data);
    const url = `${process.env.REACT_APP_API_BASE_URL}/customer`;
    return Axios.request({ url, method: !!data.id ? "PUT" : "POST", data });
  }

  static updateCellPhone({cellphone, userId}) {
    const url = `${process.env.REACT_APP_API_BASE_URL}/customer/cellphone`;
    const body = {
      cellphone,
    };
    if (userId) {
      body.userId = userId;
    }
    console.log("BODY -->", body);
    return Axios.put(url, body);
  }
}
