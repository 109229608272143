import React from 'react'
import {
    Box,
    Collapse,
    Container,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Skeleton from '@material-ui/lab/Skeleton';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useEffect } from 'react';
import FaqRepository from '../../repositories/FaqRepository';
import "./FrequentlyAskedQuestions.css"
export default function FrequentlyAskedQuestions(props) {
    const { title } = props
    const isMobile = useMediaQuery('(max-width:768px)');

    const [faqList, setFaqList] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    useEffect(() => {
        let unmounted = false;
        
        if (!unmounted) {
            setIsLoading(true);
            FaqRepository.getFaq().then(snapshot => {
                const faqs = snapshot.docs.map(doc => doc.data());
                setTimeout(() => {
                    setFaqList(faqs);
                    setIsLoading(false);
                }, 500);
            });
        }

        return () => {
            unmounted = true;
        };
    }, [setFaqList]);

    return (
        <Box id="frequentlyAskedQuestions" style={{ backgroundColor: "#f4f4f4" }}>
            <Container>
                <Box>
                    <h1>{title}</h1>
                </Box>

                {isLoading &&
                    [0, 1, 2].map(i => (
                        <Skeleton
                            variant="rect"
                            width="100%"
                            height={68}
                            style={{ marginBottom: 1 }}
                            key={Math.random() + Date.now}
                        />
                    ))}
                <Grid style={{paddingBottom:10}}>

                    <Table aria-label="collapsible table">
                        <TableBody>
                            {faqList.map((faq, index) => (<Row key={index} row={faq} />))}
                        </TableBody>
                    </Table>

                </Grid>
            </Container>
        </Box>
    )
}

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell style={{ borderBottom: 'none', paddingBottom: 0, paddingLeft: 0, paddingRight: 0 }}>
                    <Typography variant="h7" style={{ fontSize: 16, fontWeight: 700 }}>
                        {row.question}
                    </Typography>
                    <IconButton
                        style={{ float: "right" }}
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon style={{ color: "#02b3d4" }} /> : <KeyboardArrowDownIcon style={{ color: "#02b3d4" }} />}
                    </IconButton>

                </TableCell>


            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h8" gutterBottom component="div">
                                {row.answer}
                            </Typography>

                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}