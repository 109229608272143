import Axios from "axios";

export const CANCEL_TRIP_REQUEST = "CANCEL_TRIP_REQUEST";
export const CANCEL_TRIP_SUCCESS = "CANCEL_TRIP_SUCCESS";
export const CANCEL_TRIP_ERROR = "CANCEL_TRIP_ERROR";

const cancelTripRequest = (data) => ({
  type: CANCEL_TRIP_REQUEST,
  data,
});

const cancelTripSuccess = (data) => ({
  type: CANCEL_TRIP_SUCCESS,
  data,
});

const cancelTripError = (error) => ({
  type: CANCEL_TRIP_ERROR,
  error,
});

export const cancelTripAction = (bookingCode) => {
  return async (dispatch) => {
    dispatch(cancelTripRequest(bookingCode));
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/booking/cancel-refund/${bookingCode}`;
      const req = await Axios.patch(url);
        if(req){
            dispatch(cancelTripSuccess(req));
        }
      return true;
    } catch (error) {
      dispatch(cancelTripError(error));
      return false;
    }
  };
};