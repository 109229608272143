import { Box, Button, Paper, Typography } from "@material-ui/core";

import { FixedSizeList } from "react-window";
import { Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import PropTypes from "prop-types";
import ROUTES from "../../../constants/routes";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

function Row(props) {
  const { index, style, data } = props;
  //   console.log(props);
  const passenger = data[index];
  return (
    <ListItem button style={style} key={Math.random() + Date.now()}>
      <ListItemText
        primary={passenger.name}
        secondary={`${passenger.idType}: ${passenger.idNumber}`}
      />
    </ListItem>
  );
}

Row.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    // width: '100%',
    paddingTop: 20,
    height: "100%",
    // maxWidth: 358,
    backgroundColor: theme.palette.background.paper
  }
}));

const PassengersList = props => {
  const classes = useStyles();
  const selectedPassengers = JSON.parse(
    sessionStorage.getItem("selectedPassengers") || "[]"
  );

  console.log("selectedPassengers", selectedPassengers);
  return (
    <Box component={Paper} padding={2} width="100%">
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4">Passageiros</Typography>
        <Button
          component={Link}
          to={ROUTES.CHECKOUT}
          variant="outlined"
          size="small"
          color="primary"
        >
          Editar
        </Button>
      </Box>
      <div className={classes.root}>
        {/* {selectedPassengers.map(e => {
          {}
        })} */}
        {selectedPassengers.map(e => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              padding: 10,
              border: "1px solid #ccc",
              borderRadius: 10,
              marginTop: 10
            }}
          >
            <p style={{ fontWeight: "400" }}>
              <span style={{ fontWeight: "700" }}>Nome </span>
              {e.name}
            </p>
            <p style={{ fontWeight: "500" }}>
              {" "}
              <span style={{ fontWeight: "700" }}>
                {e.idType === "P" && "Passaporte : "}
                {e.idType === "CN" && "Certidão de Nascimento : "}
                {e.idType === "RG" && "RG : "}
              </span>
              {e.idNumber}
            </p>
          </div>
        ))}
        {/* <FixedSizeList
          height={100}
          width="auto"
          itemSize={46}
          itemCount={selectedPassengers.length}
          itemData={selectedPassengers}
        >
          {Row}
        </FixedSizeList> */}
      </div>
    </Box>
  );
};

PassengersList.propTypes = {};

export default PassengersList;
