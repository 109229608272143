import {
  AUTH_FORGOT_PASSWORD_ERROR,
  AUTH_FORGOT_PASSWORD_SUCCESS
} from "../actions/authActions";
import {
  AUTH_SIGN_IN_CLOSE_MODAL,
  AUTH_SIGN_IN_EMAIL_PASSWORD,
  AUTH_SIGN_IN_ERROR,
  AUTH_SIGN_IN_OPEN_MODAL,
  AUTH_SIGN_IN_SUCCESS,
  AUTH_SIGN_OUT,
  AUTH_SIGN_UP_ERROR
} from "../actions/actionTypes";

import STORAGE from "../constants/storage";
import { storage } from "../utils/storage";

import facebookPixel from '../utils/facebookPixel';
import googleAnalytics from '../utils/googleAnalytics';

const storageUser = storage.getItem(STORAGE.USER);
// console.log(storageUser);
const user = storageUser ? JSON.parse(storageUser) : null;

const initialState = {
  user,
  isLoading: false,
  rememberMe: false,
  error: null,
  isModalOpen: false,

  signUpError: null,

  forgotPasswordSuccess: null,
  forgotPasswordError: null
};

const authReducer = (state = initialState, action) => {
  // console.log("action", action.type, action);
  switch (action.type) {
    case AUTH_SIGN_IN_EMAIL_PASSWORD: {
      // console.log("action AUTH_SIGN_IN_EMAIL_PASSWORD", action);
      return {
        ...state,

        rememberMe: action.rememberMe,
        isLoading: true,

        user: null,
        error: null
      };
    }
    case AUTH_SIGN_IN_SUCCESS: {
      // console.log("action AUTH_SIGN_IN_SUCCESS", action);
    
      googleAnalytics("", ({category: 'engagement',action: 'login',label: 'login'}),"");
      facebookPixel("",{type: 'login',data: {}});

      return {
        ...state,
        isLoading: false,
        user: action.user,
        isModalOpen: false
      };
    }
    case AUTH_SIGN_IN_OPEN_MODAL: {
      // console.log("action AUTH_SIGN_IN_OPEN_MODAL", action);
      return {
        ...state,
        isModalOpen: true
      };
    }
    case AUTH_SIGN_IN_CLOSE_MODAL: {
      // console.log("action AUTH_SIGN_IN_CLOSE_MODAL", action);
      return {
        ...state,
        isModalOpen: false,

        forgotPasswordSuccess: null,
        forgotPasswordError: null
      };
    }
    case AUTH_SIGN_IN_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case AUTH_SIGN_OUT: {
      // console.log("action AUTH_SIGN_OUT", action);
      localStorage.clear();
      sessionStorage.clear();

      return {
        ...state,
        user: null,
        isLoading: false,
        rememberMe: false,
        error: null,
        isModalOpen: false
      };
    }

    case AUTH_SIGN_UP_ERROR:
      // console.log("action AUTH_SIGN_UP_ERROR", action);
      return {
        ...state,
        signUpError: action.error
      };

    case AUTH_FORGOT_PASSWORD_SUCCESS:
      // console.log("action AUTH_FORGOT_PASSWORD_SUCCESS", action);
      return {
        ...state,
        forgotPasswordSuccess: true
      };

    case AUTH_FORGOT_PASSWORD_ERROR:
      // console.log("action AUTH_FORGOT_PASSWORD_ERROR", action);
      return {
        ...state,
        forgotPasswordError: action.error
      };

    default:
      return state;
  }
};

export default authReducer;
