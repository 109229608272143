import React from "react";
import { Box, Container, Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Skeleton from "@material-ui/lab/Skeleton";
import Card from "./Card";
import { useEffect } from "react";
import BlogRepository from "../../repositories/BlogRepository";

function createElementFromHTML(htmlString) {
  var div = document.createElement("div");
  div.innerHTML = htmlString.trim();

  // Change this to div.childNodes to support multiple top-level nodes
  return div.firstChild;
}

export default function BlogSection(props) {
  const { title, subtitle } = props;
  const isMobile = useMediaQuery("(max-width:768px)");

  const [blogList, setBlogList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      setIsLoading(true);

      BlogRepository.getFeed().then((data) => {
        var items = [];
        if (data && data.children.length > 0) {
          const array = Array.from(data.children[0].children[0].children);
          array.forEach((tag, index) => {
            if (tag.localName == "item" && items.length < 3) {
              const arr = Array.from(tag.children);
              var item = {};
              arr.forEach(
                (content) => (item[content.localName] = content.textContent)
              );
              items.push(item);
            }
          });
        }
        setTimeout(() => {
          setBlogList(items);
          setIsLoading(false);
        }, 500);
      });
    }

    return () => {
      unmounted = true;
    };
  }, [setBlogList]);
  return (
    <Box>
      <Container>
        <h1>{title}</h1>

        {isLoading &&
          Array.from([0, 1]).map((_) => (
            <Skeleton
              key={Math.random() + Date.now}
              variant="rect"
              width={isMobile ? "100%" : "30%"}
              height={120}
            >
              <Skeleton width="75%" />
              <Skeleton width="55%" />
              <Skeleton width="70%" height={32} />
            </Skeleton>
          ))}
        <Grid container>
          {blogList && blogList.length > 0 ? (
            blogList.map((item) => (
              <Grid xs={12} sm={12} md={4} lg={4}>
                <a
                  href={item.link}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Card
                    title={item.title}
                    subtitle={item.description.replace(/<\/?[^>]+(>|$)/g, "")}
                    image={/<img.*?src="([^">]*\/([^">]*?))".*?>/g.exec(
                      item.description
                    )}
                    tag={item.category}
                    link={item.link}
                  />
                </a>
              </Grid>
            ))
          ) : (
            <div style={{ width: "100%" }}>
              <h2 style={{ textAlign: "center" }}>
                Não foi possível buscar o feed.
              </h2>
            </div>
          )}
        </Grid>
      </Container>
    </Box>
  );
}
