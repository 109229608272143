import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  makeStyles,
  styled,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Skeleton from '@material-ui/lab/Skeleton';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { searchTrips } from '../../actions';
import UserRepository, {
  UserTripType,
} from '../../repositories/UserRepository';
import { Link } from 'react-router-dom';

const LinkPrintBooking = styled(Link)`
  text-decoration: none;
  margin-top: 30px;
`;

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },

  tripSearchCards: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(2),
  },
  tripSearchCard: {
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
      marginRight: 0,
    },
    flexBasis: '30%',
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  tripSearchCardsTitle: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-end',
  },
  icon: {
    alignSelf: 'center',
    margin: '.75rem',
    transform: 'rotate(90deg)',
  },
  button: {
    textTransform: 'none',
  },
  link: {
    textDecoration: 'none',
    marginTop: '30px',
  },
}));

function UserNextTripCard(props) {
  const { title, isLoading, isEmpty, firstTrip, settings, searchTrips, user } =
    props;
  const classes = styles();
  const [tripsEdit, setTripsEdit] = React.useState([]);
  const [trips, setTrips] = React.useState([]);
  // console.log("trips", trips, user);

  useEffect(() => {
    UserRepository.getNextTrip(user.uidFirebase, UserTripType.PENDING).then(
      (bookings) => {
        setTrips(bookings);
      }
    );
  }, []);
  useEffect(() => {
    setTripsEdit(trips);
  }, [trips, firstTrip, settings]);
  const isMobile = useMediaQuery('(max-width:768px)');
  const redoSearch = (trip) => {
   
    searchTrips(
      trip.departureCity || trip.cityDeparture,
      trip.arrivalCity || trip.cityArrival,
      trip.dateDepart,
      trip.dateReturn,
      false,
      user.uidFirebase
    );
  };

  const getDepartureCity = (trip) =>
    trip && `${trip.departure.city.name}, ${trip.departure.city.uf}`;

  const getArrivalCity = (trip) => trip && `${trip.arrival.city.name}`;
  // trip && `${trip.arrival.city.name}, ${trip.arrival.city.uf}`;

  if (Boolean(isEmpty)) {
    return null;
  }

  const {} = props;

  return (
    <>
      <Box marginBottom={4}>
        <Container className={classes.tripSearchCards}>
          <Box className={classes.tripSearchCardsTitle}>
            <h1>{title}</h1>
          </Box>

          {isLoading &&
            Array.from([0, 1]).map((_) => (
              <Skeleton
                key={Math.random() + Date.now}
                variant='rect'
                width={isMobile ? '100%' : '30%'}
                height={120}
                style={{ padding: 10, marginRight: isMobile ? 0 : 32 }}
              >
                <Skeleton width='75%' />
                <Skeleton width='55%' />
                <Skeleton width='70%' height={32} />
              </Skeleton>
            ))}

          {!isEmpty &&
            tripsEdit &&
            tripsEdit.map((trip) => (
              <>
                <Card key={Math.random()} className={classes.tripSearchCard}>
                  <CardContent>
                    <Box
                      display='flex'
                      flexDirection='column'
                      alignItems='stretch'
                      justifyContent='space-around'
                    >
                      <div
                        style={{
                          paddingTop: 15,
                          display: 'flex',
                          flexDirection: 'row',
                          marginBottom: 15,
                        }}
                      >
                        <div style={{ width: '78%' }}>
                          <div>
                            {!!trip.dateReturn && <span>Ida: </span>}
                            <span
                              style={{
                                fontWeight: 'bolder',
                                fontSize: 23,
                                color: '#0FB8D4',
                              }}
                            >
                              {getArrivalCity(trip.trip)}
                            </span>
                          </div>
                          {!!trip.trip.etd && (
                            <span style={{ fontWeight: 'lighter' }}>
                              {moment(trip.trip.etd).format('dddd, ll')}
                            </span>
                          )}
                        </div>
                        <div style={{ display: 'flex', width: '22%' }}>
                          <Link
                            to={`/minha-conta/minhas-viagens/imprimir-reserva/${trip.id}/${trip.passengers[0].passengerId}/${trip.bookingCode}`}
                            className={classes.link}
                            target='_blank'
                            style={{ position: 'flex-end' }}
                          >
                            <Button
                              variant='outlined'
                              className={classes.button}
                              style={{
                                fontWeight: 'bolder',
                                borderRadius: '20px',
                              }}
                            >
                              Ticket
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </Box>
                  </CardContent>
                  {/* <CardActions disableSpacing>
                    <Box></Box>
                  </CardActions> */}
                </Card>
              </>
            ))}
        </Container>
      </Box>
    </>
  );
}

const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      searchTrips: searchTrips,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserNextTripCard);
