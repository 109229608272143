import React from 'react';
import './NewTripSearch.css';
import LogoWhite from '../../static/images/logo-white.png';

import * as Yup from 'yup';

import {
    Button,
    Grid,
    MuiThemeProvider,
    createMuiTheme,
    makeStyles,
    InputAdornment,
    Container,
} from '@material-ui/core';

import { Field, Formik } from 'formik';
import AppDatePicker from '../app-datepicker/AppDatePicker';
import AutoComplete from '../autocomplete';
import CitiesRepository from '../../repositories/CitiesRepository';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import deburr from 'lodash/deburr';
import { searchTrips } from '../../actions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { GpsFixed, Room } from '@material-ui/icons';
import clsx from 'clsx';
import { scroller } from 'react-scroll';
import { SCROLL_TRIP_SEARCH_ID } from '../../constants/statics_id';

const styles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    content: {
        // marginTop: "15px",
        marginBottom: '15px',
    },
    button: {
        fontWeight:700,
        textTransform: 'none',
        margin:0,
        paddingRight: '10px',
        paddingLeft: '10px',
        justifyItems: 'right',
        marginLeft: 'auto',
        background: '#02B3D4',
        color: '#FFF',
        height: 55,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    bannerImage: {
        marginTop: 20
    }
}));

const darkTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
        fontFamily: "'Rubik', sans-serif",
    },
    bannerImage:{
        paddingTop: '10px',
    },
    palette: {
        type: 'light',
        error: {
            contrastText: '#222222',
            dark: '#222222',
            light: '#2222222',
            main: '#F00000',
        },
        background: {
            default: '#424242',
        },
        primary: {
            main: '#0FB8D4',
        },
        secondary: {
            main: '#0FB8D4',
        },
        white: {
            main: '#FFF',
        },
    },
    overrides: {
        MuiInputLabel: {
            // Name of the component ⚛️ / style sheet
            root: {
                // Name of the rule
                // color: 'orange',
                '&$focused': {
                    // increase the specificity for the pseudo class
                    color: '#2222222',
                },
            },
        },
    }
});

function TripSearch(props) {
    const {
        user,
        cityDeparture,
        cityArrival,
        departDate,
        returnDate,
        searchTrips,
    } = props;

    const classes = styles();
    const [suggestions, setSuggestions] = React.useState([]);
    const citiesRepository = new CitiesRepository();

    const getSuggestions = (suggestions, value) => {
        const inputValue = deburr(value.trim()).toLowerCase();
        const inputLength = inputValue.length;
        let count = 0;
        //console.log("get ", inputValue);
        return inputLength === 0
            ? []
            : suggestions.filter((suggestion) => {
                const keep =
                    count <= 5 &&
                    suggestion.keyWords.find((key) => key.toLowerCase() === inputValue)
                        .length;

                // suggestion.nameFormat.slice(0, inputLength).toLowerCase() ===
                //   inputValue;

                if (keep) {
                    count += 1;
                }

                return keep;
            });
    };

    const getSuggestionValue = (suggestion) => {
        return `${suggestion.name}, ${suggestion.uf}`;
    };

    const handleSuggestionsFetchRequested = ({ value }) => {
        if (value.length >= 3) {
            citiesRepository.searchCities(value).then((snapshot) => {
                // console.log("querySnapshot", querySnapshot);
                // console.log("querySnapshot.docs", querySnapshot.docs);
                const res = snapshot.docs.map((i) => i.data());

                console.log('get res', res);
                console.log('getSuggestions -->', getSuggestions(res || [], value));
                setSuggestions(getSuggestions(res || [], value));
            });
        }
    };

    const handleSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const formSchema = Yup.object().shape({
        departCity: Yup.object().shape({
            id: Yup.string().required('Local de partida é obrigatório'),
        }),
        arrivalCity: Yup.object().shape({
            id: Yup.string().required('Local de destino é obrigatório'),
        }),
        departDate: Yup.date()
            .nullable()
            .required('Data de Ida é obrigatória')
            .typeError('Data de Ida inválida'),
        returnDate: Yup.date().nullable().typeError('Data da Volta inválida'),
    });

    const searchTrip = (values, { setSubmitting }) => {
        setSubmitting(true);
        scroller.scrollTo(SCROLL_TRIP_SEARCH_ID, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -60
        });

        if(isMobile){
            scroller.scrollTo(SCROLL_TRIP_SEARCH_ID, {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart',
                offset: 10
            });
        }
        searchTrips(
            values.departCity,
            values.arrivalCity,
            values.departDate,
            values.returnDate,
            true,
            user && user.uidFirebase,
            () => {
                setSubmitting(false);
            }
        );
    };

    const isMobile = useMediaQuery('(max-width:500px)');

    return (
        <MuiThemeProvider theme={darkTheme}>
            <div className='wrap'>
                
                {isMobile ?
                <img
                    src={LogoWhite}
                    alt='logo 4bus'
                    className='logo-white-responsive'
                /> :
                
                <img
                    src='https://images-adm.s3.sa-east-1.amazonaws.com/banner-new-site-1.png'
                    alt='bg'
                    className={clsx(classes.bannerImage)}
                />}

                <div className='content'>
                    <Container>
                            <Formik
                                initialValues={{
                                    departCity: cityDeparture || {},
                                    arrivalCity: cityArrival || {},
                                    departDate: departDate,
                                    returnDate: returnDate,
                                }}
                                validationSchema={formSchema}
                                validateOnBlur={true}
                                validateOnChange={true}
                                onSubmit={searchTrip}
                            >
                                {({
                                    values,
                                    errors,
                                    status,
                                    touched,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue,
                                    setFieldError,
                                }) => (
                                    <form style={{height:'inherit'}} onSubmit={handleSubmit}>
                                        <div className='searchBox'>
                                            <Grid
                                                container
                                                spacing={2}
                                                direction='row'

                                                alignItems='flex-start'
                                            >
                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                    <h2 className="searchText">Para onde vamos?</h2>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={3} lg={3}>

                                                <AutoComplete
                                                        name='departCity'
                                                        //label='Local de partida'
                                                        placeholder='Local de embarque'
                                                        suggestions={suggestions}
                                                        //value={values.departCity && values.departCity.name?`${values.departCity.name}, ${values.departCity.uf}`:null}
                                                        inputProps={{
                                                            disableUnderline: true,
                                                            className: "searchField",
                                                            startAdornment: (
                                                                <InputAdornment className="startAdornment" position="end">
                                                                    <GpsFixed />
                                                                </InputAdornment>
                                                            ),
                                                            /* endAdornment:
                                                                (<ButtonBase 
                                                                    onClick={()=>{
                                                                        setFieldValue("departCity", values.arrivalCity, false);
                                                                        setFieldValue("arrivalCity", values.departCity, false);
                                                                    }}
                                                                className="changeLocalButton">
                                                                    <InputAdornment position="end">
                                                                        <ImportExport />
                                                                    </InputAdornment>
                                                                </ButtonBase>
                                                                )*/
                                                            }}

                                                    focusInputOnSuggestionClick={false}
                                                    getSuggestionValue={getSuggestionValue}
                                                    onBlur={(event) => {
                                                        handleBlur(event);
                                                        handleSuggestionsClearRequested();
                                                    }}
                                                    onSuggestionChanged={(fieldName, suggestion) => {
                                                        setFieldValue(fieldName, suggestion, false);
                                                    }}
                                                    onSuggestionsFetch={
                                                        handleSuggestionsFetchRequested
                                                    }
                                                    onSuggestionsClear={
                                                        handleSuggestionsClearRequested
                                                    }

                                                    variant='filled'
                                                    error={Boolean(
                                                        errors.departCity && errors.departCity.id
                                                    )}
                                                    helperText={
                                                        errors.departCity && errors.departCity.id
                                                            ? errors.departCity.id
                                                            : null
                                                    }

                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                                    <AutoComplete
                                                        name='arrivalCity'
                                                        //label='Local de destino'
                                                        placeholder='Local de destino'
                                                        // onClick={e => {
                                                        //   getAllSuggestions()
                                                        //   setLocal(1)
                                                        // }}
                                                        // alwaysRenderSuggestions={local === 1}
                                                        suggestions={suggestions}
                                                        getSuggestionValue={getSuggestionValue}
                                                        onBlur={(event, ...other) => {
                                                            // console.log('event, other', event, other);
                                                            handleBlur(event);
                                                            handleSuggestionsClearRequested();
                                                        }}
                                                        onSuggestionChanged={(fieldName, suggestion) => {
                                                            setFieldValue(fieldName, suggestion, false);
                                                        }}
                                                        onSuggestionsFetch={
                                                            handleSuggestionsFetchRequested
                                                        }
                                                        onSuggestionsClear={
                                                            handleSuggestionsClearRequested
                                                        }
                                                        //value={values.arrivalCity && values.arrivalCity.name?`${values.arrivalCity.name}, ${values.arrivalCity.uf}`:null}
                                                        inputProps={{
                                                            className: "searchField",
                                                            startAdornment: (
                                                                <InputAdornment className="startAdornment" position="end">
                                                                    <Room />
                                                                </InputAdornment>
                                                            ),
                                                            disableUnderline: true,
                                                        }}
                                                        variant='filled'
                                                        error={Boolean(
                                                            errors.arrivalCity && errors.arrivalCity.id
                                                        )}
                                                        helperText={
                                                            errors.arrivalCity && errors.arrivalCity.id
                                                                ? errors.arrivalCity.id
                                                                : null
                                                        }
                                                    />
                                                </Grid>

                                                <Grid
                                                    className='dateInput'
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={3}
                                                    lg={2}
                                                >
                                                    <Field
                                                        name='departDate'
                                                        disablePast
                                                        label='Data de Ida'
                                                        className="dateField"
                                                        component={AppDatePicker}
                                                        onChange={(fieldName, date) => {
                                                            console.log('>>>>>>>>>', new Date(date));
                                                            setFieldValue(fieldName, date, false);
                                                        }}
                                                        onBlur={handleBlur}
                                                        onError={(fieldName, error, _value) =>
                                                            setFieldError(fieldName, error, false)
                                                        }
                                                        margin='none'
                                                        invalidDateMessage='Data inválida'
                                                        minDateMessage='Data inválida'
                                                        autoOk
                                                        maxDateMessage='Data inválida'
                                                        inputVariant='filled'
                                                        error={Boolean(
                                                            errors.departDate && touched.departDate
                                                        )}
                                                        helperText={
                                                            errors.departDate && touched.departDate
                                                                ? errors.departDate
                                                                : null
                                                        }
                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={3}
                                                    lg={2}
                                                    className='dateInput'
                                                >
                                                    <Field
                                                        name='returnDate'
                                                        disablePast
                                                        label='Data de Volta'
                                                        className="dateField"
                                                        component={AppDatePicker}
                                                        onChange={(fieldName, date) =>
                                                            setFieldValue(fieldName, date, false)
                                                        }
                                                        onBlur={handleBlur}
                                                        onError={(fieldName, error, _value) =>
                                                            setFieldError(fieldName, error, false)
                                                        }
                                                        margin='none'
                                                        invalidDateMessage='Data inválida'
                                                        minDateMessage='Data inválida'
                                                        maxDateMessage='Data inválida'
                                                        inputVariant='filled'
                                                        error={Boolean(
                                                            errors.returnDate && touched.returnDate
                                                        )}
                                                        helperText={
                                                            errors.returnDate && touched.returnDate
                                                                ? errors.returnDate
                                                                : null
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={2}
                                                    
                                                >
                                                    <Button
                                                        variant='text'
                                                        aria-label='Buscar'
                                                        className={classes.button}
                                                        type='submit'
                                                        fullWidth
                                                        size="large"
                                                    >
                                                        Buscar meu ônibus
                                                    </Button>
                                                </Grid>

                                            </Grid>

                                        </div>
                                    </form>
                                )}
                            </Formik>
                    </Container>
                </div>
            </div>
        </MuiThemeProvider>
    );
}

const mapStateToProps = (store) => ({
    user: store.auth.user,
    cityDeparture: store.searchTripState.cityDeparture,
    cityArrival: store.searchTripState.cityArrival,
    departDate: store.searchTripState.departDate,
    returnDate: store.searchTripState.returnDate,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            searchTrips: searchTrips,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(TripSearch);
