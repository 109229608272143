import 'date-fns';

import * as Yup from 'yup';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Field, Formik } from 'formik';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {
  faAddressCard,
  faCity,
  faIdCardAlt,
} from '@fortawesome/free-solid-svg-icons';

import AccountCircle from '@material-ui/icons/AccountCircle';
import AppInputMask from '../../app-input-mask/AppInputMask';
import DateFnsUtils from '@date-io/date-fns';
import DialogTitle from '../../shared/dialog-title/DialogTitle';
import DocumentTypes from '../../../models/DocumentTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingOverlay from 'react-loading-overlay';
import PassengerApiService from '../../../services/PassengerApiService';
import React from 'react';
import { bindActionCreators } from 'redux';
import clsx from 'clsx';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { cpfIsValid } from '../../../utils/validators';
import { enqueueSnackbar } from '../../../actions';
import ptBRLocale from 'date-fns/locale/pt-BR';
import MaskedInput from 'react-text-mask';
const CellPhoneMask = (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,

        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      //   showMask
      keepCharPositions={true}
    />
  );
};

const DatePickerField = ({ field, form, className, format, ...other }) => {
  const currentError = form.errors[field.name];

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
      <KeyboardDatePicker
        clearable
        name={field.name}
        value={field.value}
        format={format || 'dd/MM/yyyy'}
        onError={(error) => {
          // handle as a side effect
          if (error !== currentError) {
            form.setFieldError(field.name, error);
          }
        }}
        onChange={(date) => form.setFieldValue(field.name, date, true)}
        inputVariant='standard'
        maskChar='_'
        {...other}
      />
    </MuiPickersUtilsProvider>
  );
};
const CustomTextField = styled(TextField)`
  label {
    transform: translate(12px, 16px) scale(1);
  }
  input {
    padding: 14px 12px;
  }
`;
const styles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dialogPaper: {
    [theme.breakpoints.down('sm')]: {
      margin: '70px 10px 10px',
      maxHeight: 'calc(100vh - 76px) !important',
    },
  },
  textField: {
    flex: '0 1 calc(50%)',
    '&:first-child': {
      flex: '0 1 calc(50% - 16px)',
      marginRight: theme.spacing(2),
    },
  },
  textFieldAuto: {
    width: '100%',
  },
  textFieldFull: {
    flex: '0 1 100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formControl: {
    minWidth: 170,
  },
  grid: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 8px 16px !important',
    },
  },
}));

function PassengerForm(props) {
  const {
    enqueueSnackbar,
    passenger,
    isModalOpen,
    onClose,
    onSaveSuccess,
    onError,
  } = props;
  // console.log('props', props);
  const classes = styles();
  const clientBySeller = JSON.parse(
    sessionStorage.getItem('clientSelectedBySeller')
  );
  const formSchema = Yup.object().shape({
    name: Yup.string().required(
      'Por favor digite o nome completo do Passageiro'
    ),
    idType: Yup.string()
      .oneOf(['RG', 'CN', 'P'])
      .required('Escolha o Tipo de Documento'),
    idNumber: Yup.string().required('Por favor digite o Documento'),
    idIssuer: Yup.string().notRequired(),
    cpf: Yup.string()
      .notRequired()
      .test('CPF', 'CPF Inválido', (cpf) => cpfIsValid(cpf, false)),
    birthdate: Yup.date().required('Por favor digite sua Data de Nascimento'),
    cellphone: Yup.string().trim().min(15, 'Celular Inválido'),
  });

  const validCompleteName = (Name) => {
    if (Name.search(' ') === -1) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const submitValues = { ...passenger, ...values };
    if (clientBySeller?.id) {
      submitValues.userId = clientBySeller.id;
    }
    console.log('submitValues', submitValues);
    if (submitValues.name.indexOf(' ') === -1) {
      enqueueSnackbar({
        message: 'É necessário digitar o nome completo do passageiro',
        options: {
          key: 'passengerFormErrorSnack',
          autoHideDuration: 3000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        },
      });
      setSubmitting(false);
      return;
    }
    // console.log('handleSubmit -> values, submitValues', values, submitValues);
    PassengerApiService.savePassenger(submitValues)
      .then(
        (res) => {
          if (res.status === 200) {
            enqueueSnackbar({
              message: 'Passageiro incluído com sucesso!',
              options: {
                key: 'passengerFormSuccessSnack',
                autoHideDuration: 2000,
                variant: 'success',
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
              },
            });

            onSaveSuccess && onSaveSuccess(res.data);
          }
        },
        (reason) => {
          // console.log('reason', reason);
          let message =
            'Não foi possivel realizar a operação, se o problema persistir contate o administrador do sistema';

          // if (reason.status === 400) {
          //   message = 'Passageiro com passagem ativa, imporssivel excluir';
          // }

          enqueueSnackbar({
            message: message,
            options: {
              key: 'passengerFormErrorSnack',
              autoHideDuration: 3000,
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            },
          });

          onError && onError(reason);
        }
      )
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        maxWidth={'md'}
        open={isModalOpen}
        onClose={onClose}
        scroll={'paper'}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle onClose={onClose}>Cadastro de Passageiro</DialogTitle>
        <DialogContent>
          <Box>
            <Formik
              initialValues={{
                name: (passenger && passenger.name) || '',
                idType: (passenger && passenger.idType) || '',
                idNumber: (passenger && passenger.idNumber) || '',
                idIssuer: (passenger && passenger.idIssuer) || '',
                cpf: (passenger && passenger.cpf) || '',
                birthdate: (passenger && passenger.birthdate) || null,
                cellphone: (passenger && passenger.cellphone) || '',
                // name: (passenger && passenger.name) || 'O Quinto Elemento',
                // idType: (passenger && passenger.idType) || 'RG',
                // idNumber: (passenger && passenger.idNumber) || '466853336',
                // idIssuer: (passenger && passenger.idIssuer) || 'SSP',
                // cpf: (passenger && passenger.cpf) || '39100818860',
                // birthdate: new Date(1989, 11, 20),
              }}
              validationSchema={formSchema}
              // validateOnBlur={true}
              validateOnChange={true}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({
                isValid,
                values,
                errors,
                status,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                ...other
              }) => {

                return (
                  <LoadingOverlay spinner active={isSubmitting}>
                    <form
                      onSubmit={handleSubmit}
                      className={clsx(classes.form)}
                    >
                      <Grid container spacing={4}>
                        <Grid className={classes.grid} item xs={12} sm={12}>
                          <TextField
                            fullWidth
                            name='name'
                            autoFocus={true}
                            // className={clsx(
                            // classes.margin,
                            // classes.formControl,
                            // classes.textFieldFull
                            // )}
                            margin='dense'
                            variant='standard'
                            type='text'
                            label='Nome completo'
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <AccountCircle />
                                </InputAdornment>
                              ),
                            }}
                            error={errors.name && touched.name}
                            helperText={
                              errors.name && touched.name ? errors.name : null
                            }
                          />
                        </Grid>
                        {/* <Box display="flex" flex="1 1 100%"> */}
                        <Grid className={classes.grid} item xs={12} sm={6}>
                          <FormControl
                            // className={clsx(
                            //   classes.margin,
                            //   classes.formControl,
                            //   classes.textFieldAuto
                            // )}
                            fullWidth
                            error={errors.idType && touched.idType}
                          >
                            <InputLabel htmlFor='idType'>
                              Tipo de Documento
                            </InputLabel>
                            <Select
                              value={values.idType}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              margin='dense'
                              inputProps={{
                                name: 'idType',
                                id: 'idType',
                              }}
                            >
                              <MenuItem value='CN'>
                                Certidão de Nascimento
                              </MenuItem>
                              <MenuItem value='RG'>RG</MenuItem>
                              <MenuItem value='P'>Passaporte</MenuItem>
                            </Select>

                            {errors.idType && touched.idType && (
                              <FormHelperText>
                                {errors.idType && touched.idType
                                  ? errors.idType
                                  : null}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid className={classes.grid} item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            name='idNumber'
                            // className={clsx(
                            //   classes.margin,
                            //   classes.textFieldAuto
                            // )}
                            margin='dense'
                            variant='standard'
                            type='text'
                            label={DocumentTypes[values.idType] || 'Documento'}
                            value={values.idNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <FontAwesomeIcon icon={faAddressCard} />
                                </InputAdornment>
                              ),
                            }}
                            error={errors.idNumber && touched.idNumber}
                            helperText={
                              errors.idNumber && touched.idNumber
                                ? errors.idNumber
                                : null
                            }
                          />
                        </Grid>
                        <Grid className={classes.grid} item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            name='idIssuer'
                            // className={clsx(
                            //   classes.margin,
                            //   classes.textFieldAuto
                            // )}
                            margin='dense'
                            variant='standard'
                            type='text'
                            label='Órgão de Expedição'
                            value={values.idIssuer}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <FontAwesomeIcon icon={faCity} />
                                </InputAdornment>
                              ),
                            }}
                            error={errors.idIssuer && touched.idIssuer}
                            helperText={
                              errors.idIssuer && touched.idIssuer
                                ? errors.idIssuer
                                : null
                            }
                          />
                        </Grid>
                        {/* </Box> */}

                        {/* <Box display="flex" flex="1 1 100%"> */}
                        <Grid className={classes.grid} item xs={12} sm={6}>
                          <AppInputMask
                            mask='999.999.999-99'
                            value={values.cpf}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='cpf'
                            label='CPF'
                            className={clsx(classes.textFieldAuto)}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <FontAwesomeIcon icon={faIdCardAlt} />
                                </InputAdornment>
                              ),
                            }}
                            error={errors.cpf && touched.cpf}
                            helperText={
                              errors.cpf && touched.cpf ? errors.cpf : null
                            }
                          />
                        </Grid>
                        <Grid className={classes.grid} item xs={12} sm={6}>
                          <Field
                            fullWidth
                            name='birthdate'
                            // InputAdornmentProps={{ position: 'end' }}
                            value={values.birthdate}
                            component={DatePickerField}
                            // className={clsx(
                            //   classes.margin,
                            //   classes.textFieldAuto
                            // )}
                            disableFuture
                            margin='dense'
                            label='Data de Nascimento'
                          />
                        </Grid>
                        <Grid className={classes.grid} item xs={12} sm={6}>
                          <Field
                            name='cellphone'
                            render={({ field }) => (
                              <CustomTextField
                                {...field}
                                fullWidth
                                id='cellphone'
                                className={clsx(classes.textFieldAuto)}
                                variant='standard'
                                type='text'
                                label='Celular'
                                value={values.cellphone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputProps={{
                                  inputComponent: CellPhoneMask,
                                  inputProps: {
                                    autoComplete: 'off',
                                    form: {
                                      autoComplete: 'off',
                                    },
                                  },
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      <Icon style={{ fontSize: 18 }}>
                                        contact_phone
                                      </Icon>
                                    </InputAdornment>
                                  ),
                                }}
                                error={errors.cellphone && touched.cellphone}
                                helperText={
                                  errors.cellphone && touched.cellphone
                                    ? errors.cellphone
                                    : null
                                }
                              />
                            )}
                          />
                        </Grid>

                        {/* </Box> */}

                        <Box
                          container
                          spacing={1}
                          style={{ margin: '16px 8px' }}
                          alignItems='center'
                          display='flex'
                        >
                          <Icon color='primary' style={{ marginRight: '8px' }}>
                            info
                          </Icon>
                          <Typography
                            variant='subtitle2'
                            component='span'
                            color='primary'
                          >
                            O documento selecionado deve ser apresentado no
                            momento do embarque
                          </Typography>
                        </Box>

                        <Box
                          display='flex'
                          flexDirection='row-reverse'
                          alignItems='flex-end'
                          justifyContent='space-between'
                          paddingY={2}
                          flex='1 1 100%'
                        >
                          <Grid container justify='flex-end'>
                            <Grid item xs={12} sm={3}>
                              <Button
                                fullWidth
                                type='submit'
                                variant='outlined'
                                color='primary'
                                disabled={
                                  !values.name ||
                                  !values.idType ||
                                  !values.idNumber ||
                                  !values.idIssuer ||
                                  !values.cpf ||
                                  !values.birthdate ||
                                  isSubmitting
                                }
                              >
                                Salvar
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </form>
                  </LoadingOverlay>
                );
              }}
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      enqueueSnackbar: enqueueSnackbar,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PassengerForm);
