import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import React from 'react';
import { TextField } from '@material-ui/core';

function AppInputMask(props) {
  const { mask, onChange, placeholder,maskChar, onBlur, value, style, ...other } = props;
  return (
    <InputMask
      placeholder={placeholder}
      mask={mask}
      maskChar={maskChar || ' '}
      style={style}
      alwaysShowMask={false}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    >
      {() => (
        <TextField
          margin="dense"
          variant="standard"
          type="text"
          {...other}
        ></TextField>
      )}
    </InputMask>
  );
}

AppInputMask.propTypes = {
  mask: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default AppInputMask;
