import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import CheckSuccess from "../../../shared/check-success";
import { IconButton } from "@material-ui/core";

const PixInfo = () => {
  const bookingDatas = JSON.parse(
    sessionStorage.getItem("checkoutBookingResult")
  );
  const [message, setMessage] = useState("");
  const [isExpired, setIsExpired] = useState(false);
  const [isPayed, setIsPayed] = useState(false);

  const isMobile = useMediaQuery("(max-width:768px)");

  const { pixQrcode, pixQrcodeBase64, pixExpiration } =
    bookingDatas && bookingDatas.booking ? bookingDatas.booking : {};

  useEffect(() => {
    if (!pixQrcode || !pixQrcodeBase64 || !pixExpiration) {
      setIsExpired(true);
    }
  }, [pixQrcode, pixQrcodeBase64, pixExpiration]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const actualDate = moment();
      const expirationDate = moment(pixExpiration);
      if (actualDate.isAfter(expirationDate)) {
        setIsExpired(true);
        clearInterval(intervalId);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [pixExpiration]);

  const copyPixLink = () => {
    try {
      navigator.clipboard.writeText(pixQrcode);
      setMessage("Copiado com sucesso!");
    } catch (err) {
      setMessage(
        "Erro inesperado ao copiar! caso o erro persistir, contato o suporte."
      );
    }
    setTimeout(() => {
      setMessage("");
    }, 2000);
  };

  if (bookingDatas && bookingDatas.pixData && bookingDatas.pixData.statusId !== 15) {
    switch (bookingDatas.pixData.statusId) {
      case 3:
        setIsPayed(true);
        break;
      case 4:
        setIsExpired(true);
        break;
      default:
        break;
    }
    return;
  }

  if (isExpired) {
    sessionStorage.removeItem("checkoutBookingResult");
  }

  return (
    <Box textAlign="center">
      <Grid container spacing={3} className="box-container">

       {isPayed ? (
        <>
          <Grid item xs={12}>
            <CheckSuccess/>
          </Grid>
        
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              Pagamento recebido
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="h4" align="center">
              Reserva realizada com sucesso!
            </Typography>
          </Grid>
        </>
       ) : (
        <>
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              {isExpired 
              ? "Reserva cancelada"
              : "Para concluir a reserva, por favor efetue o pagamento em seu banco copiando o Código ou lendo o QR code"}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">
              {isExpired 
              ? "" 
              : "Você será notificado quando o pagamento for recebido."}
            </Typography>
          </Grid>

          <Grid item xs={12}>
              <Typography variant="span">
                {isMobile ? "Clique no botão abaixo para copiar o Código" : ""}
              </Typography>
              <p>{message}</p>
              {isExpired ? (
                <></>
              ) : (
                isMobile ? (
                  <>

                    <IconButton onClick={copyPixLink} color="primary">
                      <FileCopyIcon />
                    </IconButton>

                    <Grid item xs={12} className="grid-multi-line-btn">
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={true}
                        className="multi-line-button"
                      >
                        {pixQrcode}
                      </Button>
                    </Grid>
             
                  </>

                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    className="btn-pix-link"
                    onClick={copyPixLink}
                  >
                    {pixQrcode}
                    <FileCopyIcon />
                  </Button>
                )
              )}
          </Grid>

          <Grid item xs={12}>
              {isExpired ? (
                <Typography variant="h5" align="center">
                  O código expirou!
                </Typography>
              ) : (
                <Box className="qr-code-box">
                  <img
                    className="qr-code-img"
                    src={`data:image/jpeg;base64,${pixQrcodeBase64}`}
                    alt="Pix qr code"
                  />
                </Box>
              )}
          </Grid>
        </>
       )}
      </Grid>

        {isExpired ? (
          <></>
        ) : (
          <Grid item xs={12}>
            <Typography variant="h6">
              O Código irá se expirar {moment(pixExpiration).calendar()}
            </Typography>
          </Grid>
        )}

    </Box>
  );
};

export default PixInfo;
