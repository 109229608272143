import STORAGE from "../constants/storage";

var internalStorage = null;

function getStorage(rememberMe = null) {
  const storageRememberMe = Boolean(localStorage.getItem(STORAGE.REMEMBER_LOGIN));
  const remember = !!rememberMe ? rememberMe : storageRememberMe;
  internalStorage = remember ? localStorage : sessionStorage;

  return internalStorage;
}

const storage = internalStorage || getStorage();

export { storage, getStorage };
