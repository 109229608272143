import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

export const styles = makeStyles(theme => ({

    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    },
    titleRoot: {
      margin: 0,
      padding: theme.spacing(2)
    },
    leftIcon: {
      marginRight: theme.spacing(1)
    },
    buttonSpacer: {
      marginTop: theme.spacing(1)
    },
    loadingButtonWrapper: {
      position: 'relative',
      width: '100%'
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
    },
  })
);