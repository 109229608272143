import Axios from "axios";
import moment from "moment";

export default class PassengerApiService {
  static getListByCustomer() {
    let url = `${process.env.REACT_APP_API_BASE_URL}/passenger`;

    return Axios.get(url);
  }

  static getListBySellerClient(clientBySellerId) {
    let url = `${process.env.REACT_APP_API_BASE_URL}/passenger/user/${clientBySellerId}`;
    return Axios.get(url);
  }

  static savePassenger(data, userId) {
    data.birthdate = moment(data.birthdate).valueOf();

    var numberPattern = /\d+/g;
    data.cpf = data.cpf && data.cpf.match(numberPattern).join("");

    const url = `${process.env.REACT_APP_API_BASE_URL}/passenger`;
    let body = {
      ...data,
    };
    if (userId) {
      body.userId = userId;
    }
    return Axios.post(url, body);
  }

  static delete(passengerId) {
    const url = `${process.env.REACT_APP_API_BASE_URL}/passenger/${passengerId}`;
    return Axios.delete(url);
  }
}
