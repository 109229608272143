import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AuthApiService from '../services/AuthApiService';
import { getFeaturedTrips, getUserLastSearches } from '../actions';
import TripSearchResults from '../components/trip-search/trip-search-results/TripSearchResults';
import ForSaleTrips from '../components/for-sale-trips/ForSaleTrips';
import SettingsRepository from '../repositories/SettingsRepository';
import FrequentlyAskedQuestions from '../components/frequently-asked-questions'
import BlogSection from '../components/blog-section'
import NewTripSearch from '../components/trip-search/NewTripSearch';
import UserNextTripCard from '../components/user-next-trip-card/UserNextTripCard';
import moment from 'moment';
import { SCROLL_TRIP_SEARCH_ID } from '../constants/statics_id';

function Home(props) {
  const {
    featuredTrips,
    featuredTripsIsLoading,
    featuredTripsError,
    featuredTripsIsEmpty,
    getFeaturedTrips,
  } = props;

  const {
    userLastSearches,
    userLastSearchesIsLoading,
    userLastSearchesError,
    userLastSearchesIsEmpty,
    getUserLastSearches,
  } = props;

  const { user } = props;
  const [settings, setSettings] = useState(null);
  const [firstTrip, setFirstTrip] = useState(true);
  const [dialogCorona, setOpenDialogCorona] = useState(true);
  useEffect(() => {
    AuthApiService.getFullUser()
      .then((res) => {
        if (res.data.firstTrip) {
          setFirstTrip(true);
        } else {
          setFirstTrip(false);
        }
      })
      .catch((err) => {
        console.log('err ', err);
      });
    SettingsRepository.getDiscounts()
      .then((set) => {
        let discounts = [];
        set.docs.forEach((doc) => discounts.push(doc.data()));
        setSettings(discounts);
      })
      .catch((err) => console.log('SETTINGS err', err));
  }, [user]);
  useEffect(() => {
    const bookingDatas = JSON.parse(sessionStorage.getItem("checkoutBookingResult"));
    if(bookingDatas){
        const actualDate = moment();
        const expirationDate = moment(bookingDatas.booking.pixExpiration);
        const diff = actualDate.isAfter(expirationDate);
        if (diff) {
          sessionStorage.removeItem("checkoutBookingResult")
        }
    }
    let unmounted = false;
    getFeaturedTrips();

    if (!unmounted && !!user && !!user.uidFirebase) {
      getUserLastSearches(user.uidFirebase);
    }

    return () => {
      unmounted = true;
    };
  }, [getFeaturedTrips, getUserLastSearches, user]);

  return (
    <div style={{marginBottom:-15}}>
      <NewTripSearch />

      <div id={SCROLL_TRIP_SEARCH_ID}>
        <TripSearchResults settings={settings} firstTrip={firstTrip} />
      </div>
      
      {/*<PartnersMarketing title='Conheça também' />*/}
      {/*<MainRoute title="Principais rotas" subtitle="Lorem ipsum dolor sit amet" />*/}
      <BlogSection title="Blog 4bus" subtitle="Lorem ipsum dolor sit amet" />
      {/*  <ForSaleTrips />*/}
      <FrequentlyAskedQuestions title="Dúvidas frequentes"/>
      {/*<MainDestiny title="Principais destinos" subtitle="Lorem ipsum dolor sit amet" />*/}
      
      
    </div>
  );
}

Home.propTypes = {};

const mapStateToProps = (store) => ({
  featuredTrips: store.featuredTrips.trips,
  featuredTripsIsLoading: store.featuredTrips.isLoading,
  featuredTripsError: store.featuredTrips.error,
  featuredTripsIsEmpty: store.featuredTrips.isEmpty,

  userLastSearches: store.userLastSearches.trips,
  userLastSearchesIsLoading: store.userLastSearches.isLoading,
  userLastSearchesError: store.userLastSearches.error,
  userLastSearchesIsEmpty: store.userLastSearches.isEmpty,

  user: store.auth.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getFeaturedTrips: getFeaturedTrips,
      getUserLastSearches: getUserLastSearches,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Home);
