import { bindActionCreators, compose } from 'redux';

import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';

const WithAuth = Component => ({ user, props }) =>
  Boolean(user) ? <Component user={user} {...props} /> : <Redirect to="/" />;

WithAuth.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = store => ({
  user: store.auth.user,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  WithAuth,
);
