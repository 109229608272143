import {
  LISTENER_PIX_SUCCESS,
  LISTENER_PIX_ERROR
} from "../actions/bookingListenerAction";

const initialState = {
  pixExpired: null,
  pixPayed: null
};

const bookingListenerReducer = (state = initialState, action) => {
  switch (action.type) {
    case LISTENER_PIX_SUCCESS:
    return{
      ...state,
      pixData:action.data,
      pixPayed: true,
      pixExpired: null
    }

    case LISTENER_PIX_ERROR:
      return{
        ...state,
        pixExpired: true,
        pixPayed: null,
      }

    default:
      return state;
  }
};

export default bookingListenerReducer;