import { db } from '../firebase';
import deburr from 'lodash/deburr';

export default class CitiesRepository {
  searchCities = (term, onlyAvailable) => {
    const sanitizedTerm = deburr(term).toLowerCase();
    let baseQuery = db.collection('cities').limit(50);

    onlyAvailable =
      onlyAvailable === undefined || onlyAvailable === null
        ? true
        : onlyAvailable;

    if (onlyAvailable) {
      baseQuery = baseQuery.where('available', '==', onlyAvailable);
    }

    return baseQuery.where('keyWords', 'array-contains', sanitizedTerm).get();
  };

  searchCityById = (id) => {
    return (
      db.collection('cities')
      .where('id', '==', parseInt(id))
      .get()
      .then(
        snapshot => {
          const data = snapshot.docs
            .map(i => i.data())
          return data;
        },
        error => {
          return error;
        },
      )
    )
  };

  allCities = (onlyAvailable) => {
    let baseQuery = db.collection('cities').limit(100);

    onlyAvailable =
      onlyAvailable === undefined || onlyAvailable === null
        ? true
        : onlyAvailable;

    if (onlyAvailable) {
      baseQuery = baseQuery.where('available', '==', onlyAvailable);
    }

    return baseQuery.get();
  };
}
