import "./index.css";
import "moment/locale/pt";

import * as serviceWorker from "./serviceWorker";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import AppLayout from "./components/shared/layout/AppLayout";
import Axios from "axios";
import Checkout from "./pages/Checkout";
import CheckoutConfirmation from "./components/checkout/checkout-confirmation/CheckoutConfirmation";
import { HTTP_ERROR_CODES } from "./constants/enums";
import SelectSeats from "./components/seats-select";
import ReturnTripSeat from "./components/seats-select/return-trip-seat";

import CharterTermsOfUseFretado from "./pages/TermsFretado";
import CharterTermsOfUse from "./pages/Terms";
import Help from "./pages/Help";
import Login from "./pages/Login";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import Home from "./pages/Home";
import ResultSearch from "./pages/ResultSearch";
import MyAccount from "./pages/MyAccount";
import { Provider } from "react-redux";
import ROUTES from "./constants/routes";
import React from "react";
import ReactDOM from "react-dom";
import STORAGE from "./constants/storage";
import Store from "./store";
import TripSummary from "./components/checkout/trip-summary/TripSummary";
import PrintBooking from "./components/my-trips/booking-card-pdf/";

import _ from "lodash";
import moment from "moment";
import NewAppBar from "./NewAppBar";
moment.locale(["pt"]);

const publicRoutes = [
  {
    component: ResultSearch,
    path: ROUTES.DEEP_LINK_SEARCH,
  },
  {
    component: Home,
    path: ROUTES.HOME,
  },
  {
    component: Help,
    path: ROUTES.HELP,
  },
  {
    component: Login,
    path: ROUTES.LOGIN,
  },
  {
    component: Signin,
    path: ROUTES.SIGNIN,
  },
  {
    component: Signup,
    path: ROUTES.SIGNUP,
  },
  {
    component: MyAccount,
    path: ROUTES.MY_ACCOUNT,
  },
  {
    component: MyAccount,
    path: ROUTES.MY_TRIPS,
  },
  /*{
    component: MyAccount,
    path: ROUTES.MY_WALLET,
  },*/
  {
    component: PrintBooking,
    path: ROUTES.PRINT_BOOKING,
  },
  {
    component: Checkout,
    path: ROUTES.CHECKOUT,
  },
  {
    component: CheckoutConfirmation,
    path: ROUTES.CHECKOUT_CONFIRMATION,
  },
  {
    component: TripSummary,
    path: ROUTES.CHECKOUT_SUMMARY,
  },
  {
    component: SelectSeats,
    path: ROUTES.CHECKOUT_SELECT_SEATS,
  },
  {
    component: ReturnTripSeat,
    path: ROUTES.CHECKOUT_SELECT_RETURN_SEATS,
  },
  {
    component: CharterTermsOfUseFretado,
    path: ROUTES.CHARTER_TERMS_OF_USE_FRETADO,
  },
  {
    component: CharterTermsOfUse,
    path: ROUTES.CHARTER_TERMS_OF_USE,
  },
];

Axios.interceptors.request.use(
  (config) => {
    const accessToken =
      localStorage.getItem(STORAGE.ACCESS_TOKEN) ||
      sessionStorage.getItem(STORAGE.ACCESS_TOKEN);
    config.headers.common.Authorization = accessToken;
    // console.log('Axios config', config);
    return config;
  },
  (error) => {
    // console.log(error);
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    if (
      error &&
      error.response &&
      error.response.status === HTTP_ERROR_CODES.UNAUTHORIZED
    ) {
      // localStorage.clear();
      // sessionStorage.clear();
    }
    // console.log(error);
    return Promise.reject(error.response);
  }
);

ReactDOM.render(
  <Provider store={Store}>
    <BrowserRouter>

      <NewAppBar>
        <Switch>
          {_.map(publicRoutes, (route, key) => {
            const { component, path } = route;
            return (
              <Route
                exact
                path={path}
                key={key}
                render={(route) => (
                  <AppLayout component={component} route={route} />
                )}
              />
            );
          })}
        </Switch>
      </NewAppBar>

    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
