const REMEMBER_LOGIN = 'REMEMBER_LOGIN';
const USER = 'USER';
const ACCESS_TOKEN = 'ACCESS_TOKEN';
const SEATS_GOING = 'SEATS_GOING';
const SEATS_GOING_CONNECTION = 'SEATS_GOING_CONNECTION';
const SEATS_RETURN = 'SEATS_RETURN';
const SEATS_RETURN_CONNECTION = 'SEATS_RETURN_CONNECTION';

const STORAGE = {
  REMEMBER_LOGIN,
  USER,
  ACCESS_TOKEN,
  SEATS_GOING,
  SEATS_GOING_CONNECTION,
  SEATS_RETURN,
  SEATS_RETURN_CONNECTION,
};

export default STORAGE;
