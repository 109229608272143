import Axios from 'axios';

export default class TripGroupApiService {
  static create(departureCityId, arrivalCityId, departDate, seats, note) {
    const url = `${process.env.REACT_APP_API_BASE_URL}/trip-group`;

    return Axios.post(url, {
      departureCityId,
      arrivalCityId,
      departDate,
      seats,
      note
    });
  }

}
