import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Container, Typography } from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { enqueueSnackbar } from '../actions/';
import { bindActionCreators } from 'redux';

import CustomerApiService from '../services/CustomerApiService';
import PassengerSelection from '../components/checkout/passenger-selection/PassengerSelection';
import ROUTES from '../constants/routes';
import CheckoutStepper from '../components/checkout/checkout-stepper/CheckoutStepper';
import withAuth from '../hocs/withAuth';
import CellPhoneModal from './CellPhoneModal';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
}));

const CustomTextField = styled(TextField)`
  label {
    transform: translate(12px, 16px) scale(1);
  }
  input {
    padding: 14px 12px;
  }
`;

const CellPhoneMask = (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,

        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      //   showMask
      keepCharPositions={true}
    />
  );
};

function Checkout({ user, enqueueSnackbar }) {
  const classes = useStyles();
  const [selectedPassengers, setSelectedPassengers] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [open, setOpen] = useState(false);
  const [cellPhone, setCellPhone] = useState('');

  const clientBySeller = JSON.parse(
    sessionStorage.getItem('clientSelectedBySeller')
  );
  const getCustomer = async () => {
    console.log('clientBySeller -->', clientBySeller);
    setLoading(true);
    try {
      const { data } = await CustomerApiService.getCustomer();
      if (!data.cellphone) {
        setOpen(true);
      }
      setCurrentCustomer(data);
      // console.log('Customer -->', data);
    } catch (error) {
      console.log('error -->', error);
    } finally {
      setLoading(false);
    }
  };

  const updateCellPhone = async () => {
    setLoading(true);
    try {
      const { data } = await CustomerApiService.updateCellPhone({
        cellphone: cellPhone,
        userId: clientBySeller?.id,
      });
      console.log('update cell', data);
      // if (clientBySeller?.id) {
      //   sessionStorage.setItem(
      //     "clientSelectedBySeller",
      //     JSON.stringify(data)
      //   );
      // }
      setOpen(false);
      enqueueSnackbar({
        message: 'Celular cadastrado com sucesso!',
        options: {
          key: 'updateCellPhone',
          autoHideDuration: 3000,
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        },
      });
    } catch (error) {
      enqueueSnackbar({
        message: 'Algo deu errado ao cadastrar o celular',
        options: {
          key: 'updateCellPhone',
          autoHideDuration: 5000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        },
      });
    } finally {
      setLoading(false);
    }
  };
  // console.log('selectedPassengers', selectedPassengers);
  useEffect(() => {
    if (user) {
      if (user.type === 5) {
        if (!clientBySeller.cellphone) {
          setOpen(true);
        }
      } else {
        getCustomer();
      }
    }
  }, []);
  return (
    <Box className={classes.root}>
      <Container>
        {loading ? (
          <CircularProgress />
        ) : (
          <Fragment>
            <CheckoutStepper activeStep={0} />
            <PassengerSelection
              onPassengerSelected={setSelectedPassengers}
              clientBySeller={clientBySeller}
            />
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='flex-end'
              marginTop={4}
              className={classes.root}
            >
              <>
                <Button
                  component={Link}
                  to={ROUTES.HOME}
                  className={classes.button}
                >
                  Voltar
                </Button>
                <Button
                  variant='outlined'
                  component={Link}
                  to={ROUTES.CHECKOUT_SELECT_SEATS}
                  color='primary'
                  // onClick={handleNext}
                  className={classes.button}
                  disabled={selectedPassengers.length === 0}
                >
                  Selecionar passageiros
                </Button>
              </>
            </Box>
          </Fragment>
        )}
      </Container>
      <CellPhoneModal
        open={open}
        title='Número de Celular Obrigátorio'
        backdropclick={true}
        width={315}
      >
        <Typography>
          Para facilitar a comunicação caso haja necessidade, gostaríamos do seu
          número de celular, por favor preencha abaixo:
        </Typography>

        <CustomTextField
          fullWidth
          id='cellphone'
          margin='normal'
          variant='outlined'
          type='text'
          label='Celular'
          value={cellPhone}
          onChange={(e) => {
            setCellPhone(e.target.value);
          }}
          // onBlur={handleBlur}
          // disabled={isLoading}
          InputProps={{
            inputComponent: CellPhoneMask,
            inputProps: {
              autoComplete: 'off',
              form: {
                autoComplete: 'off',
              },
            },
          }}
        />
        <Button
          variant='contained'
          color='primary'
          fullWidth
          disabled={cellPhone.trim().length < 15 || loading}
          style={{ borderRadius: 100 }}
          onClick={(e) => updateCellPhone()}
        >
          {!loading ? 'Salvar' : <CircularProgress size={20} />}
        </Button>
      </CellPhoneModal>
    </Box>
  );
}
const mapStateToProps = (store) => ({
  user: store.auth.user,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      enqueueSnackbar: enqueueSnackbar,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
