import { Box, Divider, Grid, Paper, Typography } from '@material-ui/core';

import CheckoutTripSummaryCard from './checkout-trip-summary-card/CheckoutTripSummaryCard';
import React from 'react';

function CheckoutTripSummary() {
  const departTrip = JSON.parse(sessionStorage.getItem('departTrip') || '{}');
  const returnTrip = JSON.parse(sessionStorage.getItem('returnTrip') || '{}');

  return (
    <Box component={Paper} padding={2}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h4">Trajetos </Typography>
        </Grid>

        <Grid item>
          <CheckoutTripSummaryCard trip={departTrip} title="Ida" isGoing />
        </Grid>
        {returnTrip && returnTrip.id && (
          <>
            <Divider style={{ marginBottom: 16 }} />
            <Grid item>
              <CheckoutTripSummaryCard trip={returnTrip} title="Volta" />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}

export default CheckoutTripSummary;
