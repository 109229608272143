import React from 'react'
import { Box, Button, Card, CardContent, makeStyles } from '@material-ui/core'

import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { searchTrips } from '../../actions'
import { Link } from 'react-router-dom'
import DetailTrip from '../trip-card/DetailTrip'

const styles = makeStyles((theme) => ({
  tripSearchCard: {
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
      marginRight: 0,
    },
    flexBasis: '32%',
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: {
    textTransform: 'none',
  },
  link: {
    textDecoration: 'none',
    marginTop: '30px',
  },
}))

function ForSaleCard(props) {
  const { trip, searchTrips } = props
  const departureCityName = trip.departure.city.name
  const arrivalCityName = trip.arrival.city.name
  const originalPrice = trip.busStationPrice
  const salePrice = trip.ticketValue

  const classes = styles()

  const searchTrip = () => {
    searchTrips(
      trip.departure.city,
      trip.arrival.city,
      trip.etd,
      null,
      false,
      null,
      () => {}
    )
  }

  return (
    <>
      <Card key={Math.random()} className={classes.tripSearchCard}>
        <CardContent >
          <Box
            display='flex'
            flexDirection='column'
            alignItems='stretch'
            justifyContent='space-around'
          >
            <div style={{ display: 'flex', flexDirection: 'row' }} >
              <div style={{ width: '72%' }}>
                <div>
                  <span>Saíndo de {departureCityName} para</span>
                  <br />
                  <span style={{ fontWeight: 'bolder', fontSize: 23 }}>
                    {arrivalCityName}
                  </span>
                </div>

                <span style={{ fontWeight: 'lighter' }}>
                  A partir de
                </span>

                <p style={{ margin: 0 }}>
                  <span
                    style={{ fontWeight: 'bolder', fontSize: 23, color: '#0FB8D4' }} >
                    R${salePrice}
                  </span>
                  <span style={{ fontWeight: 'lighter', fontSize: 23, paddingLeft: 16, textDecoration: 'line-through' }}>
                    R${originalPrice}
                  </span>
                </p>
              </div>

              <div style={{ display: 'flex', width: '28%', marginRight: 0 }}>
                <Link
                  to="#"
                  className={classes.link}
                  style={{ position: 'flex-end' }}
                >
                  <Button
                    variant='outlined'
                    color='primary'
                    className={classes.button}
                    style={{ fontWeight: 'bolder', borderRadius: '20px' }}
                    onClick={searchTrip}
                  >
                    Visualizar
                  </Button>
                </Link>
              </div>
            </div>
          </Box>
        </CardContent>
        {/* <DetailTrip trip={trip} /> */}
      </Card>
    </>
  )
}
const mapStateToProps = (store) => ({

})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      searchTrips: searchTrips,
    },
    dispatch
  )
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForSaleCard)
)