// const BookingStatuses = {
//   '1': 'Pendente',
//   '2': 'Aberta',
//   '3': 'Confirmada',
//   '4': 'Cancelada',
//   '5': 'Finalizada',
//   '6': 'Paga',
//   '7': 'Cancelada',
//   '8': 'Completo',
//   '9': 'Viagem em análise',
//   '10': 'Viagem criada',
//   '11': 'Sugestão em análise',
//   '12': 'Sugestão aceita',
//   '13': 'Pronto para embarque',
// };

// export default BookingStatuses;

const BookingStatuses = {
  '1': 'Não confirmada',
  '2': 'Não confirmada',
  '3': 'Viagem Confirmada',
  '4': 'Cancelada',
  '5': 'Finalizada',
  '6': 'Não confirmada',
  '7': 'Cancelada',
  '8': 'Viagem Confirmada',
  '14' : 'Viagem Confirmada'
};

export default BookingStatuses;
