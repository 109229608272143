import "date-fns";

import * as Yup from "yup";

import Actions, { authSignInSuccess, enqueueSnackbar } from "../../../actions";
import {
  Box,
  Button,
  Icon,
  InputAdornment,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { Field, Formik } from "formik";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  faAddressCard,
  faCity,
  faIdCardAlt,
} from "@fortawesome/free-solid-svg-icons";

import AccountCircle from "@material-ui/icons/AccountCircle";
import AppInputMask from "../../app-input-mask/AppInputMask";
import AuthApiService from "../../../services/AuthApiService";
import DateFnsUtils from "@date-io/date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { bindActionCreators } from "redux";
import clsx from "clsx";
import { connect } from "react-redux";
import MaskedInput from "react-text-mask";

import { cpfIsValid } from "../../../utils/validators";
import { green } from "@material-ui/core/colors";
import ptBRLocale from "date-fns/locale/pt-BR";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const CellPhoneMask = (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,

        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      //   showMask
      keepCharPositions={true}
    />
  );
};

const DatePickerField = ({ field, form, className, format, ...other }) => {
  const currentError = form.errors[field.name];

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
      <KeyboardDatePicker
        clearable
        name={field.name}
        value={field.value}
        format={format || "dd/MM/yyyy"}
        onError={(error) => {
          // handle as a side effect
          if (error !== currentError) {
            form.setFieldError(field.name, error);
          }
        }}
        onChange={(date) => form.setFieldValue(field.name, date, true)}
        inputVariant="standard"
        maskChar="_"
        {...other}
      />
    </MuiPickersUtilsProvider>
  );
};

const styles = makeStyles((theme) => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  buttonSpacer: {
    marginTop: theme.spacing(1),
  },
  loadingButtonWrapper: {
    position: "relative",
    width: "100%",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textField: {
    flex: "0 1 calc(50%)",
    "&:first-child": {
      flex: "0 1 calc(50% - 16px)",
      marginRight: theme.spacing(2),
    },
  },
  textFieldFull: {
    flex: "1 1 100%",
  },
  birthdayField:{
    width: "100%"
  },
  boxNormalWidth:{
    display: "flex",
    flex: "1 1 100%"
  },
  boxMobileWidth:{
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  form: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
}));

function NaturalPersonForm(props) {
  const { saveCustomer, refreshUser, customer, user, onSave } = props;
  // console.log('props', props);
  const isMobile = useMediaQuery("(max-width:768px)");
  const classes = styles();
  const phoneNumberMask = [];
  const formSchema = Yup.object().shape({
    name: Yup.string().required("Por favor digite seu Nome"),
    rg: Yup.string().required("Por favor digite seu RG"),
    rgIssuer: Yup.string(),
    cpf: Yup.string().test("CPF", "CPF Inválido", cpfIsValid),
    birthdate: Yup.date().required("Por favor digite sua Data de Nascimento"),
    cellphone: Yup.string()
      .required("Por favor digite seu Celular")
      .trim()
      .min(15, "Celular Inválido"),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    const submitValues = { ...customer, ...values };
    // console.log('handleSubmit -> values, submitValues', values, submitValues);
    saveCustomer(true, submitValues, user, async () => {
      setSubmitting(false);

      const newUser = (await AuthApiService.getUser()).data;
      refreshUser(newUser);

      onSave && onSave();
    });
  };

  return (
    <Box>
      <Formik
        initialValues={{
          name: (customer && customer.name) || (user && user.name) || "",
          rg: (customer && customer.rg) || "",
          rgIssuer: (customer && customer.rgIssuer) || "",
          cpf: (customer && customer.cpf) || "",
          birthdate: (customer && customer.birthdate) || null,
          cellphone: (customer && customer.cellphone) || "",
          // name: (user && user.name) || '',
          // rg: (customer && customer.rg) || '466853336',
          // rgIssuer: (customer && customer.rgIssuer) || 'SSP',
          // cpf: (customer && customer.cpf) || '39100818860',
          // birthdate: new Date(1989, 11, 20),
          // cellphone: (customer && customer.cellphone) || '11988856996',
        }}
        validationSchema={formSchema}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          isValid,
          values,
          errors,
          status,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} className={clsx(classes.form)}>

          <Box className={isMobile ? clsx(classes.boxMobileWidth) : clsx(classes.boxNormalWidth)}>

            <TextField
              name="name"
              autoFocus={true}
              className={isMobile ? clsx(classes.margin, classes.textFieldFull) : clsx(classes.margin, classes.textField)}
              margin="dense"
              variant="standard"
              type="text"
              label="Nome"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
              error={errors.name && touched.name}
              helperText={errors.name && touched.name ? errors.name : null}
            />

            <Field
              name="cellphone"
              render={({ field }) => (
                <TextField
                  {...field}
                  mask={phoneNumberMask}
                  id="cellphone"
                  className={isMobile ? clsx(classes.margin, classes.textFieldFull) : clsx(classes.margin, classes.textField)}
                  margin="dense"
                  variant="standard"
                  type="text"
                  label="Celular"
                  value={values.cellphone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    inputComponent: CellPhoneMask,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon style={{ fontSize: 18 }}>contact_phone</Icon>
                      </InputAdornment>
                    ),
                    inputProps: {
                      autoComplete: "off",
                      form: {
                        autoComplete: "off",
                      },
                    },
                  }}
                  error={errors.cellphone && touched.cellphone}
                  helperText={
                    errors.cellphone && touched.cellphone
                      ? errors.cellphone
                      : null
                  }
                />
              )}
            />
          </Box>

          <Box className={isMobile ? clsx(classes.boxMobileWidth) : clsx(classes.boxNormalWidth)}>
              <TextField
                name="rg"
                className={isMobile ? clsx(classes.margin, classes.textFieldFull) : clsx(classes.margin, classes.textField)}
                margin="dense"
                variant="standard"
                type="text"
                label="RG"
                value={values.rg}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <FontAwesomeIcon icon={faAddressCard} />
                    </InputAdornment>
                  ),
                }}
                error={errors.rg && touched.rg}
                helperText={errors.rg && touched.rg ? errors.rg : null}
              />
              <TextField
                name="rgIssuer"
                className={isMobile ? clsx(classes.margin, classes.textFieldFull) : clsx(classes.margin, classes.textField)}
                margin="dense"
                variant="standard"
                type="text"
                label="Órgão de Expedição"
                value={values.rgIssuer}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <FontAwesomeIcon icon={faCity} />
                    </InputAdornment>
                  ),
                }}
                error={errors.rgIssuer && touched.rgIssuer}
                helperText={
                  errors.rgIssuer && touched.rgIssuer ? errors.rgIssuer : null
                }
              />
            </Box>

            <Box className={isMobile ? clsx(classes.boxMobileWidth) : clsx(classes.boxNormalWidth)}>
              <AppInputMask
                mask="999.999.999-99"
                value={values.cpf}
                onChange={handleChange}
                onBlur={handleBlur}
                name="cpf"
                label="CPF"
                className={isMobile ? clsx(classes.margin, classes.textFieldFull) : clsx(classes.margin, classes.textField)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <FontAwesomeIcon icon={faIdCardAlt} />
                    </InputAdornment>
                  ),
                }}
                error={errors.cpf && touched.cpf}
                helperText={errors.cpf && touched.cpf ? errors.cpf : null}
              />

              <Field
                name="birthdate"
                InputAdornmentProps={{ position: "end" }}
                value={values.birthdate}
                style={{
                  width: isMobile ? "100%" : "50%",
                }}
                component={DatePickerField}
                disableFuture
                margin="dense"
                label="Data de Nascimento"
                invalidDateMessage="Por favor preencha sua Data de Nascimento"
              />
            </Box>

            <Box
              display="flex"
              flexDirection="row-reverse"
              alignItems="flex-end"
              justifyContent="space-between"
              paddingY={2}
              flex="1 1 100%"
            >
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                disabled={
                  !values.name ||
                  !values.rg ||
                  !values.rgIssuer ||
                  !values.cpf ||
                  !values.birthdate ||
                  !values.cellphone ||
                  isSubmitting
                }
              >
                Salvar
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
}

const mapStateToProps = (store) => ({
  error: store.auth.signUpError,
  user: store.auth.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveCustomer: Actions.customer.saveCustomer,
      refreshUser: authSignInSuccess,
      enqueueSnackbar: enqueueSnackbar,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NaturalPersonForm);
