import ReactPixel from 'react-facebook-pixel';

const pixelId = process.env.REACT_APP_FACEBOOK_PIXEL_CODE
const advancedMatching = { em: 'teste@hmail.com' }; 
const options = {
  autoConfig: true,
  debug: false,
};

const facebookPixel = (pathname, event) => { 
    ReactPixel.init(pixelId,advancedMatching,options);
    
    if (pathname !== "")
    {
      ReactPixel.pageView();
    }

    if (event !== "") {
      ReactPixel.track(event.type,event.data);
    }
 }

 export default facebookPixel;